import IFetcher from '../../../../Drivers/Interfaces/IFetcher'
import { syncMicroserviceUrl } from '../../../../shared'
import EmailJobRequestPresenter from '../SharedAbstractions/EmailJobRequestPresenter'
import IEmailJobRequestPresenter from '../SharedAbstractions/IEmailJobRequestPresenter'
import IFormCloser from '../SharedAbstractions/IFormCloser'

class SalesforceSyncFormPresenter extends EmailJobRequestPresenter
  implements IEmailJobRequestPresenter {
  constructor(formCloser: IFormCloser, private readonly fetcher: IFetcher) {
    super(formCloser)
  }

  protected sendRequest(): Promise<void> {
    return this.fetcher.fetch({
      url: `${syncMicroserviceUrl}sync${this._email ? `?email=${this.email}` : ''}`,
      body: undefined,
      method: 'GET'
    })
  }
}

export default SalesforceSyncFormPresenter
