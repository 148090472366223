abstract class TermTargetTypeEligibilityDeterminer {
  isEligibleForCompositeTarget(): boolean {
    return false
  }

  isEligibleForPeopleManagedTarget(): boolean {
    return false
  }
}

export default TermTargetTypeEligibilityDeterminer
