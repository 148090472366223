import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import IConfirmDeleteModalParam from './IConfirmDeleteModalParam'
import IConfirmModal from './IConfirmModal'

const Swal = withReactContent(swal)
class ConfirmModal implements IConfirmModal {
  public async confirmDelete(params: IConfirmDeleteModalParam): Promise<void> {
    await Swal.fire({
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      title: 'Are you sure?',
      input: 'text',
      inputValue: '',
      html: params.html,
      showCancelButton: true,
      allowOutsideClick: params.allowOutsideClick,
      preConfirm: params.preConfirm
    }).then(params.showResult)
  }
}

export default ConfirmModal
