import { IResponse } from 'Utilities/Interfaces'

import { ExistingVariableDTO } from '../DTOs'
import IErrorMessageDisplayer from '../SharedAbstractions/IErrorMessageDisplayer'
import ITermDeleter from '../SharedAbstractions/ITermDeleter'
import ITermListUpdateObserver from '../SharedAbstractions/ITermListUpdateObserver'
import ITermUpdater from '../SharedAbstractions/ITermUpdater'
import RowPresenter from '../SharedAbstractions/RowPresenter'
import IQueryCRUD from './IQueryCRUD'
import IVariableFormPresenter from './IVariableFormPresenter'
import IVariableRowPresenter from './IVariableRowPresenter'
import VariableEditFormPresenter from './VariableEditFormPresenter'

class VariableRowPresenter extends RowPresenter implements IVariableRowPresenter {
  private readonly editFormPresenter: VariableEditFormPresenter

  constructor(
    private readonly originalDTO: ExistingVariableDTO,
    private readonly termManager: ITermDeleter & ITermUpdater,
    termListUpdateObserver: ITermListUpdateObserver,
    errorMessageDisplayer: IErrorMessageDisplayer,
    private readonly queryManager?: IQueryCRUD
  ) {
    super(originalDTO, termManager, termListUpdateObserver, errorMessageDisplayer, 'Variable')
    this.editFormPresenter = new VariableEditFormPresenter(
      this,
      termListUpdateObserver,
      originalDTO,
      termManager,
      queryManager
    )
  }

  protected async getDeletionResponse(): Promise<IResponse> {
    await this.queryManager?.delete(this.originalDTO.variable_id)
    return this.termManager.delete(this.originalDTO.id)
  }

  public getEditFormPresenter(): IVariableFormPresenter {
    return this.editFormPresenter
  }

  public getPreview(): string {
    return this.originalDTO.isCurrentStatus ? 'Current Status' : 'Retro Queryable'
  }
}

export default VariableRowPresenter
