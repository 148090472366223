import FormPresenter from '../SharedAbstractions/FormPresenter'
import IOperationCanceler from '../SharedAbstractions/IOperationCanceler'
import ITermListUpdateObserver from '../SharedAbstractions/ITermListUpdateObserver'
import IConstantFormPresenter from './IConstantFormPresenter'

abstract class ConstantFormPresenter extends FormPresenter implements IConstantFormPresenter {
  protected _value: string

  constructor(
    operationCanceler: IOperationCanceler,
    operation: string,
    termListUpdateObserver: ITermListUpdateObserver,
    displayDuration?: number
  ) {
    super('', operation, operationCanceler, termListUpdateObserver, 'Constant', displayDuration)
    this._value = ''
  }

  set value(newValue: string) {
    this._value = newValue
    this.updateView()
  }

  get value(): string {
    return this._value
  }
}

export default ConstantFormPresenter
