import './style.css'

import { BackButton, Button, Form, StringHelper } from 'common'
import React, { Component, ReactElement } from 'react'

import { backToPreviousPage } from '../../../Utilities/LocalStorageHelper'

type Props = {
  apiError: string
  busy: boolean
  changed: boolean
  children: React.ReactNode
  deleteObject: (event: React.MouseEvent) => Promise<void>
  metricProfileId: number
  mode: 'add' | 'edit'
  objectName: string
  onSubmit: () => Promise<void>
  reasonForNotDeletable?: string
  reasonForNotSavable?: string
}

class GenericFormView extends Component<Props> {
  static defaultProps = {
    reasonForNotDeletable: undefined,
    reasonForNotSavable: undefined
  }

  render(): ReactElement {
    const { apiError, children, mode, objectName, onSubmit } = this.props

    return (
      <div className='genericFormView'>
        <BackButton className='genericFormView-backButton' action={this.back} />
        <h3>
          {StringHelper.capitalize(mode)} {StringHelper.aOrAn(objectName)}{' '}
          {StringHelper.capitalize(objectName)}
        </h3>
        <Form onSubmit={onSubmit}>
          {!!apiError && <p className='error'>{apiError}</p>}
          {children}
          {this.renderFormControls()}
        </Form>
      </div>
    )
  }

  back = (): void => {
    const { metricProfileId } = this.props
    backToPreviousPage(metricProfileId)
  }

  renderFormControls = (): ReactElement => {
    const { mode } = this.props
    return mode === 'add' ? this.renderAddControls() : this.renderEditControls()
  }

  renderAddControls = (): ReactElement => {
    const { busy, objectName } = this.props

    return (
      <Button
        busy={busy}
        className='primaryButton genericFormView-saveButton'
        disabled={busy}
        label={`Add ${objectName.toLowerCase()}`}
        type='Submit'
      />
    )
  }

  renderEditControls = (): ReactElement => {
    const {
      busy,
      changed,
      deleteObject,
      objectName,
      reasonForNotDeletable,
      reasonForNotSavable
    } = this.props

    return (
      <div>
        {reasonForNotSavable !== undefined && (
          <p className='note'>
            <strong>Cannot Save: </strong>
            {reasonForNotSavable}
          </p>
        )}
        <div className='genericFormView-editControls'>
          <Button
            busy={busy}
            className='primaryButton'
            disabled={busy || !changed || reasonForNotSavable !== undefined}
            label='Save changes'
            type='Submit'
          />

          {reasonForNotDeletable !== undefined ? (
            <div className='not-deletable'>
              <div className='arrow-box'>{reasonForNotDeletable}</div>
              Delete {objectName.toLowerCase()}
            </div>
          ) : (
            <a className='danger' href='#' onClick={deleteObject}>
              Delete {objectName.toLowerCase()}
            </a>
          )}
        </div>
      </div>
    )
  }
}

export default GenericFormView
