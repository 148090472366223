import './style.css'

import { Button, Select } from 'common'
import React, { Component, ReactElement } from 'react'

import ISubscribingView from '../../../SharedAbstractions/ISubscribingView'
import IFieldViewPresenter from '../Interfaces/IFieldViewPresenter'

export const pleaseSelectFieldPlaceholder = 'Please select a field'

type Props = {
  presenter: IFieldViewPresenter
}

class FieldVerificationView extends Component<Props> implements ISubscribingView {
  private readonly sumFieldSelectId = 'reportFieldView-summationFieldSelector'
  private readonly creditFieldSelectId = 'reportFieldView-creditToFieldSelector'
  private readonly appliedAtFieldSelectId = 'reportFieldView-appliedAtFieldSelector'

  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  componentDidUpdate(): void {
    this.props.presenter.setView(this)
  }

  componentWillUnmount(): void {
    this.props.presenter.clearView()
  }

  render(): ReactElement {
    const { presenter } = this.props

    return (
      <div>
        <div>
          <strong>Required:</strong> Salesforce Object for this report is{' '}
          <strong>{presenter.getDefaultSfdcObject()}</strong>
        </div>

        <div className='reportFieldView-formWrapper'>
          <div>
            <strong>Required:</strong> the field that determines who gets credit
          </div>
          <Select
            labelId={this.creditFieldSelectId}
            name={this.creditFieldSelectId}
            value={presenter.getCurrentCreditToField()}
            onChange={this.trackChanges}
          >
            {this.buildPlaceholderElement()}
            {presenter.getCreditFields().map(field => (
              <option value={`${field}`}>{field}</option>
            ))}
          </Select>
        </div>

        <div className='reportFieldView-formWrapper'>
          <div>
            <strong>Required:</strong> the field that determines when credit will be applied
          </div>
          <Select
            labelId={this.appliedAtFieldSelectId}
            name={this.appliedAtFieldSelectId}
            value={presenter.getCurrentAppliedAtField()}
            onChange={this.trackChanges}
          >
            {this.buildPlaceholderElement()}
            {presenter.getAppliedAtFields().map(field => (
              <option value={`${field}`}>{presenter.getDateFieldDisplayValue(field)}</option>
            ))}
          </Select>
        </div>

        <div className='reportFieldView-formWrapper'>
          <div>
            <strong>Optional:</strong> select a summation field
          </div>
          <Select
            labelId={this.sumFieldSelectId}
            name={this.sumFieldSelectId}
            value={presenter.getCurrentSumField()}
            onChange={this.trackChanges}
          >
            {this.buildPlaceholderElement()}
            {presenter.getSummationFields().map(field => (
              <option value={`${field}`}>{field}</option>
            ))}
          </Select>
        </div>

        <div className='reportFieldView-formWrapper'>
          <div>
            <strong>Optional:</strong> these are the where clauses we generated from the report
          </div>
          <textarea id='reportFieldView-whereClauseField'>
            {presenter.getDefaultWhereClauses()}
          </textarea>
        </div>
        {presenter.shouldDisplayQueryDto() ? (
          <div className='reportFieldView-formWrapper'>
            <div>
              These are your report parameters. Please copy and paste them into the above SOQL field
            </div>
            <textarea contentEditable='false' id='reportFieldView-queryDtoField'>
              {presenter.getQueryDto()}
            </textarea>
          </div>
        ) : (
          <div></div>
        )}
        <div className='reportFieldView-submitButtonWrapper'>
          <Button
            className='primaryButton reportFieldView-submitButton'
            label={'Generate Query Parameters'}
            type='Submit'
            onClick={() => {
              presenter.setShouldDisplayQueryDto(true)
            }}
          />
        </div>
      </div>
    )
  }

  buildPlaceholderElement = (): ReactElement => {
    return <option value={null}>{pleaseSelectFieldPlaceholder}</option>
  }

  trackChanges = (event: React.ChangeEvent): void => {
    const { presenter } = this.props
    const target = event.target as HTMLInputElement
    const field = target.name

    if (field === this.sumFieldSelectId) {
      presenter.setCurrentSumField(target.value)
    }

    if (field === this.creditFieldSelectId) {
      presenter.setCurrentCreditToField(target.value)
    }

    if (field === this.appliedAtFieldSelectId) {
      presenter.setCurrentAppliedAtField(target.value)
    }
  }

  update(): void {
    this.setState({})
  }
}

export default FieldVerificationView
