import './style.css'

import { Cookies, DbDataHelper } from 'common'
import React, { Component, ReactElement } from 'react'

import { IRequestComponent } from '../../shared'
import { baseApiUrl } from '../../shared/constants'
import LoginView from './LoginView'

type State = {
  apiError: string
  busy: boolean
}

class Login extends Component<{}, State> implements IRequestComponent {
  state = {
    busy: false,
    apiError: ''
  }

  render(): ReactElement {
    const { busy, apiError } = this.state
    return <LoginView busy={busy} formError={apiError} onSubmit={this.attemptLogin} />
  }

  attemptLogin = async (): Promise<void> => {
    const loginUrl = `${baseApiUrl}api/login`
    const params = {
      email: this.saferUserInput(
        (document.getElementById('login-email') as HTMLInputElement).value
      ),
      password: this.saferUserInput(
        (document.getElementById('login-password') as HTMLInputElement).value
      )
    }

    DbDataHelper.request({
      component: this,
      method: 'post',
      params,
      requiresAuth: false,
      successCallback: this.login,
      url: loginUrl
    })
  }

  saferUserInput = (value: string): string => {
    if (
      value.toLowerCase().indexOf('and') !== -1 &&
      value.indexOf('1=') !== -1 &&
      value.indexOf('--') !== -1
    ) {
      return value.replace(/((1=)|(--))/g, '')
    }

    return value
  }

  login = (response: any): void => {
    if (response?.error) {
      this.setState({ apiError: response.error })
    } else if (!response?.auth?.accountType.includes('admin')) {
      this.setState({ apiError: 'You must be an admin to use this tool' })
    } else {
      this.setLoginCookies(response)
      window.location.pathname = '/'
    }
  }

  setLoginCookies = (response: any): void => {
    const profilePicture = response.profilePicture ? JSON.stringify(response.profilePicture) : ''

    Cookies.set('companyId', response.companyId)
    Cookies.set('auth', JSON.stringify(response.auth), { expires: 1 })
    Cookies.set('crm', response.crm, { expires: 1 })
    Cookies.set('profilePicture', profilePicture, { expires: 1 })
  }
}

export default Login
