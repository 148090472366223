import IDependencyIdentifier from './IDependencyIdentifier'
import MetricDTO from './MetricDTO'
import TargetTypeEligibilityDeterminer from './TargetTypeEligibilityDeterminer'
import TermDTO from './TermDTOs/TermDTO'
import TermTargetTypeEligibilityDeterminerFactory from './TermTargetTypeEligibilityDeterminerFactory'

class DynamicTargetDependencyIdentifier implements IDependencyIdentifier {
  private termIdToTerm: Map<number, TermDTO>
  private metricIdToMetricDTO: Map<number, MetricDTO>
  private terms: TermDTO[]
  private metrics: MetricDTO[]

  constructor(termDTOs: TermDTO[], metricDTOs: MetricDTO[]) {
    this.termIdToTerm = new Map()
    termDTOs.forEach((termDTO: TermDTO) => {
      this.termIdToTerm.set(termDTO.termId, termDTO)
    })
    this.metricIdToMetricDTO = new Map()
    metricDTOs.forEach((metricDTO: MetricDTO) => {
      this.metricIdToMetricDTO.set(metricDTO.id, metricDTO)
    })
    this.terms = termDTOs
    this.metrics = metricDTOs
  }

  public getDependentDynamicTargetMetricNames(metricId: number): string[] {
    const metricsThatHaveDynamicTargets = this.metrics.filter(
      (metric: MetricDTO) =>
        metric.id !== metricId && this.isMetricCurrentlyUsingADynamicTarget(metric)
    )
    const metricsThatWouldNotBeEligibleWithoutThisMetric = metricsThatHaveDynamicTargets.filter(
      (metric: MetricDTO) =>
        this.isMetricIneligibleForDynamicTargetWithoutMetricId(metric, metricId)
    )

    return metricsThatWouldNotBeEligibleWithoutThisMetric.map(
      (metric: MetricDTO) =>
        `${metric.metricProfileName}: ${this.termIdToTerm.get(metric.termId).name}`
    )
  }

  private isMetricCurrentlyUsingADynamicTarget(metric: MetricDTO): boolean {
    return (
      metric.benchmarkType === 'percentOfAgg' &&
      new TargetTypeEligibilityDeterminer(
        new TermTargetTypeEligibilityDeterminerFactory(
          this.terms,
          this.metrics.filter((metricDTO: MetricDTO) => metric.id !== metricDTO.id)
        )
      ).isEligibleForDynamicTarget(metric.termId, metric.metricProfileId)
    )
  }

  private isMetricIneligibleForDynamicTargetWithoutMetricId(
    metricToCheck: MetricDTO,
    metricIdOfPotentiallyDependedUponMetric: number
  ): boolean {
    return !new TargetTypeEligibilityDeterminer(
      new TermTargetTypeEligibilityDeterminerFactory(
        this.terms,
        this.metrics.filter(
          (metricDTO: MetricDTO) =>
            metricToCheck.id !== metricDTO.id &&
            metricDTO.id !== metricIdOfPotentiallyDependedUponMetric
        )
      )
    ).isEligibleForDynamicTarget(metricToCheck.termId, metricToCheck.metricProfileId)
  }
}
export default DynamicTargetDependencyIdentifier
