import { Button, Input, Spinner } from 'common'
import React, { ChangeEvent, Component, Fragment, ReactElement } from 'react'

import IEmailJobRequestPresenter from '../SharedAbstractions/IEmailJobRequestPresenter'
import ISubscribingView from '../SharedAbstractions/ISubscribingView'

type Props = {
  presenter: IEmailJobRequestPresenter
}

class SalesforceSyncForm extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  componentWillUnmount(): void {
    this.props.presenter.clearView()
  }

  render() {
    const { presenter } = this.props
    if (presenter.isLoading()) {
      return this.renderLoading()
    }
    return (
      <div>
        {presenter.isShowingCompleteMessage()
          ? this.renderSuccessState()
          : this.renderEmailPrompt()}
      </div>
    )
  }

  public update(): void {
    this.setState({})
  }

  private renderLoading = (): ReactElement => {
    return (
      <Fragment>
        <h3>Sending...</h3>
        <div className='home-loadingState'>
          <Spinner />
        </div>
      </Fragment>
    )
  }

  private renderEmailPrompt = (): ReactElement => {
    const { presenter } = this.props
    return (
      <Fragment>
        <h3>Refresh all Salesforce performance data</h3>
        <p className='note'>
          This action will make one request for each user for each variable in your organization.
          Please use sparingly to preserve your Salesforce API allotment.
        </p>
        <Input
          id='refresh-email'
          label='Notification Email (Optional)'
          name='email'
          type='email'
          onChange={(event: ChangeEvent): void => {
            const target = event.target as HTMLInputElement
            presenter.email = target.value
          }}
          value={presenter.email}
        />
        <div className='home-formControls'>
          <Button
            className='primaryButton home-modalButton'
            label={'Start Refresh'}
            onClick={() => presenter.clickBeginJob()}
          />
          <a onClick={() => presenter.clickCloseForm()}>Cancel</a>
        </div>
      </Fragment>
    )
  }

  private renderSuccessState = (): ReactElement => {
    const { presenter } = this.props
    return (
      <Fragment>
        <h3>Refresh has started!</h3>
        <Button
          className='primaryButton home-modalButton'
          label={'OK'}
          onClick={() => presenter.clickCloseForm()}
        />
      </Fragment>
    )
  }
}

export default SalesforceSyncForm
