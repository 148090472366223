class DateHelper {
  private static months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  private static shortMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  private static weekDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]

  static daysInDecember = 31
  static daysInWeek = 7
  static millisecondsPerDay = 86400000
  static maxWeeksInYear = 53
  static monthsInQuarter = 3
  static monthsInYear = 12
  static quartersPerYear = 4
  public static fiscalMonths = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

  static getShortMonthDateFromDateString(date: string): string {
    const splitDate = date.split('-')
    const sliceDate = splitDate[2].slice(0, 2)
    const month = this.shortMonths[parseInt(splitDate[1], 10) - 1]
    return `${month} ${sliceDate}, ${splitDate[0]}`
  }
}

export default DateHelper
