import IExpressionChangeValidator from '../../../../UseCases/IExpressionChangeValidator'
import { IResponse } from '../../../../Utilities/Interfaces'
import { ExistingExpressionDTO, ExistingTermDTO } from '../DTOs'
import IOperationCanceler from '../SharedAbstractions/IOperationCanceler'
import ITermListUpdateObserver from '../SharedAbstractions/ITermListUpdateObserver'
import ITermUpdater from '../SharedAbstractions/ITermUpdater'
import ExpressionFormPresenter from './ExpressionFormPresenter'

class ExpressionEditFormPresenter extends ExpressionFormPresenter {
  private readonly blockedTermIds: Set<number>

  constructor(
    operationCanceler: IOperationCanceler,
    termListUpdateObserver: ITermListUpdateObserver,
    private readonly existingDTOs: ExistingTermDTO[],
    private readonly originalDTO: ExistingExpressionDTO,
    private readonly updater: ITermUpdater,
    private readonly changeValidator: IExpressionChangeValidator
  ) {
    super(operationCanceler, termListUpdateObserver, 'Update')
    this._leftHandTermId = originalDTO.lh_term
    this._name = originalDTO.name
    this._operator = originalDTO.operator
    this._rightHandTermId = originalDTO.rh_term

    this.blockedTermIds = new Set()
    const expressions: ExistingExpressionDTO[] = existingDTOs.reduce(
      (existingExpressions: ExistingExpressionDTO[], existingTerm: ExistingTermDTO) => {
        if (existingTerm.type === 'expression') {
          existingExpressions.push(existingTerm)
        }
        return existingExpressions
      },
      []
    )
    let { size } = this.blockedTermIds
    this.blockedTermIds.add(originalDTO.id)

    while (size < this.blockedTermIds.size) {
      size = this.blockedTermIds.size
      expressions.forEach(expressionDTO => {
        if (!this.blockedTermIds.has(expressionDTO.id)) {
          if (
            this.blockedTermIds.has(expressionDTO.lh_term) ||
            this.blockedTermIds.has(expressionDTO.rh_term)
          ) {
            this.blockedTermIds.add(expressionDTO.id)
          }
        }
      })
    }
    this.currentActiveStatus = this.originalDTO.isActive
  }

  protected getAvailableTerms(): ExistingTermDTO[] {
    return this.existingDTOs.filter(existingDTO => !this.blockedTermIds.has(existingDTO.id))
  }

  protected getResponse(): Promise<IResponse> {
    return this.updater.update(this.originalDTO.id, {
      lh_term: this.leftHandTermId,
      rh_term: this.rightHandTermId,
      operator: this.operator,
      name: this.name,
      isActiveVariable: this.currentActiveStatus ?? true,
      isActiveTerm: this.currentActiveStatus ?? true
    })
  }

  public isSaveEnabled(): boolean {
    return (
      this.requiredFieldsAreNonEmpty() &&
      (this._leftHandTermId !== this.originalDTO.lh_term ||
        this._rightHandTermId !== this.originalDTO.rh_term ||
        this._operator !== this.originalDTO.operator ||
        this._name !== this.originalDTO.name ||
        this.currentActiveStatus !== this.originalDTO.isActive) &&
      !this.causesBreakingChangeElseWhere()
    )
  }

  private causesBreakingChangeElseWhere(): boolean {
    return (
      !this.changeValidator.isInitialized() ||
      (this.changeValidator.isInitialized() && this.getMetricsThatWouldBreak().length > 0)
    )
  }

  private getMetricsThatWouldBreak(): string[] {
    return this.changeValidator.getNamesOfMetricsThatWouldBreakIfExpressionBecame({
      name: '',
      termId: this.originalDTO.id,
      leftHandTermId: this.leftHandTermId,
      rightHandTermId: this.rightHandTermId,
      type: 'expression'
    })
  }

  protected handleTermChange(): void {
    if (this.getMetricsThatWouldBreak().length !== 0) {
      this.setErrorMessage(
        `Editing this way will break the target for: ${this.getMetricsThatWouldBreak().join(', ')}`
      )
    } else {
      this.updateView()
    }
  }

  public getCheckedStatus(): boolean {
    return this.currentActiveStatus ?? true
  }
}
export default ExpressionEditFormPresenter
