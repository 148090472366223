import { StringHelper } from 'common'

import IBenchmarkSchedule from '../Routes/Home/IBenchmarkSchedule'
import IMetric from '../Routes/Home/IMetric'
import IBenchmark from './IBenchmark'

const PERCENT_AS_WHOLE_NUMBER = 100
class Benchmark implements IBenchmark {
  private _benchmarkType: string

  private _unit: string

  constructor(param: IBenchmarkSchedule) {
    const { unit } = param

    this._benchmarkType = this.getBenchmarkType(param)
    this._unit = this.setUnit(unit)
  }

  setUnit(unit: string): string {
    return unit === ' ' ? '' : unit
  }

  public get unit(): string {
    return this._unit
  }

  get benchmarkType(): string {
    return this._benchmarkType
  }

  private getBenchmarkType(benchmarkSchedule: IBenchmarkSchedule): string {
    const {
      fixedBenchmark,
      fixedMultipliedByNumPeopleManaged,
      percentOfAggregateBenchmark
    } = benchmarkSchedule

    if (fixedBenchmark) {
      return 'fixedBenchmark'
    }

    if (fixedMultipliedByNumPeopleManaged) {
      return 'fixedMultipliedByNumPeopleManaged'
    }

    if (percentOfAggregateBenchmark) {
      return 'percentOfAggregateBenchmark'
    }

    return ''
  }

  public getBenchmarkString = (rampStage: { benchmark: number; stageIndex: number }): string => {
    const prefixUnits = ['$']
    const isPrefixUnit = prefixUnits.includes(this._unit)
    const formattedBenchmark = this.getFormattedBenchmark(rampStage)

    if (this.isPercentOfAggregateBenchmark()) {
      return `${formattedBenchmark}%`
    }

    return isPrefixUnit
      ? `${this._unit}${formattedBenchmark}`
      : `${formattedBenchmark}${this._unit}`
  }

  private getFormattedBenchmark = (rampStage: {
    benchmark: number
    stageIndex: number
  }): number => {
    return this._unit === '%' || this.isPercentOfAggregateBenchmark()
      ? StringHelper.formatNumber(rampStage.benchmark * PERCENT_AS_WHOLE_NUMBER)
      : StringHelper.formatNumber(rampStage.benchmark)
  }

  public isPercentOfAggregateBenchmark(): boolean {
    return this._benchmarkType === 'percentOfAggregateBenchmark'
  }

  public getBenchmarksFromRampStages = (metric: IMetric, mode: 'edit' | 'add'): number[] => {
    if (mode === 'add') {
      return [0]
    }

    return metric.benchmarkSchedule.rampStages.map(rampStage => {
      const multiplier =
        metric.benchmarkSchedule.unit === '%' ||
        metric.benchmarkSchedule.percentOfAggregateBenchmark
          ? PERCENT_AS_WHOLE_NUMBER
          : 1

      return rampStage.benchmark * multiplier
    })
  }
}

export default Benchmark
