import 'regenerator-runtime/runtime'
import '@polymer/paper-radio-button/paper-radio-button.js'
import '@polymer/paper-radio-group/paper-radio-group.js'
import '@polymer/paper-tooltip/paper-tooltip.js'
import './shared/style.css'
import './App.css'

import React, { Component, ReactElement } from 'react'
import { Route, Switch } from 'react-router-dom'

import Form from './Routes/Form'
import Home from './Routes/Home'
import Login from './Routes/Login'
import { images } from './shared'

class App extends Component {
  componentDidMount() {
    this.changeSandboxHeader()
  }

  render(): ReactElement {
    return (
      <Switch>
        <Route path='/login' component={Login} />
        <Route path='/metric-form' component={Form} />
        <Route path='/metric-profile-form' component={Form} />
        <Route path='/:metricProfileId?' component={Home} />
      </Switch>
    )
  }

  changeSandboxHeader = (): void => {
    if (process.env.NODE_ENV === 'sandbox') {
      const header = document.querySelector('header')

      if (header) {
        const userMenuLink = header.querySelector('.userMenu')
        const logo = header.querySelector('img')

        header.setAttribute('style', 'background: #c2b280')
        userMenuLink.setAttribute('style', 'color: white')
        logo.setAttribute('src', images.darkLogo)
      }
    }
  }
}

export default App
