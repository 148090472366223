// An index value is used for a key in this file that can't be targeted by a comment with `eslint-disable-next-line`.
// In this situation, there is nothing unique about the values being mapped other than their index.
/* eslint-disable react/no-array-index-key */

import './style.css'

import { AddLink, Input, Select } from 'common'
import React, { Component, Fragment, ReactElement } from 'react'

import Benchmark from '../../../Entities/Benchmark'
import { baseMetricsUrl, DateHelper } from '../../../shared'
import IDependencyIdentifier from '../../../UseCases/IDependencyIdentifier'
import ITargetTypeEligibilityDeterminer from '../../../UseCases/ITargetTypeEligibilityDeterminer'
import IExpandedTerm from '../../Home/IExpandedTerm'
import IMetric from '../../Home/IMetric'
import GenericForm from '../GenericForm'

type Props = {
  dependedUponMetricNameFinder: IDependencyIdentifier
  eligibilityChecker: ITargetTypeEligibilityDeterminer
  id?: number
  metric?: IMetric
  metricProfileId: number
  mode: 'edit' | 'add'
  terms: IExpandedTerm[]
}

type State = {
  [field: string]: any
  benchmarkType: string
  benchmarks: number[]
  daysIntoPeriod: string
  effectiveDate: string
  fixedBenchmark: boolean
  fixedMultipliedByNumPeopleManaged: boolean
  percentOfAggregateBenchmark: boolean
  termId: string
  timePeriod: string
  unit: string
}

const fiscalMonths = ['2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']

class MetricForm extends Component<Props, State> {
  static defaultProps = {
    metric: {
      benchmarkSchedule: {
        daysIntoPeriodThreshold: 0,
        rampStages: [],
        timePeriod: null,
        unit: null
      },
      effectiveDate: null,
      id: -1,
      metricProfileId: -1,
      name: null,
      termId: -1
    },
    id: null
  }

  constructor(props) {
    super(props)
    const { metric, mode } = props

    if (mode === 'edit' && metric) {
      const benchmark = new Benchmark(metric.benchmarkSchedule)

      this.state = {
        benchmarkType: benchmark.benchmarkType,
        benchmarks: benchmark.getBenchmarksFromRampStages(metric, mode),
        fixedBenchmark: metric.benchmarkSchedule.fixedBenchmark,
        fixedMultipliedByNumPeopleManaged:
          metric.benchmarkSchedule.fixedMultipliedByNumPeopleManaged,
        daysIntoPeriod: metric.benchmarkSchedule.daysIntoPeriodThreshold.toString(),
        effectiveDate: metric.effectiveDate === null ? '' : metric.effectiveDate,
        percentOfAggregateBenchmark: metric.benchmarkSchedule.percentOfAggregateBenchmark,
        termId: metric.termId.toString(),
        timePeriod: metric.benchmarkSchedule.timePeriod,
        unit: metric.benchmarkSchedule.unit
      }
    } else {
      this.state = {
        benchmarks: [0],
        fixedBenchmark: true,
        fixedMultipliedByNumPeopleManaged: false,
        benchmarkType: 'fixed',
        daysIntoPeriod: '0',
        effectiveDate: '',
        percentOfAggregateBenchmark: false,
        termId: '',
        timePeriod: 'q',
        unit: ' '
      }
    }
  }

  componentDidMount(): void {
    const { timePeriod } = this.state
    this.setState({
      daysIntoPeriod: this.getMaximumDaysIntoPeriod(timePeriod).toString()
    })
  }

  render(): ReactElement {
    const { metric, metricProfileId, mode, terms } = this.props
    const { effectiveDate, daysIntoPeriod, termId, timePeriod, unit } = this.state

    return (
      <GenericForm
        changed={this.hasChanged()}
        metricProfileId={metricProfileId}
        mode={mode}
        objectName='metric'
        params={this.getParams()}
        submitUrl={this.getSubmitUrl()}
        reasonForNotDeletable={this.getReasonForNotDeletable()}
      >
        <Select
          id='metricForm-measurement'
          label='Measurement'
          name='termId'
          onChange={this.trackChanges}
          originalValue={mode === 'edit' && metric ? metric.termId.toString() : ''}
          required
          value={termId}
        >
          {!termId && <option value=''>Select a term that is used for this metric</option>}
          {terms.map(term => (
            <option key={term.id} value={term.id}>
              {term.name}
            </option>
          ))}
        </Select>
        <h4>Benchmark Schedule</h4>
        <Select
          id='metricForm-timePeriod'
          label='Time period'
          name='timePeriod'
          onChange={this.trackChanges}
          originalValue={mode === 'edit' && metric ? metric.benchmarkSchedule.timePeriod : ''}
          required
          value={timePeriod}
        >
          {!timePeriod && <option value=''>Select a time period</option>}
          <option value='m'>Month</option>
          <option value='q'>Quarter</option>
          <option value='d'>Workday (Weekend Targets are Zero)</option>
          {this.renderFiscalMonthOptions()}
        </Select>
        <Input
          id='metricForm-effectiveDate'
          label='Effective Date (optional)'
          labelId='metricForm-effectiveDateLabel'
          name='effectiveDate'
          onChange={this.trackChanges}
          originalValue={metric.effectiveDate === null ? '' : metric.effectiveDate}
          type='date'
          value={effectiveDate === null ? '' : effectiveDate}
        />
        <Input
          id='metricForm-daysIntoPeriod'
          label='Days into period'
          labelId='metricForm-daysIntoPeriodLabel'
          max={this.getMaximumDaysIntoPeriod(timePeriod)}
          min='0'
          name='daysIntoPeriod'
          onChange={this.trackChanges}
          originalValue={metric.benchmarkSchedule.daysIntoPeriodThreshold}
          placeholder={this.getMaximumDaysIntoPeriodPlaceholder(timePeriod)}
          type='number'
          required
          value={daysIntoPeriod}
        />
        <paper-tooltip
          animation-delay={0}
          for='metricForm-daysIntoPeriodLabel'
          offset={4}
          position='right'
        >
          This field marks the number of days into a period a new employee could miss and still have
          their first benchmark become active for that time period rather than the following one.
        </paper-tooltip>
        <fieldset className='metricForm-metricUnits'>
          <label className='expressionForm-operationLabel'>Metric unit:</label>
          <paper-radio-group
            onClick={this.trackUnitChange}
            onKeyPress={this.handleUnitKeyPress}
            selected={unit}
          >
            <paper-radio-button name=' '>No unit</paper-radio-button>
            <paper-radio-button name='$'>$</paper-radio-button>
            <paper-radio-button name='%'>%</paper-radio-button>
          </paper-radio-group>
        </fieldset>
        <h4>Benchmarks</h4>
        {this.nonFixedTargetIsAnOption(termId) && this.renderBenchmarkTypesButtons()}
        {this.renderBenchmarkFields()}
        <AddLink
          className='metricForm-addLink'
          label='Add another benchmark'
          onClick={this.addBenchmarkField}
        />
      </GenericForm>
    )
  }

  private nonFixedTargetIsAnOption = (termId: string): boolean => {
    const { eligibilityChecker, metricProfileId } = this.props
    if (termId) {
      return (
        eligibilityChecker.isEligibleForDynamicTarget(parseInt(termId, 10), metricProfileId) ||
        eligibilityChecker.isEligibleForPeopleManagedTarget(parseInt(termId, 10))
      )
    }
    return false
  }

  hasChanged = (): boolean => {
    const { metric, mode } = this.props
    const { benchmarks, daysIntoPeriod, effectiveDate, termId, timePeriod, unit } = this.state
    const benchmark = new Benchmark(metric.benchmarkSchedule)

    if (mode === 'add') {
      return true
    }

    return (
      effectiveDate !== metric.effectiveDate ||
      daysIntoPeriod !== metric.benchmarkSchedule.daysIntoPeriodThreshold.toString() ||
      termId !== metric.termId.toString() ||
      timePeriod !== metric.benchmarkSchedule.timePeriod ||
      unit !== metric.benchmarkSchedule.unit ||
      (metric && metric.benchmarkSchedule.rampStages.length < benchmarks.length) ||
      !benchmark.getBenchmarksFromRampStages(metric, mode).every((b, i) => b === benchmarks[i])
    )
  }

  getParams = (): object => {
    const {
      benchmarks,
      daysIntoPeriod,
      effectiveDate,
      fixedBenchmark,
      fixedMultipliedByNumPeopleManaged,
      percentOfAggregateBenchmark,
      termId,
      timePeriod,
      unit
    } = this.state
    const percentAsAWholeNumber = 100
    const benchmarkDivisor = unit === '%' || percentOfAggregateBenchmark ? percentAsAWholeNumber : 1

    return {
      benchmarkSchedule: {
        daysIntoPeriodThreshold: parseInt(daysIntoPeriod, 10),
        rampStages: benchmarks.map((benchmark: number, i) => ({
          benchmark: benchmark / benchmarkDivisor,
          stageIndex: i
        })),
        timePeriod,
        fixedBenchmark,
        fixedMultipliedByNumPeopleManaged,
        percentOfAggregateBenchmark,
        unit
      },
      effectiveDate: effectiveDate || null,
      termId: parseInt(termId, 10)
    }
  }

  getSubmitUrl = (): string => {
    const { id, metricProfileId } = this.props
    const metricIdString = id ? `/${id}` : ''

    return `${baseMetricsUrl}metricProfiles/${metricProfileId}/metrics${metricIdString}`
  }

  private getReasonForNotDeletable = (): string => {
    const { dependedUponMetricNameFinder, metric } = this.props
    if (metric) {
      const dependedUponMetricNames: string[] = dependedUponMetricNameFinder.getDependentDynamicTargetMetricNames(
        metric.id
      )
      if (dependedUponMetricNames.length) {
        return `This metric cannot be deleted because it is part of the dynamic targets for: ${dependedUponMetricNames.map(
          (name, index) => `${index > 0 ? ', ' : ''}${name}`
        )}`
      }
    }
    return undefined
  }

  trackChanges = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    const field = target.name

    if (field === 'timePeriod') {
      this.setState({
        [field]: target.value,
        daysIntoPeriod: this.getMaximumDaysIntoPeriod(target.value).toString()
      })
    } else {
      this.setState({ [field]: target.value })
    }
  }

  getMaximumDaysIntoPeriod = (timePeriod: string): number => {
    if (timePeriod === 'q' || fiscalMonths.includes(timePeriod)) {
      return 92
    }

    if (timePeriod === 'm') {
      return 31
    }

    if (timePeriod === 'd') {
      return 1
    }
    return null
  }

  getMaximumDaysIntoPeriodPlaceholder = (timePeriod: string): string => {
    if (timePeriod === 'q') {
      return 'Please enter a value between 0 and 92'
    }

    if (timePeriod === 'm') {
      return 'Please enter a value between 0 and 31'
    }

    if (timePeriod === 'd') {
      return 'Please enter a value between 0 and 1'
    }
    return null
  }

  trackUnitChange = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    const value = target.name

    this.setState({ unit: value })
  }

  handleUnitKeyPress = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      const submitButton = document.querySelector('button[type="submit"]') as HTMLElement
      submitButton.click()
    }
  }

  renderBenchmarkTypesButtons = (): ReactElement => {
    const { eligibilityChecker, metricProfileId } = this.props
    const { benchmarkType, termId } = this.state

    return (
      <fieldset className='metricForm-benchmarkTypes'>
        <label className='metricForm-benchmarkTypeLabel'>Benchmark type:</label>
        <paper-radio-group onClick={this.updateBenchmarkType} selected={benchmarkType}>
          <paper-radio-button name='fixedBenchmark'>Fixed Benchmark</paper-radio-button>
          {eligibilityChecker.isEligibleForPeopleManagedTarget(parseInt(termId, 10)) && (
            <paper-radio-button name='fixedMultipliedByNumPeopleManaged'>
              Fixed Benchmark * Number of Average People Managed
            </paper-radio-button>
          )}
          {eligibilityChecker.isEligibleForDynamicTarget(parseInt(termId, 10), metricProfileId) && (
            <paper-radio-button name='percentOfAggregateBenchmark'>
              Percent of Aggregate Benchmark
            </paper-radio-button>
          )}
        </paper-radio-group>
      </fieldset>
    )
  }

  updateBenchmarkType = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLSelectElement
    const selectedBenchmarkType = target.name
    const nonSelectedBenchmarkTypes: string[] = this.getNonSelectedBenchmarkTypes(
      selectedBenchmarkType
    )

    this.setState({
      benchmarkType: selectedBenchmarkType,
      [selectedBenchmarkType]: true,
      [nonSelectedBenchmarkTypes[0]]: false,
      [nonSelectedBenchmarkTypes[1]]: false
    })
  }

  getNonSelectedBenchmarkTypes = (selectedBenchmarkType: string): string[] => {
    const benchmarkTypes = [
      'fixedBenchmark',
      'fixedMultipliedByNumPeopleManaged',
      'percentOfAggregateBenchmark'
    ]

    return benchmarkTypes.filter(type => type !== selectedBenchmarkType)
  }

  getBenchmarksFromRampStages = (): number[] => {
    const { metric, mode } = this.props
    const percentAsWholeNumber = 100

    if (mode === 'add') {
      return [0]
    }

    return metric.benchmarkSchedule.rampStages.map(rampStage => {
      const multiplier = metric.benchmarkSchedule.unit === '%' ? percentAsWholeNumber : 1
      return rampStage.benchmark * multiplier
    })
  }

  renderBenchmarkFields = (): ReactElement => {
    const { metric, mode } = this.props
    const { benchmarks, benchmarkType, unit } = this.state
    const originalBenchmarks = new Benchmark(metric.benchmarkSchedule).getBenchmarksFromRampStages(
      metric,
      mode
    )

    return (
      <Fragment>
        {benchmarks.map((benchmark: number, i: number) => {
          const removeButton = i > 0 ? this.renderRemoveButton(i) : null

          return (
            <Input
              id={`metricForm-benchmark${i}`}
              key={i}
              label={
                i === benchmarks.length - 1
                  ? 'Fully ramped period target'
                  : `Ramping target ${i + 1}`
              }
              onChange={this.updateBenchmarkField}
              onFocus={this.selectInputText}
              originalValue={i < originalBenchmarks.length ? originalBenchmarks[i] : '0'}
              relativeContent={removeButton}
              required
              type='number'
              unit={benchmarkType === 'percentOfAggregateBenchmark' ? '%' : unit}
              value={benchmark.toString()}
            />
          )
        })}
      </Fragment>
    )
  }

  selectInputText = (event: React.FocusEvent): void => {
    const target = event.target as HTMLInputElement
    target.select()
  }

  renderRemoveButton = (index: number): ReactElement => {
    return (
      <a
        className='metricForm-removeButton danger'
        data-index={index}
        href='#'
        onClick={this.removeBenchmark}
      >
        Remove <ion-icon name='close-outline' />
      </a>
    )
  }

  removeBenchmark = (event: React.MouseEvent): void => {
    event.preventDefault()
    const { benchmarks } = this.state
    const target = event.target as HTMLElement
    const index = parseInt(target.getAttribute('data-index'), 10)

    this.setState({
      benchmarks: benchmarks.filter((benchmark, i) => i !== index)
    })
  }

  updateBenchmarkField = (event: React.ChangeEvent): void => {
    const { benchmarks } = this.state
    const target = event.target as HTMLInputElement
    const index = parseInt(target.id.replace(/\D/g, ''), 10)

    if (index === 0 && !benchmarks.length) {
      this.setState({
        benchmarks: [parseFloat(target.value)]
      })
    } else {
      benchmarks[index] = parseFloat(target.value)
      this.setState({ benchmarks })
    }
  }

  addBenchmarkField = (): void => {
    const { benchmarks } = this.state
    const firstBenchmarkElement = document.getElementById(
      'metricForm-benchmark0'
    ) as HTMLInputElement

    if (!benchmarks.length) {
      if (!firstBenchmarkElement) {
        this.setState({ benchmarks: [0] })
      } else {
        const firstBenchmarkValue = firstBenchmarkElement.value
          ? parseInt(firstBenchmarkElement.value, 10)
          : 0
        this.setState({ benchmarks: [firstBenchmarkValue, 0] })
      }
    } else {
      this.setState({
        benchmarks: [...benchmarks, 0]
      })
    }
  }

  renderFiscalMonthOptions = (): ReactElement => {
    return DateHelper.fiscalMonths.reduce((previous, month) => {
      return (
        <Fragment>
          {previous},{' '}
          <option value={month.toString()}>Fiscal month starting {month.toString()}</option>
        </Fragment>
      )
    }, <Fragment />)
  }
}

export default MetricForm
