import './style.css'

import { DialogContent, Modal } from '@material-ui/core'
import { DbDataHelper, DomHelper, PopupMenu } from 'common'
import React, { Component, Fragment, ReactElement } from 'react'

import { baseMetricsUrl, messageTime } from '../../../shared'
import ErrorModal from '../../../Utilities/DeleteModal/ErrorModal'
import IConfirmModal from '../../../Utilities/DeleteModal/IConfirmModal'
import { IModal, IResponse } from '../../../Utilities/Interfaces'
import IExpandedTerm from '../IExpandedTerm'
import IMetric from '../IMetric'
import IMetricProfile from '../IMetricProfile'
import ISubscribingView from '../MetricDefinitions/SharedAbstractions/ISubscribingView'
import CompanyDataHelper from '../MetricProfile/CompanyDataHelper/CompanyDataHelper'
import IMetricProfilePresenter from './MetricProfilePresenter/IMetricProfilePresenter'
import MetricSection from './MetricSection'
import RefreshForm from './RefreshForm/RefreshForm'

type Props = {
  confirmModal: IConfirmModal
  loading?: boolean
  metricProfile: IMetricProfile
  metricProfileIdToMetric: { [n: number]: IMetric[] }
  metricProfileIdToTerm: { [n: number]: IExpandedTerm[] }
  metricProfiles: IMetricProfile[]
  modal: IModal
  presenter: IMetricProfilePresenter
  removeMetricProfile: (id: number) => void
  setSuccessMessage: (message: string) => void
}

type State = {
  busy: boolean
  toggleMetricProfile: boolean
}

class MetricProfile extends Component<Props, State> implements ISubscribingView {
  static defaultProps = {
    loading: false
  }

  state = {
    busy: false,
    toggleMetricProfile: false
  }

  componentDidMount(): void {
    this.props.presenter.setView(this)
    this.openSelectedMetricProfile()
  }

  componentWillUnmount(): void {
    this.props.presenter.clearView()
  }

  render(): ReactElement {
    const {
      loading,
      metricProfile,
      metricProfiles,
      metricProfileIdToMetric,
      metricProfileIdToTerm,
      presenter
    } = this.props
    const { busy } = this.state
    const metrics = metricProfileIdToMetric[metricProfile.id] || []
    const terms = metricProfileIdToTerm[metricProfile.id] || []

    return (
      <Fragment>
        <details
          className='metricProfile-metricProfileDetails'
          onClick={this.toggleMetricProfile}
          open={this.isOpen()}
        >
          <summary className='metricProfile-metricProfileSummary'>
            {metricProfile.name}
            <PopupMenu actions={this.getPopupMenuActions()} />
          </summary>
          <div className='metricProfile-resync'>
            <a
              className={DomHelper.classString([
                'metricProfile-resyncLink',
                busy && 'metricProfile-disableResync'
              ])}
              data-id={metricProfile.id}
              href='#'
              onClick={() => presenter.clickOpen()}
            >
              <ion-icon name='sync-outline' />
              Refresh coordinates and conversion funnel
            </a>
          </div>
          <div className='metricProfile-detailsContent'>
            <MetricSection
              companyMetricDtos={CompanyDataHelper.getMetricDTOs(
                metricProfiles,
                metricProfileIdToMetric
              )}
              companyTermDtos={CompanyDataHelper.getTerms(metricProfileIdToTerm, metricProfiles)}
              loading={loading}
              metrics={metrics}
              metricProfileId={metricProfile.id}
              terms={terms}
            />
          </div>
        </details>
        {presenter.isShowingForm() && this.renderRefreshModal()}
      </Fragment>
    )
  }

  public update = (): void => {
    this.setState({})
  }

  private renderRefreshModal = (): ReactElement => {
    const { presenter } = this.props
    return (
      <Modal open onClose={() => presenter.closeForm()}>
        <DialogContent>
          <div className='home-modalBody'>
            <RefreshForm presenter={presenter.getRefreshFormPresenter()} />
          </div>
        </DialogContent>
      </Modal>
    )
  }

  openSelectedMetricProfile = (): void => {
    const formProps = localStorage.getItem('formProps')
    const selectedMetricProfileId = formProps && JSON.parse(formProps).metricProfileId

    window.history.pushState('', document.title, window.location.origin)
    if (typeof selectedMetricProfileId === 'number') {
      window.history.pushState(
        '',
        document.title,
        `${window.location.origin}/${selectedMetricProfileId}`
      )
    }
  }

  toggleMetricProfile = (): void => {
    this.setState(previousState => ({
      toggleMetricProfile: !previousState.toggleMetricProfile
    }))
  }

  isOpen = (): boolean => {
    const { metricProfile } = this.props
    return window.location.pathname === `/${metricProfile.id.toString()}`
  }

  getPopupMenuActions = (): object => {
    return [
      {
        label: 'Edit',
        operation: this.editMetricProfile
      },
      {
        label: 'Delete',
        operation: this.openDeleteConfirmation
      }
    ]
  }

  editMetricProfile = (): void => {
    const { id, name } = this.props.metricProfile
    const formProps = { id, mode: 'edit', name }

    window.localStorage.setItem('formProps', JSON.stringify(formProps))
    window.location.pathname = '/metric-profile-form'
  }

  openDeleteConfirmation = (): void => {
    const { confirmModal, modal } = this.props

    confirmModal.confirmDelete({
      allowOutsideClick: () => !modal.isLoading(),
      html: this.renderDeleteConfirmationInfo(),
      preConfirm: this.preConfirmDelete,
      showResult: this.showDeleteMetricProfileResult
    })
  }

  renderDeleteConfirmationInfo = (): ReactElement => {
    const { name } = this.props.metricProfile

    return (
      <div className='metricProfile-deleteMessage'>
        <p>
          Deleting a metric profile will also delete all expressions, variables, and constants
          belonging to that metric profile. This change is non-reversible.
        </p>
        <p>Please type "{name}" to confirm. This may take a while.</p>
      </div>
    )
  }

  preConfirmDelete = (metricProfileNameInput: string): Promise<any> | void => {
    const { modal } = this.props
    const { id, name } = this.props.metricProfile

    return metricProfileNameInput === name
      ? this.deleteMetricProfile(id)
      : modal.showValidationMessage(`Input does not match metric profile name. Please try again.`)
  }

  deleteMetricProfile = async (metricProfileId: number): Promise<any> => {
    const { removeMetricProfile } = this.props

    return DbDataHelper.request({
      method: 'DELETE',
      requiresAuth: true,
      successCallback: () => {
        removeMetricProfile(metricProfileId)
      },
      url: `${baseMetricsUrl}metricProfiles/${metricProfileId}/all`
    }).catch(error => new ErrorModal().error(`${error.message}`))
  }

  showDeleteMetricProfileResult = (result: any): void => {
    const { setSuccessMessage } = this.props
    const { value } = result
    const { message } = value as IResponse

    if (value) {
      DomHelper.delay(messageTime).then(() => {
        setSuccessMessage('')
      })
      setSuccessMessage(message)
    }
  }
}

export default MetricProfile
