import { ExistingVariableDTO } from '../DTOs'
import ITermCreator from '../SharedAbstractions/ITermCreator'
import ITermDeleter from '../SharedAbstractions/ITermDeleter'
import ITermListUpdateObserver from '../SharedAbstractions/ITermListUpdateObserver'
import ITermReader from '../SharedAbstractions/ITermReader'
import ITermUpdater from '../SharedAbstractions/ITermUpdater'
import SectionPresenter from '../SharedAbstractions/SectionPresenter'
import IQueryCRUD from './IQueryCRUD'
import IVariableFormPresenter from './IVariableFormPresenter'
import IVariableRowPresenter from './IVariableRowPresenter'
import IVariableSectionPresenter from './IVariableSectionPresenter'
import VariableAddFormPresenter from './VariableAddFormPresenter'
import VariableRowPresenter from './VariableRowPresenter'

class VariableSectionPresenter extends SectionPresenter implements IVariableSectionPresenter {
  private readonly addFormPresenter: VariableAddFormPresenter
  private readonly termIdToRowPresenter: Map<number, VariableRowPresenter>

  constructor(
    termManager: ITermCreator & ITermDeleter & ITermUpdater & ITermReader,
    companyId: number,
    termListUpdateObserver: ITermListUpdateObserver,
    existingDTOs: ExistingVariableDTO[],
    queryManager?: IQueryCRUD
  ) {
    super(existingDTOs)
    this.addFormPresenter = new VariableAddFormPresenter(
      this,
      termListUpdateObserver,
      termManager,
      companyId,
      queryManager
    )
    this.termIdToRowPresenter = new Map()
    existingDTOs.forEach(existingDTO => {
      this.termIdToRowPresenter.set(
        existingDTO.id,
        new VariableRowPresenter(
          existingDTO,
          termManager,
          termListUpdateObserver,
          this,
          queryManager
        )
      )
    })
  }

  public getSectionTitle(): string {
    return 'Variables'
  }

  public getAddFormPresenter(): IVariableFormPresenter {
    return this.addFormPresenter
  }

  public getRowPresenter(termId: number): IVariableRowPresenter {
    return this.termIdToRowPresenter.get(termId)
  }

  public getTermType(): string {
    return 'variable'
  }
}

export default VariableSectionPresenter
