import { ExistingConstantDTO } from '../DTOs'
import IErrorMessageDisplayer from '../SharedAbstractions/IErrorMessageDisplayer'
import ITermDeleter from '../SharedAbstractions/ITermDeleter'
import ITermListUpdateObserver from '../SharedAbstractions/ITermListUpdateObserver'
import ITermUpdater from '../SharedAbstractions/ITermUpdater'
import RowPresenter from '../SharedAbstractions/RowPresenter'
import ConstantEditFormPresenter from './ConstantEditFormPresenter'
import IConstantFormPresenter from './IConstantFormPresenter'
import IConstantRowPresenter from './IConstantRowPresenter'

class ConstantRowPresenter extends RowPresenter implements IConstantRowPresenter {
  private constantEditFormPresenter: ConstantEditFormPresenter

  constructor(
    private readonly existingConstantDTO: ExistingConstantDTO,
    termDeleterAndUpdater: ITermUpdater & ITermDeleter,
    termListUpdateObserver: ITermListUpdateObserver,
    errorMessageDisplayer: IErrorMessageDisplayer,
    confirmationDuration?: number
  ) {
    super(
      existingConstantDTO,
      termDeleterAndUpdater,
      termListUpdateObserver,
      errorMessageDisplayer,
      'Constant',
      confirmationDuration
    )
    this.constantEditFormPresenter = new ConstantEditFormPresenter(
      existingConstantDTO,
      termDeleterAndUpdater,
      this,
      termListUpdateObserver
    )
  }

  public getName(): string {
    return this.existingConstantDTO.name
  }

  public getPreview(): string {
    return String(this.existingConstantDTO.value)
  }

  public getEditFormPresenter(): IConstantFormPresenter {
    return this.constantEditFormPresenter
  }
}

export default ConstantRowPresenter
