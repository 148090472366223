import { IResponse } from '../../../../Utilities/Interfaces'
import FormPresenter from '../SharedAbstractions/FormPresenter'
import IOperationCanceler from '../SharedAbstractions/IOperationCanceler'
import ITermListUpdateObserver from '../SharedAbstractions/ITermListUpdateObserver'
import IQueryManager from './IQueryManager'
import IVariableFormPresenter from './IVariableFormPresenter'

abstract class VariableFormPresenter extends FormPresenter implements IVariableFormPresenter {
  private _isCurrentStatus: boolean
  private _queryString: string

  constructor(
    operationCanceler: IOperationCanceler,
    termListUpdateObserver: ITermListUpdateObserver,
    operation: string,
    protected readonly queryManager?: IQueryManager
  ) {
    super('', operation, operationCanceler, termListUpdateObserver, 'Variable')
    this._isCurrentStatus = false
    this._queryString = ''
  }

  get isCurrentStatus(): boolean {
    return this._isCurrentStatus
  }

  set isCurrentStatus(isCurrentStatus: boolean) {
    this._isCurrentStatus = isCurrentStatus
    this.updateView()
  }

  get queryString(): string {
    return this._queryString
  }

  set queryString(queryString: string) {
    this._queryString = queryString
    this.updateView()
  }

  public getQueryInstructions(): string {
    return this.queryManager?.getInstructions() || ''
  }

  public getQueryTitle(): string {
    return this.queryManager?.getTitle() || ''
  }

  protected abstract getResponse(): Promise<IResponse>

  abstract getCheckedStatus(): boolean

  public isSaveEnabled(): boolean {
    return !!this.name
  }

  public getDateUpdated(): string {
    return ''
  }

  public getUpdatedByName(): string {
    return ''
  }

  public isLoadingQuery(): boolean {
    return false
  }
}

export default VariableFormPresenter
