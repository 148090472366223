import { messageTime } from '../../../../shared'
import ObservablePresenter from './ObservedPresenter'

abstract class ErrorMessageDisplayingPresenter extends ObservablePresenter {
  private _errorMessage: string

  constructor(private readonly displayDuration: number = messageTime) {
    super()
    this._errorMessage = ''
  }

  protected setErrorMessage(message: string): void {
    this._errorMessage = message
    this.updateView()
    setTimeout(() => {
      this._errorMessage = ''
      this.updateView()
    }, this.displayDuration)
  }

  public getErrorMessage(): string {
    return this._errorMessage
  }
}

export default ErrorMessageDisplayingPresenter
