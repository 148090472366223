import IFetcher from '../../../../Drivers/Interfaces/IFetcher'
import { baseMetricsUrl } from '../../../../shared'
import SimpleMetric from '../Interfaces/SimpleMetric'

export const PERCENT_COMPLETE_AT_START = 10
export const PERCENT_COMPLETE_AFTER_METRICS = 100
class MetricProfileCloner {
  constructor(
    private readonly newMetricProfileId: number,
    private readonly fetcher: IFetcher,
    private readonly oldMetrics: SimpleMetric[],
    private readonly updateCompletionStatus: (percentCompleted: number) => void
  ) {}

  public async cloneMetricProfile(): Promise<void> {
    this.updateCompletionStatus(PERCENT_COMPLETE_AT_START)
    await this.cloneMetrics(this.oldMetrics)
    this.updateCompletionStatus(PERCENT_COMPLETE_AFTER_METRICS)
  }

  private async cloneMetrics(oldMetrics: SimpleMetric[]): Promise<void> {
    if (oldMetrics && oldMetrics.length) {
      await Promise.all(
        oldMetrics.map(async (oldMetric: SimpleMetric) => {
          await this.cloneMetric(oldMetric)
        })
      )
    }
  }

  private async cloneMetric(metric: SimpleMetric): Promise<void> {
    const url = `${baseMetricsUrl}metricProfiles/${this.newMetricProfileId}/metrics`
    await this.fetcher.fetch({
      method: 'POST',
      url,
      body: {
        benchmarkSchedule: metric.benchmarkSchedule,
        effectiveDate: metric.effectiveDate,
        metricProfileId: metric.metricProfileId,
        termId: metric.termId
      }
    })
  }
}

export default MetricProfileCloner
