import TermTargetTypeEligibilityDeterminer from './TermTargetTypeEligibilityDeterminer'

class VariableTargetTypeEligibilityDeterminer extends TermTargetTypeEligibilityDeterminer {
  private hasTarget: boolean

  constructor(hasTarget: boolean) {
    super()
    this.hasTarget = hasTarget
  }

  public isEligibleForCompositeTarget(): boolean {
    return this.hasTarget
  }
}

export default VariableTargetTypeEligibilityDeterminer
