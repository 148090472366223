import IFetcher from '../../../../Drivers/Interfaces/IFetcher'
import DialogBoxPresenter from '../SharedAbstractions/DialogBoxPresenter'
import IEmailJobRequestPresenter from '../SharedAbstractions/IEmailJobRequestPresenter'
import IFormCloser from '../SharedAbstractions/IFormCloser'
import ISyncPresenter from './ISyncPresenter'
import SalesforceSyncFormPresenter from './SalesforceSyncFormPresenter'

class SalesforceSyncPresenter extends DialogBoxPresenter implements ISyncPresenter, IFormCloser {
  private readonly formPresenter: SalesforceSyncFormPresenter

  constructor(fetcher: IFetcher) {
    super()
    this.formPresenter = new SalesforceSyncFormPresenter(this, fetcher)
  }

  public getFormPresenter(): IEmailJobRequestPresenter {
    return this.formPresenter
  }
}

export default SalesforceSyncPresenter
