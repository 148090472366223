import IBenchmarkSchedule from '../../../../Routes/Home/IBenchmarkSchedule'
import MetricDTO from '../../../../UseCases/MetricDTO'
import AggregateTermDTO from '../../../../UseCases/TermDTOs/AggregateTermDTO'
import ExpressionDTO from '../../../../UseCases/TermDTOs/ExpressionDTO'
import TermDTO from '../../../../UseCases/TermDTOs/TermDTO'
import IExpandedTerm from '../../IExpandedTerm'
import IMetric from '../../IMetric'
import IMetricProfile from '../../IMetricProfile'

class CompanyDataHelper {
  public static getTerms(
    metricProfileIdToTerm: { [n: number]: IExpandedTerm[] },
    metricProfiles: IMetricProfile[]
  ): TermDTO[] {
    if (!Array.isArray(metricProfiles)) {
      return []
    }
    const result: TermDTO[] = []
    metricProfiles.forEach((metricProfile: IMetricProfile) => {
      const terms: IExpandedTerm[] = metricProfileIdToTerm[metricProfile.id]
      if (Array.isArray(terms)) {
        terms.forEach((term: IExpandedTerm) => {
          if (term.type === 'constant') {
            result.push({ termId: term.id, type: 'constant', name: term.name })
          } else if (term.type === 'variable') {
            result.push({ termId: term.id, type: 'variable', name: term.name })
          } else if (term.type === 'expression') {
            const expression: ExpressionDTO = {
              termId: term.id,
              type: 'expression',
              leftHandTermId: term.lh_term.id,
              rightHandTermId: term.rh_term.id,
              name: term.name
            }
            result.push(expression)
          } else {
            const aggregate: AggregateTermDTO = {
              termId: term.id,
              type: 'aggregate',
              termIdToAggregate: term.termIdToAggregate,
              metricProfileIdsToAggregate: term.metricProfileIdsToAggregate,
              name: term.name
            }
            result.push(aggregate)
          }
        })
      }
    })
    return result
  }

  public static getMetricDTOs(
    metricProfiles: IMetricProfile[],
    metricProfileIdToMetric: { [n: number]: IMetric[] }
  ): MetricDTO[] {
    if (!Array.isArray(metricProfiles)) {
      return []
    }
    const result: MetricDTO[] = []
    metricProfiles.forEach((metricProfile: IMetricProfile) => {
      const metrics: IMetric[] = metricProfileIdToMetric[metricProfile.id]
      if (Array.isArray(metrics)) {
        metrics.forEach((metric: IMetric) => {
          result.push({
            benchmarkType: this.getBenchmarkType(metric.benchmarkSchedule),
            id: metric.id,
            metricProfileName: metricProfile.name,
            termId: metric.termId,
            metricProfileId: metric.metricProfileId
          })
        })
      }
    })
    return result
  }

  private static getBenchmarkType(
    benchmarkSchedule: IBenchmarkSchedule
  ): 'fixed' | 'fixedTimesNumPeopleManaged' | 'percentOfAgg' {
    if (!benchmarkSchedule) {
      throw Error(`Benchmark Schedule must be defined: ${benchmarkSchedule}`)
    }
    if (benchmarkSchedule.fixedBenchmark) {
      return 'fixed'
    }
    if (benchmarkSchedule.percentOfAggregateBenchmark) {
      return 'percentOfAgg'
    }
    if (benchmarkSchedule.fixedMultipliedByNumPeopleManaged) {
      return 'fixedTimesNumPeopleManaged'
    }
    throw Error(
      `Benchmark Schedule must have at least one type boolean as true defined: ${benchmarkSchedule}`
    )
  }
}

export default CompanyDataHelper
