import './style.css'

import { BackButton, Button, DbDataHelper, Input, Select, StringHelper } from 'common'
import React, { Component, Fragment, ReactElement } from 'react'

import Fetcher from '../../../Drivers/Fetcher'
import { baseMetricsUrl, IRequestComponent } from '../../../shared'
import {
  backToPreviousPage,
  getFormProps,
  hasFormProps
} from '../../../Utilities/LocalStorageHelper'
import IMetricProfile from '../../Home/IMetricProfile'
import SimpleMetric from './Interfaces/SimpleMetric'
import MetricProfileCloner from './MetricProfileCloner/MetricProfileCloner'

type Props = {
  id?: number
  mode: 'edit' | 'add'
  name?: string
}

type State = {
  apiError: string
  busy: boolean
  changed: boolean
  cloning: boolean
  cloningProgress: number
  metricProfile: string
  metricProfileSelected: boolean
  name: string
}

class MetricProfileForm extends Component<Props, State> implements IRequestComponent {
  static defaultProps = {
    id: null,
    name: ''
  }

  state = {
    apiError: '',
    busy: false,
    changed: false,
    cloning: false,
    cloningProgress: null,
    metricProfile: '',
    metricProfileSelected: false,
    name: this.props.name || ''
  }

  render(): ReactElement {
    const { mode } = this.props
    const { apiError, name } = this.state
    const metricProfiles = hasFormProps() && JSON.parse(getFormProps()).profiles

    return (
      <form className='metricProfileForm' onSubmit={this.onSubmit}>
        <BackButton className='metricProfileForm-backButton' action={this.back} />
        {apiError && <p className='error'>{apiError}</p>}
        <Input
          autoFocus
          id='metricProfileForm-name'
          label='Name'
          pattern='^[a-zA-Z0-9 -]+$'
          patternExplanation='Please keep the name limited to letters, numbers, spaces, and dashes'
          onChange={this.trackChanges}
          originalValue={this.props.name}
          required
          value={name}
        />
        {mode === 'add' && !!metricProfiles.length && (
          <div className='metricProfileForm-metricProfileDropdown'>
            <Select
              id='metricProfileForm-metricProfiles'
              label='Create From Existing Metric Profile (Optional)'
              name='metricProfileSelected'
              onChange={this.trackTemplateChanges}
              value={this.state.metricProfile}
            >
              {<option value=''>Select a metric profile</option>}
              {metricProfiles.map(metricProfile => (
                <option key={metricProfile.id} value={metricProfile.name}>
                  {metricProfile.name}
                </option>
              ))}
            </Select>
          </div>
        )}
        {this.renderFormControls()}
      </form>
    )
  }

  onSubmit = async (event: React.FormEvent): Promise<void> => {
    const { mode } = this.props
    const { metricProfileSelected } = this.state
    event.preventDefault()
    const modeToMethod = {
      add: 'post',
      edit: 'put',
      delete: 'delete'
    }
    const metricProfileRequest = {
      cloneMetricProfile: metricProfileSelected,
      method: modeToMethod[mode],
      params: this.getParams(),
      url: this.getSubmitUrl()
    }

    this.operateOnMetricProfile(metricProfileRequest)
  }

  operateOnMetricProfile = async (metricProfileRequest: IMetricProfileRequest): Promise<void> => {
    const { cloneMetricProfile, method, params, url } = metricProfileRequest

    DbDataHelper.request({
      component: this,
      method,
      params,
      requiresAuth: true,
      successCallback: cloneMetricProfile
        ? (response: IMetricProfile) => this.beginCloningProcess(response.id)
        : () => this.formSuccess(method),
      url
    })
  }

  beginCloningProcess = (newMetricProfileId: number): void => {
    this.setState({ cloning: true })
    const metrics: SimpleMetric[] = this.getMetrics()
    new MetricProfileCloner(
      newMetricProfileId,
      new Fetcher(),
      metrics,
      this.updateCloningProgress
    ).cloneMetricProfile()
  }

  getMetrics = (): SimpleMetric[] => {
    const formStorage = window.localStorage.getItem('formProps')
    const { metricProfile } = this.state

    if (hasFormProps) {
      const metricProfiles = JSON.parse(formStorage).profiles
      const metricProfileId = metricProfiles.find(profile => profile.name === metricProfile).id
      const metrics = JSON.parse(formStorage).metrics[metricProfileId]

      if (metrics) {
        return metrics.map(metric => {
          return {
            benchmarkSchedule: metric.benchmarkSchedule,
            effectiveDate: metric.effectiveDate,
            metricProfileId: metric.metricProfileId,
            termId: metric.termId
          }
        })
      }
    }

    return []
  }

  updateCloningProgress = (percentCompleted: number): void => {
    const maxProgress = 100
    if (percentCompleted === maxProgress) {
      this.setState({ busy: false, cloning: false, cloningProgress: percentCompleted })
      this.formSuccess('clone')
    } else {
      this.setState({ cloningProgress: percentCompleted })
    }
  }

  formSuccess = (method: string): void => {
    const methodToOperationType = {
      post: 'added',
      put: 'edited',
      delete: 'deleted',
      clone: 'cloned'
    }
    localStorage.setItem(
      'formSuccessMessage',
      `Metric profile ${methodToOperationType[method]} successfully!`
    )
    window.history.back()
  }

  back = (): void => {
    const { id } = this.props

    backToPreviousPage(id)
  }

  getParams = (): object => {
    const { mode } = this.props
    const { name } = this.state

    if (mode === 'add') {
      return { name }
    }
    return { newName: name }
  }

  getSubmitUrl = (): string => {
    const { id } = this.props
    const idString = id ? `/${id}` : ''

    return `${baseMetricsUrl}metricProfiles${idString}`
  }

  trackChanges = (event: React.ChangeEvent): void => {
    const { mode, name } = this.props
    const target = event.target as HTMLInputElement

    if (mode === 'add') {
      this.setState({ name: target.value })
    } else {
      this.setState({ changed: target.value !== name, name: target.value })
    }
  }

  trackTemplateChanges = (event: React.ChangeEvent): void => {
    const { metricProfile } = this.state
    const target = event.target as HTMLInputElement

    this.setState({
      metricProfileSelected: target.value !== '' && metricProfile !== target.value,
      metricProfile: target.value
    })
  }

  renderFormControls = (): ReactElement => {
    const { mode } = this.props
    return mode === 'add' ? this.renderAddControls() : this.renderEditControls()
  }

  renderAddControls = (): ReactElement => {
    const { busy, cloning, name } = this.state

    return (
      <div className='metricProfileForm-cloningProgress'>
        {cloning && this.renderProgressBar()}
        <Button
          busy={cloning || busy}
          className='primaryButton metricProfileForm-saveButton'
          disabled={cloning || busy || name === ''}
          label={this.addButtonLabel()}
          type='Submit'
        />
      </div>
    )
  }

  addButtonLabel = (): string => {
    const { cloning, metricProfileSelected } = this.state
    if (metricProfileSelected) {
      return cloning ? 'Cloning Metric Profile' : 'Clone Metric Profile'
    }
    return 'Add Metric Profile'
  }

  renderProgressBar = (): ReactElement => {
    const { cloningProgress } = this.state

    return (
      <Fragment>
        <label htmlFor='metricProfileForm-cloning'>
          Progress {StringHelper.formatNumber(cloningProgress, '%', 0)}
        </label>
        <progress id='metricProfileForm-cloning' value={cloningProgress} max='100'></progress>
      </Fragment>
    )
  }

  renderEditControls = (): ReactElement => {
    const { busy, changed, name } = this.state

    return (
      <div className='metricProfileForm-editControls'>
        <Button
          busy={busy}
          className='primaryButton metricProfileForm-saveButton'
          disabled={busy || !changed || name === ''}
          label='Save changes'
          type='Submit'
        />

        <a className='danger' href='#' onClick={this.deleteObject}>
          Delete metric profile
        </a>
      </div>
    )
  }

  deleteObject = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault()
    const metricProfileRequest = {
      method: 'delete',
      params: {},
      url: this.getSubmitUrl()
    }

    this.operateOnMetricProfile(metricProfileRequest)
  }
}

export default MetricProfileForm
