import TermTargetTypeEligibilityDeterminer from './TermTargetTypeEligibilityDeterminer'

class ExpressionTargetTypeEligibilityDeterminer extends TermTargetTypeEligibilityDeterminer {
  private firstChild: TermTargetTypeEligibilityDeterminer
  private secondChild: TermTargetTypeEligibilityDeterminer
  private hasTarget: boolean

  constructor(
    firstChild: TermTargetTypeEligibilityDeterminer,
    secondChild: TermTargetTypeEligibilityDeterminer,
    hasTarget: boolean
  ) {
    super()
    this.firstChild = firstChild
    this.secondChild = secondChild
    this.hasTarget = hasTarget
  }

  public isEligibleForCompositeTarget(): boolean {
    return (
      this.hasTarget ||
      (this.firstChild.isEligibleForCompositeTarget() &&
        this.secondChild.isEligibleForCompositeTarget())
    )
  }

  public isEligibleForPeopleManagedTarget(): boolean {
    return (
      this.firstChild.isEligibleForPeopleManagedTarget() ||
      this.secondChild.isEligibleForPeopleManagedTarget()
    )
  }
}

export default ExpressionTargetTypeEligibilityDeterminer
