import { Modal } from '@material-ui/core'
import React, { Component, Fragment, ReactElement } from 'react'

import ISyncPresenter from '../SalesforceSync/ISyncPresenter'
import ISubscribingView from '../SharedAbstractions/ISubscribingView'
import SalesforceSyncForm from './SalesforceSyncForm'

type Props = {
  presenter: ISyncPresenter
}

class SalesforceSync extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  componentWillUnmount(): void {
    this.props.presenter.clearView()
  }

  render() {
    const { presenter } = this.props
    return (
      <Fragment>
        <a className='metricProfile-resyncLink' onClick={() => presenter.clickOpen()}>
          <ion-icon name='sync-outline' />
          Refresh all Salesforce performance data
        </a>
        {presenter.isShowingForm() && this.renderForm()}
      </Fragment>
    )
  }

  public update = (): void => {
    this.setState({})
  }

  private renderForm = (): ReactElement => {
    const { presenter } = this.props
    return (
      <Modal open onClose={() => presenter.closeForm()}>
        <div className='home-modalBody'>
          <SalesforceSyncForm presenter={presenter.getFormPresenter()} />
        </div>
      </Modal>
    )
  }
}

export default SalesforceSync
