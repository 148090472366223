import { IResponse } from '../../../../Utilities/Interfaces'
import { ExistingTermDTO, IdNameDTO } from '../DTOs'
import IOperationCanceler from '../SharedAbstractions/IOperationCanceler'
import ITermCreator from '../SharedAbstractions/ITermCreator'
import ITermListUpdateObserver from '../SharedAbstractions/ITermListUpdateObserver'
import AggregateTermFormPresenter from './AggregateTermFormPresenter'

class AggregateTermAddFormPresenter extends AggregateTermFormPresenter {
  constructor(
    operationCanceler: IOperationCanceler,
    termListUpdateObserver: ITermListUpdateObserver,
    existingTermDTOs: ExistingTermDTO[],
    metricProfileDTOs: IdNameDTO[],
    private readonly creator: ITermCreator,
    private readonly companyId: number
  ) {
    super(operationCanceler, termListUpdateObserver, existingTermDTOs, metricProfileDTOs, 'Create')
  }

  public getCheckedStatus(): boolean {
    return true
  }

  protected getResponse(): Promise<IResponse> {
    return this.creator.create({
      company_id: this.companyId,
      termIdToAggregate: this.selectedTermIdToAggregate,
      metricProfileIdsToAggregate: this.selectedMetricProfileIds,
      type: 'aggregate',
      name: this.getAggregateTermNamePreview(),
      isActiveVariable: true
    })
  }
}

export default AggregateTermAddFormPresenter
