import TermTargetTypeEligibilityDeterminer from './TermTargetTypeEligibilityDeterminer'

class AggregateTargetTypeEligibilityDeterminer extends TermTargetTypeEligibilityDeterminer {
  private hasTarget: boolean
  private termsToAggregate: TermTargetTypeEligibilityDeterminer[]

  constructor(termsToAggregate: TermTargetTypeEligibilityDeterminer[], hasTarget: boolean) {
    super()
    this.termsToAggregate = termsToAggregate
    this.hasTarget = hasTarget
  }

  public isEligibleForCompositeTarget(): boolean {
    return (
      this.hasTarget || this.termsToAggregate.every(term => term.isEligibleForCompositeTarget())
    )
  }

  public isEligibleForPeopleManagedTarget(): boolean {
    return true
  }
}

export default AggregateTargetTypeEligibilityDeterminer
