import { ExistingConstantDTO } from '../DTOs'
import ITermCreator from '../SharedAbstractions/ITermCreator'
import ITermDeleter from '../SharedAbstractions/ITermDeleter'
import ITermListUpdateObserver from '../SharedAbstractions/ITermListUpdateObserver'
import ITermUpdater from '../SharedAbstractions/ITermUpdater'
import SectionPresenter from '../SharedAbstractions/SectionPresenter'
import ConstantAddFormPresenter from './ConstantAddFormPresenter'
import ConstantRowPresenter from './ConstantRowPresenter'
import IConstantFormPresenter from './IConstantFormPresenter'
import IConstantRowPresenter from './IConstantRowPresenter'
import IConstantSectionPresenter from './IConstantSectionPresenter'

class ConstantSectionPresenter extends SectionPresenter implements IConstantSectionPresenter {
  private readonly termIdToConstantRowPresenter: Map<number, ConstantRowPresenter>
  private readonly constantAddFormPresenter: ConstantAddFormPresenter

  constructor(
    existingConstantDTOs: ExistingConstantDTO[],
    private readonly termManager: ITermDeleter & ITermUpdater & ITermCreator,
    private readonly termListUpdateObserver: ITermListUpdateObserver,
    private readonly companyId: number,
    displayDuration?: number
  ) {
    super(existingConstantDTOs, displayDuration)

    this.termIdToConstantRowPresenter = new Map()
    existingConstantDTOs.forEach(existingConstantDTO => {
      this.termIdToConstantRowPresenter.set(
        existingConstantDTO.id,
        new ConstantRowPresenter(
          existingConstantDTO,
          this.termManager,
          this.termListUpdateObserver,
          this
        )
      )
    })

    this.constantAddFormPresenter = new ConstantAddFormPresenter(
      this,
      this.termManager,
      this.termListUpdateObserver,
      this.companyId
    )
  }

  public getRowPresenter(termId: number): IConstantRowPresenter {
    return this.termIdToConstantRowPresenter.get(termId)
  }

  public getAddFormPresenter(): IConstantFormPresenter {
    return this.constantAddFormPresenter
  }

  public getSectionTitle(): string {
    return 'Constants'
  }

  public getTermType(): string {
    return 'constant'
  }
}

export default ConstantSectionPresenter
