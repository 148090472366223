import './style.css'

import { DomHelper, Header } from 'common'
import React, { Component, ReactElement, ReactNode } from 'react'

type Props = {
  children: ReactNode
  className?: string
}

class Page extends Component<Props> {
  static defaultProps = {
    className: ''
  }

  render(): ReactElement {
    const { children, className } = this.props
    const pageContentClassName = DomHelper.classString(['pageContent', className])

    return (
      <div className='page'>
        <Header />
        <main className={pageContentClassName}>{children}</main>
        <page-footer />
      </div>
    )
  }
}

export default Page
