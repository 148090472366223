import ITargetTypeEligibilityDeterminer from './ITargetTypeEligibilityDeterminer'
import ITermTargetTypeEligibilityDeterminerFactory from './ITermTargetTypeEligibilityDeterminerFactory'

class TargetTypeEligibilityDeterminer implements ITargetTypeEligibilityDeterminer {
  constructor(
    private readonly termTargetTypeEligibilityDeterminerFactory: ITermTargetTypeEligibilityDeterminerFactory
  ) {}

  isEligibleForDynamicTarget(
    termId: number,
    metricProfileIdIntendingToUseTermAsMetric: number
  ): boolean {
    return this.termTargetTypeEligibilityDeterminerFactory
      .getTermByIdAndMetricProfileIdIntendingToUseTermAsMetric(
        termId,
        metricProfileIdIntendingToUseTermAsMetric
      )
      .isEligibleForCompositeTarget()
  }

  isEligibleForPeopleManagedTarget(termId: number): boolean {
    const fakeMetricProfileId = 0
    return this.termTargetTypeEligibilityDeterminerFactory
      .getTermByIdAndMetricProfileIdIntendingToUseTermAsMetric(termId, fakeMetricProfileId)
      .isEligibleForPeopleManagedTarget()
  }
}

export default TargetTypeEligibilityDeterminer
