import { DateHelper } from '../../../../shared'
import { IResponse } from '../../../../Utilities/Interfaces'
import { ExistingVariableDTO } from '../DTOs'
import IOperationCanceler from '../SharedAbstractions/IOperationCanceler'
import ITermListUpdateObserver from '../SharedAbstractions/ITermListUpdateObserver'
import ITermUpdater from '../SharedAbstractions/ITermUpdater'
import ExistingQueryDTO from './ExistingQueryDTO'
import IQueryCRUD from './IQueryCRUD'
import IVariableFormPresenter from './IVariableFormPresenter'
import VariableFormPresenter from './VariableFormPresenter'

class VariableEditFormPresenter extends VariableFormPresenter implements IVariableFormPresenter {
  private _isLoadingQuery: boolean
  private _originalQuery: ExistingQueryDTO | null

  constructor(
    operationCanceler: IOperationCanceler,
    termListUpdateObserver: ITermListUpdateObserver,
    private readonly originalDTO: ExistingVariableDTO,
    private readonly termManager: ITermUpdater,
    protected readonly queryManager?: IQueryCRUD
  ) {
    super(operationCanceler, termListUpdateObserver, 'Update', queryManager)
    this.name = originalDTO.name
    this.isCurrentStatus = originalDTO.isCurrentStatus
    this._isLoadingQuery = false
    if (queryManager) {
      this.initializeQuery()
    }
    this.currentActiveStatus = this.originalDTO.isActive
  }

  private async initializeQuery(): Promise<void> {
    this._isLoadingQuery = true
    this._originalQuery = await this.queryManager.getQuery(this.originalDTO.variable_id)
    this._isLoadingQuery = false
    this.queryString = this._originalQuery?.query || ''
  }

  private baseFieldsHaveChanged(): boolean {
    return (
      this.name !== this.originalDTO.name ||
      this.isCurrentStatus !== this.originalDTO.isCurrentStatus ||
      this.currentActiveStatus !== this.originalDTO.isActive
    )
  }

  public isSaveEnabled(): boolean {
    return !!this.name && (this.baseFieldsHaveChanged() || this.queryHasChanged())
  }

  private queryHasChanged(): boolean {
    if (!this.queryManager) {
      return false
    }
    if (!this._originalQuery) {
      return !!this.queryString
    }

    return this._originalQuery.query !== this.queryString
  }

  public isLoadingQuery(): boolean {
    return this._isLoadingQuery
  }

  public getDateUpdated(): string {
    return this._originalQuery
      ? DateHelper.getShortMonthDateFromDateString(this._originalQuery.dateUpdated)
      : ''
  }

  public getUpdatedByName(): string {
    return this._originalQuery?.updatedBy || ''
  }

  protected async getResponse(): Promise<IResponse> {
    let res: IResponse
    if (this.baseFieldsHaveChanged()) {
      res = await this.termManager.update(this.originalDTO.id, {
        isCurrentStatus: this.isCurrentStatus,
        name: this.name,
        isActiveVariable: this.currentActiveStatus ?? true,
        isActiveTerm: this.currentActiveStatus ?? true
      })
    }
    if (this.queryHasChanged()) {
      if (!this._originalQuery) {
        res = await this.queryManager.create(this.originalDTO.variable_id, this.queryString)
      } else if (!this.queryString) {
        res = await this.queryManager.delete(this.originalDTO.variable_id)
      } else {
        res = await this.queryManager.update(this.originalDTO.variable_id, this.queryString)
      }
    }
    return res
  }

  public getCheckedStatus(): boolean {
    return this.currentActiveStatus ?? true
  }
}

export default VariableEditFormPresenter
