import { ExistingTermDTO } from '../DTOs'
import ErrorMessageDisplayingPresenter from './ErrorMessageDisplayingPresenter'
import IErrorMessageDisplayer from './IErrorMessageDisplayer'
import IFormPresenter from './IFormPresenter'
import IOperationCanceler from './IOperationCanceler'
import IRowPresenter from './IRowPresenter'
import ISectionPresenter from './ISectionPresenter'

abstract class SectionPresenter extends ErrorMessageDisplayingPresenter
  implements IErrorMessageDisplayer, IOperationCanceler, ISectionPresenter {
  private _isShowingAddForm: boolean

  constructor(private readonly existingDTOs: ExistingTermDTO[], displayDuration?: number) {
    super(displayDuration)
    this._isShowingAddForm = false
  }

  public abstract getSectionTitle(): string

  public abstract getAddFormPresenter(): IFormPresenter

  public abstract getRowPresenter(termId: number): IRowPresenter

  public abstract getTermType(): string

  public showErrorMessage(message: string): void {
    this.setErrorMessage(message)
  }

  public cancelOperation(): void {
    this._isShowingAddForm = false
    this.updateView()
  }

  public clickAdd(): void {
    this._isShowingAddForm = true
    this.updateView()
  }

  public getExistingTermIds(): number[] {
    return this.existingDTOs.map(existingConstant => existingConstant.id)
  }

  public isShowingAddForm(): boolean {
    return this._isShowingAddForm
  }
}

export default SectionPresenter
