import './style.css'

import { DialogContent, Modal } from '@material-ui/core'
import { AddLink, Button, Spinner } from 'common'
import React, { Component, Fragment, ReactElement } from 'react'

import Fetcher from '../../Drivers/Fetcher'
import { Page, TabContainer } from '../../shared'
import ConfirmModal from '../../Utilities/DeleteModal/ConfirmModal'
import UtilitiesModal from '../../Utilities/Modal'
import IExpandedTerm from './IExpandedTerm'
import IMetric from './IMetric'
import IMetricProfile from './IMetricProfile'
import ICompanyTermsListPresenter from './MetricDefinitions/SharedAbstractions/ICompanyTermsListPresenter'
import ISubscribingView from './MetricDefinitions/SharedAbstractions/ISubscribingView'
import CompanyTermsListView from './MetricDefinitions/Views/CompanyTermsListView'
import MetricProfile from './MetricProfile'
import IMetricProfilePresenter from './MetricProfile/MetricProfilePresenter/IMetricProfilePresenter'
import MetricProfilePresenter from './MetricProfile/MetricProfilePresenter/MetricProfilePresenter'
import RefreshForm from './MetricProfile/RefreshForm/RefreshForm'

type Props = {
  busy: boolean
  error: string
  metricProfileIdToMetric: { [n: number]: IMetric[] }
  metricProfileIdToPresenter: { [n: number]: IMetricProfilePresenter }
  metricProfileIdToTerm: { [n: number]: IExpandedTerm[] }
  metricProfiles: IMetricProfile[]
  metricProfilesLoading: boolean
  openMetricProfileForm: (event: React.MouseEvent) => void
  removeMetricProfile: (id: number) => void
  setSuccessMessage: (message: string) => void
  successMessage: string
  termsListPresenter: ICompanyTermsListPresenter
}

class HomeView extends Component<Props> implements ISubscribingView {
  private metricPresenter: IMetricProfilePresenter = new MetricProfilePresenter(new Fetcher(), null)
  public componentDidMount(): void {
    this.metricPresenter.setView(this)
  }

  public componentWillUnmount(): void {
    this.metricPresenter.clearView()
  }

  public render(): ReactElement {
    return (
      <Page>
        <TabContainer getTabContent={this.getTabContent} tabNames={this.getTabNames()} />
      </Page>
    )
  }

  public update(): void {
    this.setState({})
  }

  private getTabNames = (): string[] => {
    return this.props.termsListPresenter
      ? ['Metric Profiles', 'Metric Definitions']
      : ['Metric Profiles']
  }

  private getTabContent = (tabName: string): ReactElement => {
    if (tabName === 'Metric Profiles') {
      return this.renderPageContent()
    }

    return <CompanyTermsListView presenter={this.props.termsListPresenter} />
  }

  private renderPageContent = (): ReactElement => {
    const { error, successMessage } = this.props
    return (
      <Fragment>
        {successMessage && <p className='home-success success'>{successMessage}</p>}
        {error && <p className='home-error error'>{error}</p>}
        {this.renderPageBody()}
      </Fragment>
    )
  }

  renderPageBody = (): ReactElement => {
    const { busy, metricProfiles } = this.props

    if (busy) {
      return this.renderLoadingState()
    }

    if (metricProfiles.length) {
      return this.renderMetricProfiles()
    }

    return this.renderEmptyState()
  }

  renderLoadingState = (): ReactElement => {
    return (
      <div className='home-wrapper'>
        <h2>Metric Profiles</h2>
        <div className='home-loadingState'>
          <Spinner />
        </div>
      </div>
    )
  }

  renderMetricProfiles = (): ReactElement => {
    const {
      metricProfiles,
      metricProfileIdToMetric,
      metricProfileIdToPresenter,
      metricProfileIdToTerm,
      metricProfilesLoading,
      openMetricProfileForm,
      removeMetricProfile,
      setSuccessMessage
    } = this.props
    const hasAllDataToClone = Object.keys(metricProfileIdToMetric).length > 0

    return (
      <div className='home-wrapper'>
        <h2>Metric Profiles</h2>
        <div className='home-resync'>
          <a className='home-resyncLink' href='#' onClick={() => this.metricPresenter.clickOpen()}>
            <ion-icon name='sync-outline' />
            Refresh coordinates and conversion funnel for all Metric Profiles
          </a>
        </div>
        <AddLink
          className={`home-addMetricProfileLink ${!hasAllDataToClone && 'home-disabledLink'}`}
          label='Add a metric profile'
          onClick={hasAllDataToClone ? openMetricProfileForm : ''}
        />

        {metricProfiles.map(metricProfile => (
          <MetricProfile
            confirmModal={new ConfirmModal()}
            key={metricProfile.id}
            loading={metricProfilesLoading}
            metricProfile={metricProfile}
            metricProfiles={metricProfiles}
            metricProfileIdToMetric={metricProfileIdToMetric}
            metricProfileIdToTerm={metricProfileIdToTerm}
            modal={new UtilitiesModal()}
            presenter={metricProfileIdToPresenter[metricProfile.id]}
            removeMetricProfile={removeMetricProfile}
            setSuccessMessage={setSuccessMessage}
          />
        ))}
        {this.metricPresenter.isShowingForm() && this.renderRefreshModal()}
      </div>
    )
  }

  private renderRefreshModal = (): ReactElement => {
    return (
      <Modal open onClose={() => this.metricPresenter.closeForm()}>
        <DialogContent>
          <div className='home-modalBody'>
            <RefreshForm presenter={this.metricPresenter.getRefreshFormPresenter()} />
          </div>
        </DialogContent>
      </Modal>
    )
  }

  renderEmptyState = (): ReactElement => {
    const { openMetricProfileForm } = this.props

    return (
      <div className='home-emptyState'>
        <ion-icon name='analytics-outline' />
        <h3>No metric profiles found</h3>
        <Button
          className='primaryButton home-emptyStateButton'
          label='Add a metric profile'
          onClick={openMetricProfileForm}
        />
      </div>
    )
  }
}

export default HomeView
