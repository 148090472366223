import './style.css'

import { Button, Form, Input, PreLoginPage } from 'common'
import React, { Component, ReactElement } from 'react'

type Props = {
  busy?: boolean
  formError?: string
  onSubmit: (event: React.FormEvent) => Promise<void>
}

type State = {
  [field: string]: string
  email: string
  password: string
}

class LoginView extends Component<Props, State> {
  static defaultProps = {
    formError: '',
    busy: false
  }

  state = {
    email: '',
    password: ''
  }

  render(): ReactElement {
    const { busy, formError, onSubmit } = this.props
    const { email, password } = this.state

    return (
      <PreLoginPage header='Sign in'>
        {!!formError && <p className='error'>{formError}</p>}
        <Form onSubmit={onSubmit}>
          <fieldset>
            <Input
              id='login-email'
              label='Email'
              name='email'
              onChange={this.updateValue}
              required
              type='text'
              value={email}
            />
          </fieldset>

          <fieldset>
            <Input
              id='login-password'
              label='Password'
              name='password'
              onChange={this.updateValue}
              pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,40}$'
              patternExplanation='Passwords must be at least 8 characters and have at least one lowercase character, one uppercase character, and one number.'
              required
              type='password'
              value={password}
            />
            <a className='login-forgetPassword small-text' href='#'>
              Forgot your password?
            </a>
          </fieldset>

          <Button
            busy={busy}
            className='primaryButton'
            disabled={busy}
            label='Sign in'
            type='submit'
          />
        </Form>
      </PreLoginPage>
    )
  }

  updateValue = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    this.setState({ [target.name]: target.value })
  }
}

export default LoginView
