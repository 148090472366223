/* eslint-disable no-param-reassign */
import './style.css'

import { Checkbox, FormControlLabel } from '@material-ui/core'
import { Cookies, Input, Select } from 'common'
import React, { ChangeEvent, Component, Fragment, ReactElement } from 'react'

import Fetcher from '../../../../Drivers/Fetcher'
import IAggregateTermFormPresenter from '../AggregateTerms/IAggregateTermFormPresenter'
import IConstantFormPresenter from '../Constants/IConstantFormPresenter'
import { IdNameDTO, ValidExpressionOperator } from '../DTOs'
import IExpressionFormPresenter from '../Expressions/IExpressionFormPresenter'
import IFormPresenter from '../SharedAbstractions/IFormPresenter'
import IVariableFormPresenter from '../Variables/IVariableFormPresenter'
import ReportUrlToQueryTabPresenter from './ReportUrlToQueryTab/Classes/ReportUrlToQueryTabPresenter'
import ReportUrlSubmissionView from './ReportUrlToQueryTab/ReportUrlSubmissionView/ReportUrlSubmissionView'

const DATABASED_COMPANY_ID = 100
const DROPLET_COMPANY_ID = 189
const BEN_LABS_COMPANY_ID = 248
const TERM = 'term'
const VARIABLE = 'variable'

type Props = {
  presenter: IFormPresenter
}

export class FormBodyFactory extends Component<Props> {
  private isChecked = true
  render() {
    const { presenter } = this.props
    return (
      <Fragment>
        {this.presenterIsConstantFormPresenter(presenter) &&
          this.renderConstantSpecificFields(presenter)}
        {this.presenterIsExpressionFormPresenter(presenter) &&
          this.renderExpressionSpecificFields(presenter)}
        {this.presenterIsAggregateTermFormPresenter(presenter) &&
          this.renderAggregateTermSpecificFields(presenter)}
        {this.presenterIsVariableFormPresenter(presenter) &&
          this.renderVariableSpecificFields(presenter)}
      </Fragment>
    )
  }

  private renderNameInput = (): ReactElement => {
    const { presenter } = this.props
    return (
      <Input
        className='home-input'
        disabled={presenter.isSaving()}
        id='form-name'
        label='Name'
        name='name'
        onChange={(event: ChangeEvent): void => {
          const target = event.target as HTMLInputElement
          presenter.name = target.value
        }}
        pattern='^[a-zA-Z0-9 -]+$'
        patternExplanation='Please keep names limited to letters, numbers, spaces, and dashes'
        required
        value={presenter.name}
      />
    )
  }

  private presenterIsConstantFormPresenter(
    presenter: IFormPresenter
  ): presenter is IConstantFormPresenter {
    return Object.keys(presenter).some(key => key === '_value')
  }

  private renderConstantSpecificFields = (presenter: IConstantFormPresenter): ReactElement => {
    return (
      <Fragment>
        {this.renderNameInput()}
        <Input
          id='form-value'
          className='home-input'
          disabled={presenter.isSaving()}
          label='Value'
          name='value'
          onChange={(event: ChangeEvent): void => {
            const target = event.target as HTMLInputElement
            presenter.value = target.value
          }}
          pattern='^([0-9]+|[0-9]*\.[0-9]+)$'
          patternExplanation='Please only use integers or floating point numbers for the value field'
          required
          type='number'
          value={presenter.value}
        />
        {this.renderCurrentStatusBox(TERM)}
      </Fragment>
    )
  }

  private presenterIsExpressionFormPresenter(
    presenter: IFormPresenter
  ): presenter is IExpressionFormPresenter {
    return Object.keys(presenter).some(key => key === '_operator')
  }

  private renderExpressionSpecificFields = (presenter: IExpressionFormPresenter): ReactElement => {
    return (
      <Fragment>
        {this.renderNameInput()}
        <Select
          className='expressionForm-term'
          disabled={presenter.isSaving()}
          id='expressionForm-term1'
          label='Term 1'
          name='leftTerm'
          onChange={(event: ChangeEvent): void => {
            const target = event.target as HTMLInputElement
            presenter.leftHandTermId = parseInt(target.value, 10)
          }}
          value={presenter.leftHandTermId}
        >
          {!presenter.leftHandTermId && <option value=''>Choose a term</option>}
          {this.renderTermOptions(presenter)}
        </Select>
        <fieldset>
          <label className='expressionForm-operationLabel'>Operation:</label>
          <paper-radio-group
            onClick={(event: ChangeEvent) => {
              const target = event.target as HTMLInputElement
              const value = target.name as ValidExpressionOperator
              presenter.operator = value
            }}
            selected={presenter.operator}
          >
            <paper-radio-button name='/'>Division (/)</paper-radio-button>
            <paper-radio-button name='*'>Multiplication (*)</paper-radio-button>
            <paper-radio-button name='-'>Subtraction (-)</paper-radio-button>
            <paper-radio-button name='+'>Addition (+)</paper-radio-button>
          </paper-radio-group>
        </fieldset>
        <Select
          className='expressionForm-term'
          disabled={presenter.isSaving()}
          id='expressionForm-term2'
          label='Term 2'
          name='rightTerm'
          onChange={(event: ChangeEvent): void => {
            const target = event.target as HTMLInputElement
            presenter.rightHandTermId = parseInt(target.value, 10)
          }}
          value={presenter.rightHandTermId}
        >
          {!presenter.rightHandTermId && <option value=''>Choose a term</option>}
          {this.renderTermOptions(presenter)}
        </Select>
        {this.renderCurrentStatusBox(TERM)}
      </Fragment>
    )
  }

  private renderTermOptions = (presenter: { getTermOptions: () => IdNameDTO[] }): ReactElement => {
    return (
      <Fragment>
        {presenter.getTermOptions().map(option => {
          return (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          )
        })}
      </Fragment>
    )
  }

  private presenterIsAggregateTermFormPresenter(
    presenter: IFormPresenter
  ): presenter is IAggregateTermFormPresenter {
    return Object.keys(presenter).some(key => key === '_groupName')
  }

  private renderAggregateTermSpecificFields = (
    presenter: IAggregateTermFormPresenter
  ): ReactElement => {
    return (
      <Fragment>
        <Select
          className='home-input'
          disabled={presenter.isSaving()}
          id='aggregateForm-term'
          label='Term to aggregate'
          name='termToAggregate'
          onChange={(event: ChangeEvent): void => {
            const target = event.target as HTMLInputElement
            presenter.selectedTermIdToAggregate = parseInt(target.value, 10)
          }}
          value={presenter.selectedTermIdToAggregate}
        >
          {!presenter.selectedTermIdToAggregate && <option value=''>Choose a term</option>}
          {this.renderTermOptions(presenter)}
        </Select>
        <label>Metric profiles to aggregate from:</label>
        <div className='home-metricProfileCheckboxes home-input'>
          {presenter.getMetricProfileOptions().map(option => {
            return (
              <FormControlLabel
                className='home-checkboxAndLabel'
                disabled={presenter.isSaving()}
                key={option.id}
                control={
                  <Checkbox
                    disabled={presenter.isSaving()}
                    id={`custom-checkbox-${option.id}`}
                    name={`${option.name}`}
                    checked={presenter.selectedMetricProfileIds.some(id => option.id === id)}
                    onChange={() => this.handleMetricProfileIdCheckboxChange(option.id, presenter)}
                    color='primary'
                  />
                }
                label={option.name}
              />
            )
          })}
        </div>
        <Input
          className='home-input'
          disabled={presenter.isSaving()}
          id='form-name'
          label='Name of the above group of profiles'
          name='name'
          onChange={(event: ChangeEvent): void => {
            const target = event.target as HTMLInputElement
            presenter.groupName = target.value
          }}
          pattern='^[a-zA-Z0-9 -]+$'
          patternExplanation='Please keep names limited to letters, numbers, spaces, and dashes'
          required
          value={presenter.groupName}
        />
        {!!presenter.getAggregateTermNamePreview() && (
          <Input
            className='home-input'
            id='home-aggregateTermNamePreview'
            name='home-aggregateTermNamePreview'
            label='Aggregate Term Name Preview'
            disabled
            value={presenter.getAggregateTermNamePreview()}
          />
        )}
        {this.renderCurrentStatusBox(TERM)}
      </Fragment>
    )
  }

  private handleMetricProfileIdCheckboxChange(
    metricProfileId: number,
    presenter: IAggregateTermFormPresenter
  ): void {
    if (presenter.selectedMetricProfileIds.some(id => metricProfileId === id)) {
      presenter.selectedMetricProfileIds = presenter.selectedMetricProfileIds.filter(
        id => id !== metricProfileId
      )
    } else {
      presenter.selectedMetricProfileIds = [...presenter.selectedMetricProfileIds, metricProfileId]
    }
  }

  private presenterIsVariableFormPresenter(
    presenter: IFormPresenter
  ): presenter is IVariableFormPresenter {
    return Object.keys(presenter).some(key => key === '_isCurrentStatus')
  }

  private renderVariableSpecificFields(presenter: IVariableFormPresenter): ReactElement {
    return (
      <Fragment>
        {this.renderNameInput()}
        {!!presenter.getQueryTitle() && this.renderQueryBox(presenter)}
        <Select
          disabled={presenter.isSaving()}
          id='variableForm-type'
          label='Variable Type'
          name='variableType'
          onChange={(event: ChangeEvent) => {
            const target = event.target as HTMLInputElement
            presenter.isCurrentStatus = target.value === 'current-status'
          }}
          required
          value={presenter.isCurrentStatus ? 'current-status' : 'non-current-status'}
        >
          <option value='non-current-status'>Retroactively queryable (most common)</option>
          <option value='current-status'>Only queryable in the present</option>
        </Select>
        {this.renderCurrentStatusBox(VARIABLE)}
      </Fragment>
    )
  }

  private renderCurrentStatusBox(type: string): ReactElement {
    this.isChecked = this.props.presenter.getCheckedStatus()
    return (
      <div className='variableOrTermForm-StatusWrapper'>
        <input
          id='variableOrTermForm-currentStatus'
          type='checkbox'
          checked={this.isChecked}
          onClick={this.clickCheckbox}
        />
        <div className='variableOrTermForm-StatusText'>{`${type} active`}</div>
      </div>
    )
  }

  private clickCheckbox = (): void => {
    this.isChecked = !this.isChecked
    this.props.presenter.updateCurrentActiveStatus(this.isChecked)
  }

  private renderQueryBox(presenter: IVariableFormPresenter): ReactElement {
    return (
      <Fragment>
        <h4>{presenter.getQueryTitle()}</h4>
        <p>{presenter.getQueryInstructions()}</p>
        <div className='variableForm-queryInput'>
          <label htmlFor='variableForm-query'>SOQL (optional)</label>
          <textarea
            disabled={presenter.isLoadingQuery() || presenter.isSaving()}
            id='variableForm-query'
            name='query'
            onChange={(event: ChangeEvent): void => {
              const target = event.target as HTMLInputElement
              presenter.queryString = target.value
            }}
            placeholder={presenter.isLoadingQuery() ? 'Loading...' : 'Paste value here'}
            value={presenter.queryString}
          />
          {presenter.getDateUpdated() && presenter.getUpdatedByName() && (
            <div className='variableForm-queryMetaData'>
              <p>Updated By: {presenter.getUpdatedByName()}</p>
              <p>Date Updated: {presenter.getDateUpdated()}</p>
            </div>
          )}
        </div>
        {Cookies.get('companyId') === DATABASED_COMPANY_ID ||
        Cookies.get('companyId') === DROPLET_COMPANY_ID ||
        Cookies.get('companyId') === BEN_LABS_COMPANY_ID ? (
          <div>
            <h4 className='variableForm-urlSubmissionHeader'>Use Salesforce report URL</h4>
            <ReportUrlSubmissionView presenter={new ReportUrlToQueryTabPresenter(new Fetcher())} />
          </div>
        ) : (
          <div></div>
        )}
      </Fragment>
    )
  }
}

export default FormBodyFactory
