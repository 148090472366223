import { Spinner } from 'common'
import React, { Component, Fragment, ReactElement } from 'react'

import SalesforceSync from '../SalesforceSync/SalesforceSync'
import ICompanyTermsListPresenter from '../SharedAbstractions/ICompanyTermsListPresenter'
import ISubscribingView from '../SharedAbstractions/ISubscribingView'
import SectionView from './SectionView'

type Props = {
  presenter: ICompanyTermsListPresenter
}

class CompanyTermsListView extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  componentWillUnmount(): void {
    this.props.presenter.clearView()
  }

  render(): ReactElement {
    const { presenter } = this.props

    return (
      <div className='home-wrapper'>
        <h2>Metric Definitions</h2>
        {this.renderMessages()}
        {presenter.isLoading() ? (
          <div className='home-loadingState'>
            <Spinner />
          </div>
        ) : (
          <Fragment>
            {presenter.isShowingSyncButton() && (
              <div className='split'>
                <div></div>
                <SalesforceSync presenter={presenter.getSyncPresenter()} />
              </div>
            )}
            <SectionView presenter={presenter.getVariableSectionPresenter()} />
            <SectionView presenter={presenter.getExpressionSectionPresenter()} />
            <SectionView presenter={presenter.getConstantSectionPresenter()} />
            <SectionView presenter={presenter.getAggregateTermSectionPresenter()} />
          </Fragment>
        )}
      </div>
    )
  }

  private renderMessages = (): ReactElement => {
    const { presenter } = this.props
    const successMessage = presenter.getSuccessMessage()
    const errorMessage = presenter.getErrorMessage()
    return (
      <Fragment>
        {successMessage && <p className='home-success success'>{successMessage}</p>}
        {errorMessage && <p className='home-error error'>{errorMessage}</p>}
      </Fragment>
    )
  }

  public update = (): void => {
    this.setState({})
  }
}

export default CompanyTermsListView
