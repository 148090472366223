import IDialogBoxPresenter from './IDialogBoxPresenter'
import ObservedPresenter from './ObservedPresenter'

abstract class DialogBoxPresenter extends ObservedPresenter implements IDialogBoxPresenter {
  private _isShowingForm: boolean

  constructor() {
    super()
    this._isShowingForm = false
  }

  public clickOpen(): void {
    this._isShowingForm = true
    this.updateView()
  }

  public closeForm(): void {
    this._isShowingForm = false
    this.updateView()
  }

  public isShowingForm(): boolean {
    return this._isShowingForm
  }
}

export default DialogBoxPresenter
