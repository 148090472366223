import './style.css'

import React, { Component, ReactElement } from 'react'

import { Page } from '../../shared'
import DynamicTargetDependencyIdentifier from '../../UseCases/DynamicTargetDependencyIdentifier'
import TargetTypeEligibilityDeterminer from '../../UseCases/TargetTypeEligibilityDeterminer'
import TermTargetTypeEligibilityDeterminerFactory from '../../UseCases/TermTargetTypeEligibilityDeterminerFactory'
import MetricForm from './MetricForm'
import MetricProfileForm from './MetricProfileForm'

class Form extends Component {
  pathToForm = {
    '/metric-form': MetricForm,
    '/metric-profile-form': MetricProfileForm
  }

  render(): ReactElement {
    return <Page>{this.renderForm()}</Page>
  }

  renderForm = (): ReactElement => {
    const formPropsString = window.localStorage.getItem('formProps')

    if (formPropsString) {
      const formProps = JSON.parse(formPropsString)
      if (window.location.pathname === '/metric-form') {
        return this.renderMetricForm(formProps)
      }
      return React.createElement(this.pathToForm[window.location.pathname], formProps, null)
    }

    window.location.pathname = '/'
    return <p>No data found for this form. Redirecting to home...</p>
  }

  private renderMetricForm = (formProps: any): ReactElement => {
    return (
      <MetricForm
        id={formProps.id}
        metric={formProps.metric}
        metricProfileId={formProps.metricProfileId}
        mode={formProps.mode}
        terms={formProps.terms}
        eligibilityChecker={
          new TargetTypeEligibilityDeterminer(
            new TermTargetTypeEligibilityDeterminerFactory(
              formProps.termDtos,
              formProps.eligibleMetricDtos
            )
          )
        }
        dependedUponMetricNameFinder={
          new DynamicTargetDependencyIdentifier(formProps.termDtos, formProps.metricDtos)
        }
      />
    )
  }
}

export default Form
