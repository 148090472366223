import IFetcher from '../../../../Drivers/Interfaces/IFetcher'
import DialogBoxPresenter from '../../MetricDefinitions/SharedAbstractions/DialogBoxPresenter'
import IFormCloser from '../../MetricDefinitions/SharedAbstractions/IFormCloser'
import IRefreshFormPresenter from '../RefreshForm/IRefreshFormPresenter'
import RefreshFormPresenter from '../RefreshForm/RefreshFormPresenter'
import IMetricProfilePresenter from './IMetricProfilePresenter'

class MetricProfilePresenter extends DialogBoxPresenter
  implements IMetricProfilePresenter, IFormCloser {
  private readonly refreshFormPresenter: RefreshFormPresenter

  constructor(fetcher: IFetcher, metricProfileId: number) {
    super()
    this.refreshFormPresenter = new RefreshFormPresenter(fetcher, metricProfileId, this)
  }

  public getRefreshFormPresenter(): IRefreshFormPresenter {
    return this.refreshFormPresenter
  }
}

export default MetricProfilePresenter
