import { okHttpCode } from '../../../../Utilities/HttpCodes'
import { IResponse } from '../../../../Utilities/Interfaces'
import { ExistingTermDTO, ExistingVariableDTO, NewVariableDTO } from '../DTOs'
import IOperationCanceler from '../SharedAbstractions/IOperationCanceler'
import ITermCreator from '../SharedAbstractions/ITermCreator'
import ITermDeleter from '../SharedAbstractions/ITermDeleter'
import ITermListUpdateObserver from '../SharedAbstractions/ITermListUpdateObserver'
import ITermReader from '../SharedAbstractions/ITermReader'
import IQueryCreator from './IQueryCreator'
import IVariableFormPresenter from './IVariableFormPresenter'
import VariableFormPresenter from './VariableFormPresenter'

class VariableAddFormPresenter extends VariableFormPresenter implements IVariableFormPresenter {
  constructor(
    operationCanceler: IOperationCanceler,
    termListUpdateObserver: ITermListUpdateObserver,
    private readonly termCreator: ITermCreator & ITermReader & ITermDeleter,
    private readonly companyId: number,
    private readonly queryCreator?: IQueryCreator
  ) {
    super(operationCanceler, termListUpdateObserver, 'Create', queryCreator)
  }

  public getCheckedStatus(): boolean {
    return true
  }

  protected async getResponse(): Promise<IResponse> {
    const newVariableDTO: NewVariableDTO = {
      company_id: this.companyId,
      type: 'variable',
      isCurrentStatus: this.isCurrentStatus,
      name: this.name,
      isActiveVariable: true
    }
    const res = await this.termCreator.create(newVariableDTO)
    if (res.code === okHttpCode && this.queryString && this.queryCreator) {
      const existingTerms = await this.termCreator.getTerms()
      const newlyCreatedVariableDTO: ExistingTermDTO = existingTerms.find(
        existingTermDTO => existingTermDTO.name === this.name
      )
      if (newlyCreatedVariableDTO && this.isVariableDTO(newlyCreatedVariableDTO)) {
        const createQueryResponse = await this.queryCreator.create(
          newlyCreatedVariableDTO.variable_id,
          this.queryString
        )
        if (createQueryResponse.code !== okHttpCode) {
          await this.termCreator.delete(newlyCreatedVariableDTO.id)
          return createQueryResponse
        }
      } else {
        throw Error('Could not find newly created variable')
      }
    }
    return res
  }

  private isVariableDTO(existingTermDTO: ExistingTermDTO): existingTermDTO is ExistingVariableDTO {
    return existingTermDTO.type === 'variable'
  }
}

export default VariableAddFormPresenter
