import { ExistingTermDTO, IdNameDTO, ValidExpressionOperator } from '../DTOs'
import FormPresenter from '../SharedAbstractions/FormPresenter'
import IOperationCanceler from '../SharedAbstractions/IOperationCanceler'
import ITermListUpdateObserver from '../SharedAbstractions/ITermListUpdateObserver'
import IExpressionFormPresenter from './IExpressionFormPresenter'

abstract class ExpressionFormPresenter extends FormPresenter implements IExpressionFormPresenter {
  protected _leftHandTermId: number | undefined
  protected _operator: ValidExpressionOperator | undefined
  protected _rightHandTermId: number | undefined

  constructor(
    operationCanceler: IOperationCanceler,
    termListUpdateObserver: ITermListUpdateObserver,
    operation: string,
    displayDuration?: number
  ) {
    super('', operation, operationCanceler, termListUpdateObserver, 'Expression', displayDuration)
    this._leftHandTermId = undefined
    this._operator = undefined
    this._rightHandTermId = undefined
  }

  get leftHandTermId(): number {
    return this._leftHandTermId
  }

  set leftHandTermId(newTermId: number) {
    if (this.getTermOptions().find(({ id }) => id === newTermId)) {
      this._leftHandTermId = newTermId
    } else {
      this._leftHandTermId = undefined
    }
    this.handleTermChange()
  }

  get operator(): ValidExpressionOperator {
    return this._operator
  }

  set operator(newOperator: ValidExpressionOperator) {
    this._operator = newOperator
    this.handleTermChange()
  }

  get rightHandTermId(): number {
    return this._rightHandTermId
  }

  set rightHandTermId(newTermId: number) {
    if (this.getTermOptions().find(({ id }) => id === newTermId)) {
      this._rightHandTermId = newTermId
    } else {
      this._rightHandTermId = undefined
    }
    this.handleTermChange()
  }

  public getTermOptions(): IdNameDTO[] {
    return this.getAvailableTerms()
      .map((existingTerm: ExistingTermDTO) => {
        return { id: existingTerm.id, name: existingTerm.name }
      })
      .sort((a, b) => (a.name < b.name ? -1 : 1))
  }

  protected requiredFieldsAreNonEmpty(): boolean {
    return !!(this._leftHandTermId && this.operator && this._rightHandTermId && this._name)
  }

  protected handleTermChange(): void {
    this.updateView()
  }

  protected abstract getAvailableTerms(): ExistingTermDTO[]
}

export default ExpressionFormPresenter
