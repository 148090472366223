import { AddLink } from 'common'
import React, { Component, Fragment, ReactElement } from 'react'

import ISectionPresenter from '../SharedAbstractions/ISectionPresenter'
import FormView from './FormView'
import RowView from './RowView'

type Props = {
  presenter: ISectionPresenter
}

class SectionView extends Component<Props> {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  componentWillUnmount(): void {
    this.props.presenter.clearView()
  }

  render(): ReactElement {
    const { presenter } = this.props
    return (
      <details className='metricProfile-subSection'>
        <summary>
          <h4>{presenter.getSectionTitle()}</h4>
        </summary>
        <AddLink label={`Add ${presenter.getTermType()}`} onClick={() => presenter.clickAdd()} />
        {this.renderErrorMessage()}
        {!presenter.getExistingTermIds().length ? (
          <p>{`There are no ${presenter.getTermType().toLowerCase()}s to display.`}</p>
        ) : (
          presenter.getExistingTermIds().map(termId => {
            return <RowView key={termId} presenter={presenter.getRowPresenter(termId)} />
          })
        )}
        {presenter.isShowingAddForm() && <FormView presenter={presenter.getAddFormPresenter()} />}
      </details>
    )
  }

  private renderErrorMessage = (): ReactElement => {
    const errorMessage = this.props.presenter.getErrorMessage()
    return <Fragment>{errorMessage && <p className='home-error error'>{errorMessage}</p>}</Fragment>
  }

  public update = (): void => {
    this.setState({})
  }
}

export default SectionView
