import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { IModal, IModalParam, IModalResponse } from './Interfaces'

const Swal = withReactContent(swal)
class Modal implements IModal {
  public async fire(options: IModalParam): Promise<IModalResponse> {
    const result = await Swal.fire({
      confirmButtonColor: 'var(--color-link)',
      html: options.html,
      icon: options.icon,
      input: options.input,
      preConfirm: options.preConfirm,
      showLoaderOnConfirm: true,
      title: options.title
    })

    return {
      isConfirmed: result.isConfirmed,
      value: result.value
    }
  }

  public isLoading(): boolean {
    return Swal.isLoading()
  }

  public showValidationMessage(message: string): void {
    Swal.showValidationMessage(message)
  }
}

export default Modal
