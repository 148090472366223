import IFetcher from '../../../../../Drivers/Interfaces/IFetcher'
import { syncMicroserviceUrl } from '../../../../../shared'
import { internalServerError, okHttpCode } from '../../../../../Utilities/HttpCodes'
import IResponse from '../../../../../Utilities/Interfaces/IResponse'
import ExistingQueryDTO from '../ExistingQueryDTO'
import IQueryCRUD from '../IQueryCRUD'
import DeleteQueryDTO from './DeleteQueryDTO'
import NewQueryDTO from './NewQueryDTO'
import UpdateQueryDTO from './UpdateQueryDTO'
import VariableIdAndTermId from './VariableIdAndTermId'

class SalesforceQueryManager implements IQueryCRUD {
  constructor(
    private readonly fetcher: IFetcher,
    private readonly existingVariableDTOs: VariableIdAndTermId[],
    private readonly loggedInUserId: number
  ) {}

  public async create(variableId: number, query: string): Promise<IResponse> {
    const newQueryDTO: NewQueryDTO = {
      id: variableId,
      query,
      userId: this.loggedInUserId
    }
    const res = await this.fetcher.fetch({
      method: 'POST',
      url: `${syncMicroserviceUrl}salesforce/query`,
      body: newQueryDTO
    })
    return this.handleResponse(res)
  }

  private handleResponse(response: any): IResponse {
    if (Number.isInteger(response)) {
      return {
        code: response,
        message: response === okHttpCode ? 'Success' : 'Error'
      }
    }
    return {
      code: internalServerError,
      message: 'Unknown Error Occurred'
    }
  }

  public async delete(variableId: number): Promise<IResponse> {
    const deleteQueryDTO: DeleteQueryDTO = {
      variableId
    }
    const res: number = await this.fetcher.fetch({
      method: 'DELETE',
      body: deleteQueryDTO,
      url: `${syncMicroserviceUrl}salesforce/query`
    })
    return this.handleResponse(res)
  }

  public async update(variableId: number, query: string): Promise<IResponse> {
    const updateQueryDTO: UpdateQueryDTO = {
      variableId,
      query,
      userId: this.loggedInUserId
    }
    const res = await this.fetcher.fetch({
      method: 'PUT',
      url: `${syncMicroserviceUrl}salesforce/query`,
      body: updateQueryDTO
    })
    return this.handleResponse(res)
  }

  public getInstructions(): string {
    return 'To add a Salesforce Query, go to query.databased.com and build your query. Then paste the built query here.'
  }

  public async getQuery(variableId: number): Promise<ExistingQueryDTO | null> {
    const termId = this.existingVariableDTOs.find(
      variableDTO => variableDTO.variable_id === variableId
    )?.id

    if (!termId) {
      return null
    }

    const res: object = await this.fetcher.fetch({
      method: 'GET',
      url: `${syncMicroserviceUrl}salesforce/query/${termId}`,
      body: undefined
    })

    if (this.isExistingQueryDTO(res)) {
      return res
    }

    return null
  }

  private isExistingQueryDTO(obj: object): obj is ExistingQueryDTO {
    return (
      obj &&
      Object.prototype.hasOwnProperty.call(obj, 'query') &&
      Object.prototype.hasOwnProperty.call(obj, 'dateUpdated') &&
      Object.prototype.hasOwnProperty.call(obj, 'updatedBy')
    )
  }

  public getTitle(): string {
    return 'Salesforce Query'
  }
}

export default SalesforceQueryManager
