import { Checkbox } from '@material-ui/core'
import { Button, Input, Spinner } from 'common'
import React, { ChangeEvent, Component, Fragment, ReactElement } from 'react'

import ISubscribingView from '../../MetricDefinitions/SharedAbstractions/ISubscribingView'
import IRefreshFormPresenter from './IRefreshFormPresenter'

type Props = {
  presenter: IRefreshFormPresenter
}

class RefreshForm extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  componentWillUnmount(): void {
    this.props.presenter.clearView()
  }

  render(): ReactElement {
    const { presenter } = this.props
    if (presenter.isLoading()) {
      return this.renderLoading()
    }
    return (
      <div>
        {presenter.isShowingCompleteMessage()
          ? this.renderSuccessState()
          : this.renderEmailPrompt()}
      </div>
    )
  }

  public update(): void {
    this.setState({})
  }

  private renderLoading = (): ReactElement => {
    return (
      <Fragment>
        <h3>Sending...</h3>
        <div className='home-loadingState'>
          <Spinner />
        </div>
      </Fragment>
    )
  }

  private renderEmailPrompt = (): ReactElement => {
    const { presenter } = this.props
    return (
      <Fragment>
        <h3>Refresh Coordinates and Conversion Funnel</h3>
        <p>The refresh process will take about 1-2 minutes per user.</p>
        <Input
          id='refresh-email'
          label='Notification Email (Optional)'
          name='email'
          type='email'
          onChange={(event: ChangeEvent): void => {
            const target = event.target as HTMLInputElement
            presenter.email = target.value
          }}
          value={presenter.email}
        />
        <label>
          <Checkbox
            checked={presenter.isCalcTodayOnly}
            onChange={(event: ChangeEvent): void => {
              const target = event.target as HTMLInputElement
              presenter.isCalcTodayOnly = target.checked
            }}
          />
          Only Calculate Today's Coordinate
        </label>
        <div className='home-formControls'>
          <Button
            className='primaryButton home-modalButton'
            label={'Start Refresh'}
            onClick={() => presenter.clickBeginJob()}
          />
          <a onClick={() => presenter.clickCloseForm()}>Cancel</a>
        </div>
      </Fragment>
    )
  }

  private renderSuccessState = (): ReactElement => {
    const { presenter } = this.props
    return (
      <Fragment>
        <h3>Refresh has started!</h3>
        <Button
          className='primaryButton home-modalButton'
          label={'OK'}
          onClick={() => presenter.clickCloseForm()}
        />
      </Fragment>
    )
  }
}

export default RefreshForm
