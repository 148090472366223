import { ExistingAggregateTermDTO, ExistingTermDTO, IdNameDTO } from '../DTOs'
import IErrorMessageDisplayer from '../SharedAbstractions/IErrorMessageDisplayer'
import ITermDeleter from '../SharedAbstractions/ITermDeleter'
import ITermListUpdateObserver from '../SharedAbstractions/ITermListUpdateObserver'
import ITermUpdater from '../SharedAbstractions/ITermUpdater'
import RowPresenter from '../SharedAbstractions/RowPresenter'
import AggregateTermEditFormPresenter from './AggregateTermEditFormPresenter'
import IAggregateTermFormPresenter from './IAggregateTermFormPresenter'
import IAggregateTermRowPresenter from './IAggregateTermRowPresenter'

class AggregateTermRowPresenter extends RowPresenter implements IAggregateTermRowPresenter {
  private readonly editFormPresenter: AggregateTermEditFormPresenter

  constructor(
    termDeleterAndUpdater: ITermDeleter & ITermUpdater,
    termListUpdateObserver: ITermListUpdateObserver,
    errorMessageDisplayer: IErrorMessageDisplayer,
    existingTermDTO: ExistingAggregateTermDTO,
    existingTermDTOs: ExistingTermDTO[],
    metricProfileDTOs: IdNameDTO[]
  ) {
    super(
      existingTermDTO,
      termDeleterAndUpdater,
      termListUpdateObserver,
      errorMessageDisplayer,
      'Aggregate Term'
    )
    this.editFormPresenter = new AggregateTermEditFormPresenter(
      this,
      termListUpdateObserver,
      existingTermDTOs,
      metricProfileDTOs,
      existingTermDTO,
      termDeleterAndUpdater
    )
  }

  public getEditFormPresenter(): IAggregateTermFormPresenter {
    return this.editFormPresenter
  }

  public getPreview(): string {
    return ''
  }
}

export default AggregateTermRowPresenter
