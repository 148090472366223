import AggregateTargetTypeEligibilityDeterminer from '../Entities/AggregateTargetTypeEligibilityDeterminer'
import ConstantTargetTypeEligibilityDeterminer from '../Entities/ConstantTargetTypeEligibilityDeterminer'
import ExpressionTargetTypeEligibigiliyDeterminer from '../Entities/ExpressionTargetTypeEligibilityDeterminer'
import TermTargetTypeEligibilityDeterminer from '../Entities/TermTargetTypeEligibilityDeterminer'
import VariableTargetTypeEligibilityDeterminer from '../Entities/VariableTargetTypeEligibilityDeterminer'
import ITermTargetTypeEligibilityDeterminerFactory from './ITermTargetTypeEligibilityDeterminerFactory'
import MetricDTO from './MetricDTO'
import AggregateTermDTO from './TermDTOs/AggregateTermDTO'
import ExpressionDTO from './TermDTOs/ExpressionDTO'
import TermDTO from './TermDTOs/TermDTO'

class TermTargetTypeEligibilityDeterminerFactory
  implements ITermTargetTypeEligibilityDeterminerFactory {
  private readonly metricProfileIdToMetricTermIds: Map<number, Set<number>>
  private readonly termIdToTermDTO: Map<number, TermDTO>

  constructor(termDTOs: TermDTO[], metrics: MetricDTO[]) {
    this.metricProfileIdToMetricTermIds = new Map()
    metrics.forEach(metric => {
      if (!this.metricProfileIdToMetricTermIds.has(metric.metricProfileId)) {
        this.metricProfileIdToMetricTermIds.set(metric.metricProfileId, new Set())
      }
      this.metricProfileIdToMetricTermIds.get(metric.metricProfileId).add(metric.termId)
    })
    this.termIdToTermDTO = new Map()
    termDTOs.forEach(termDTO => this.termIdToTermDTO.set(termDTO.termId, termDTO))
  }

  public getTermByIdAndMetricProfileIdIntendingToUseTermAsMetric(
    termId: number,
    metricProfileId: number
  ): TermTargetTypeEligibilityDeterminer {
    const termDto: TermDTO | undefined = this.termIdToTermDTO.get(termId)
    if (termDto && termDto.type === 'variable') {
      return new VariableTargetTypeEligibilityDeterminer(
        this.metricProfileIdToMetricTermIds.get(metricProfileId)?.has(termId) || false
      )
    }

    if (this.isExpressionDTO(termDto)) {
      return new ExpressionTargetTypeEligibigiliyDeterminer(
        this.getTermByIdAndMetricProfileIdIntendingToUseTermAsMetric(
          termDto.leftHandTermId,
          metricProfileId
        ),
        this.getTermByIdAndMetricProfileIdIntendingToUseTermAsMetric(
          termDto.rightHandTermId,
          metricProfileId
        ),
        this.metricProfileIdToMetricTermIds.get(metricProfileId)?.has(termId) || false
      )
    }

    if (this.isAggregateTermDTO(termDto)) {
      return new AggregateTargetTypeEligibilityDeterminer(
        termDto.metricProfileIdsToAggregate.map(metricProfileIdToAggregate =>
          this.getTermByIdAndMetricProfileIdIntendingToUseTermAsMetric(
            termDto.termIdToAggregate,
            metricProfileIdToAggregate
          )
        ),
        this.metricProfileIdToMetricTermIds.get(metricProfileId)?.has(termId) || false
      )
    }
    return new ConstantTargetTypeEligibilityDeterminer()
  }

  private isExpressionDTO(termDTO: TermDTO | undefined): termDTO is ExpressionDTO {
    return termDTO && termDTO.type === 'expression'
  }

  private isAggregateTermDTO(termDTO: TermDTO | undefined): termDTO is AggregateTermDTO {
    return termDTO && termDTO.type === 'aggregate'
  }
}

export default TermTargetTypeEligibilityDeterminerFactory
