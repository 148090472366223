import IExpressionChangeValidator from '../../../../UseCases/IExpressionChangeValidator'
import { ExistingExpressionDTO, ExistingTermDTO, ValidExpressionOperator } from '../DTOs'
import IErrorMessageDisplayer from '../SharedAbstractions/IErrorMessageDisplayer'
import ITermDeleter from '../SharedAbstractions/ITermDeleter'
import ITermListUpdateObserver from '../SharedAbstractions/ITermListUpdateObserver'
import ITermUpdater from '../SharedAbstractions/ITermUpdater'
import RowPresenter from '../SharedAbstractions/RowPresenter'
import ExpressionEditFormPresenter from './ExpressionEditFormPresenter'
import IExpressionFormPresenter from './IExpressionFormPresenter'
import IExpressionRowPresenter from './IExpressionRowPresenter'

class ExpressionRowPresenter extends RowPresenter implements IExpressionRowPresenter {
  private readonly _leftHandTermName: string
  private readonly _rightHandTermName: string
  private readonly editFormPresenter: ExpressionEditFormPresenter

  constructor(
    private readonly originalDTO: ExistingExpressionDTO,
    termDeleterAndUpdater: ITermDeleter & ITermUpdater,
    termListUpdateObserver: ITermListUpdateObserver,
    errorMessageDisplayer: IErrorMessageDisplayer,
    existingTerms: ExistingTermDTO[],
    expressionUpdateValidator: IExpressionChangeValidator
  ) {
    super(
      originalDTO,
      termDeleterAndUpdater,
      termListUpdateObserver,
      errorMessageDisplayer,
      'Expression'
    )

    let leftHandTermName: string
    let rightHandTermName: string
    existingTerms.forEach((existingTerm: ExistingTermDTO) => {
      if (existingTerm.id === this.originalDTO.lh_term) {
        leftHandTermName = existingTerm.name
      }
      if (existingTerm.id === this.originalDTO.rh_term) {
        rightHandTermName = existingTerm.name
      }
    })
    this._leftHandTermName = leftHandTermName
    this._rightHandTermName = rightHandTermName
    this.editFormPresenter = new ExpressionEditFormPresenter(
      this,
      termListUpdateObserver,
      existingTerms,
      originalDTO,
      termDeleterAndUpdater,
      expressionUpdateValidator
    )
  }

  public getPreview(): string {
    return `${this.getLeftHandTermName()} ${this.getOperator()} ${this.getRightHandTermName()}`
  }

  private getLeftHandTermName(): string {
    return this._leftHandTermName
  }

  private getOperator(): ValidExpressionOperator {
    return this.originalDTO.operator
  }

  private getRightHandTermName(): string {
    return this._rightHandTermName
  }

  public getEditFormPresenter(): IExpressionFormPresenter {
    return this.editFormPresenter
  }
}

export default ExpressionRowPresenter
