import { IResponse } from '../../../../Utilities/Interfaces'
import { ExistingAggregateTermDTO, ExistingTermDTO, IdNameDTO } from '../DTOs'
import IOperationCanceler from '../SharedAbstractions/IOperationCanceler'
import ITermListUpdateObserver from '../SharedAbstractions/ITermListUpdateObserver'
import ITermUpdater from '../SharedAbstractions/ITermUpdater'
import AggregateTermFormPresenter from './AggregateTermFormPresenter'

class AggregateTermEditFormPresenter extends AggregateTermFormPresenter {
  constructor(
    operationCanceler: IOperationCanceler,
    termListUpdateObserver: ITermListUpdateObserver,
    existingTermDTOs: ExistingTermDTO[],
    metricProfileDTOs: IdNameDTO[],
    private readonly originalAggregateTerm: ExistingAggregateTermDTO,
    private readonly updater: ITermUpdater
  ) {
    super(operationCanceler, termListUpdateObserver, existingTermDTOs, metricProfileDTOs, 'Update')
    this.name = originalAggregateTerm.name
    this.selectedMetricProfileIds = originalAggregateTerm.metricProfileIdsToAggregate.slice()
    this.selectedTermIdToAggregate = originalAggregateTerm.termIdToAggregate
    if (this.name.includes(':')) {
      const [beforeColon] = this.name.split(':')
      if (beforeColon.includes('Managed ')) {
        const afterManaged = beforeColon.split('Managed ')[1]
        this.groupName = afterManaged
      }
    } else {
      this.groupName = ''
    }
    this.currentActiveStatus = this.originalAggregateTerm.isActive
  }

  public getTermOptions(): IdNameDTO[] {
    return this.existingTermDTOs.filter(term => term.id !== this.originalAggregateTerm.id)
  }

  public isSaveEnabled(): boolean {
    return (
      this.requiredFieldsAreDefined() &&
      (this.getAggregateTermNamePreview() !== this.name ||
        JSON.stringify(this.originalAggregateTerm.metricProfileIdsToAggregate) !==
          JSON.stringify(this.selectedMetricProfileIds) ||
        this.originalAggregateTerm.termIdToAggregate !== this.selectedTermIdToAggregate ||
        this.currentActiveStatus !== this.originalAggregateTerm.isActive)
    )
  }

  protected getResponse(): Promise<IResponse> {
    return this.updater.update(this.originalAggregateTerm.id, {
      name: this.getAggregateTermNamePreview(),
      metricProfileIdsToAggregate: this.selectedMetricProfileIds,
      termIdToAggregate: this.selectedTermIdToAggregate,
      isActiveVariable: this.currentActiveStatus ?? true,
      isActiveTerm: this.currentActiveStatus ?? true
    })
  }

  public getCheckedStatus(): boolean {
    return this.currentActiveStatus ?? true
  }
}

export default AggregateTermEditFormPresenter
