import React, { Component } from 'react'
import { FaExclamationCircle, FaPencilAlt, FaTrashAlt } from 'react-icons/fa'

import IRowPresenter from '../SharedAbstractions/IRowPresenter'
import FormView from './FormView'

type Props = {
  presenter: IRowPresenter
}

class RowView extends Component<Props> {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  componentWillUnmount(): void {
    this.props.presenter.clearView()
  }

  render() {
    const { presenter } = this.props

    if (presenter.isDeleting()) {
      return <div className='home-termRow'>Deleting...</div>
    }

    return (
      <div className='home-termRow'>
        {presenter.getName()}
        {presenter.getPreview() ? `: ${presenter.getPreview()}` : ''}
        <div className='home-actionIcons'>
          <div className='home-editIcon'>
            <FaPencilAlt onClick={() => presenter.clickEdit()} />
          </div>
          {presenter.isShowingClickDeleteAgainConfirmation() ? (
            <span className='home-deleteIcon home-warn' onClick={() => presenter.clickDelete()}>
              <FaExclamationCircle /> click to confirm
            </span>
          ) : (
            <FaTrashAlt className='home-deleteIcon' onClick={() => presenter.clickDelete()} />
          )}
          {presenter.isShowingEditForm() && (
            <FormView presenter={presenter.getEditFormPresenter()} />
          )}
        </div>
      </div>
    )
  }

  public update = (): void => {
    this.setState({})
  }
}

export default RowView
