import IFetcher from '../../../../Drivers/Interfaces/IFetcher'
import { baseApiUrl } from '../../../../shared'
import EmailJobRequestPresenter from '../../MetricDefinitions/SharedAbstractions/EmailJobRequestPresenter'
import IFormCloser from '../../MetricDefinitions/SharedAbstractions/IFormCloser'
import IRefreshFormPresenter from './IRefreshFormPresenter'

class RefreshFormPresenter extends EmailJobRequestPresenter implements IRefreshFormPresenter {
  private _isCalcTodayOnly: boolean

  constructor(
    private readonly fetcher: IFetcher,
    private readonly metricProfileId: number,
    formCloser: IFormCloser
  ) {
    super(formCloser)
    this._isCalcTodayOnly = true
  }

  protected sendRequest(): Promise<void> {
    const params = {
      body: undefined,
      url: this.isNullOrUndefined(this.metricProfileId)
        ? `${baseApiUrl}metricProfiles/sync`
        : `${baseApiUrl}metricProfiles/${this.metricProfileId}/sync`,
      method: 'GET'
    }
    const extraHeaders = {
      email: this._email || undefined,
      'is-calculate-today-only': this._isCalcTodayOnly ? 'TRUE' : 'FALSE',
      priority: 'high'
    }
    return this.fetcher.fetch(params, extraHeaders)
  }

  public get isCalcTodayOnly(): boolean {
    return this._isCalcTodayOnly
  }

  public set isCalcTodayOnly(isCalcTodayOnly: boolean) {
    this._isCalcTodayOnly = isCalcTodayOnly
    this.updateView()
  }

  private isNullOrUndefined(toCheck: any) {
    return toCheck === null || toCheck === undefined
  }
}

export default RefreshFormPresenter
