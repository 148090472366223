import { IResponse } from 'Utilities/Interfaces'

import { okHttpCode } from '../../../../Utilities/HttpCodes'
import { ExistingTermDTO } from '../DTOs'
import ITermDeleter from '../SharedAbstractions/ITermDeleter'
import IErrorMessageDisplayer from './IErrorMessageDisplayer'
import IFormPresenter from './IFormPresenter'
import IOperationCanceler from './IOperationCanceler'
import IRowPresenter from './IRowPresenter'
import ITermListUpdateObserver from './ITermListUpdateObserver'
import ObservedPresenter from './ObservedPresenter'

const DEFAULT_CLICK_TO_CONFIRM_DURATION = 3000

abstract class RowPresenter extends ObservedPresenter implements IRowPresenter, IOperationCanceler {
  private _isDeletingConstant: boolean
  private _isShowingClickAgainToConfirm: boolean
  private _isShowingEditForm: boolean

  constructor(
    private readonly existingTermDTO: ExistingTermDTO,
    private readonly termDeleter: ITermDeleter,
    private readonly termListUpdateObserver: ITermListUpdateObserver,
    private readonly errorMessageDisplayer: IErrorMessageDisplayer,
    private readonly termType: string,
    private readonly confirmationDuration: number = DEFAULT_CLICK_TO_CONFIRM_DURATION
  ) {
    super()
    this._isShowingEditForm = false
    this._isDeletingConstant = false
    this._isShowingClickAgainToConfirm = false
  }

  public abstract getEditFormPresenter(): IFormPresenter

  public abstract getPreview(): string

  protected getDeletionResponse(): Promise<IResponse> {
    return this.termDeleter.delete(this.existingTermDTO.id)
  }

  public async clickDelete(): Promise<void> {
    if (this.isShowingClickDeleteAgainConfirmation()) {
      this._isDeletingConstant = true
      this._isShowingClickAgainToConfirm = false
      this.updateView()
      const response = await this.getDeletionResponse()
      this._isDeletingConstant = false
      this.updateView()
      if (response.code === okHttpCode) {
        this.termListUpdateObserver.notifyOfSuccessfulTermListChange(
          `Successfully Deleted ${this.termType}`
        )
      } else {
        this.errorMessageDisplayer.showErrorMessage(`Failed to Delete ${this.termType}.`)
      }
    } else {
      this._isShowingClickAgainToConfirm = true
      this.updateView()
      setTimeout(() => {
        if (this._isShowingClickAgainToConfirm) {
          this._isShowingClickAgainToConfirm = false
          this.updateView()
        }
      }, this.confirmationDuration)
    }
  }

  public clickEdit(): void {
    this._isShowingEditForm = true
    this.updateView()
  }

  public getName(): string {
    return this.existingTermDTO.name
  }

  public isDeleting(): boolean {
    return this._isDeletingConstant
  }

  public isShowingClickDeleteAgainConfirmation(): boolean {
    return this._isShowingClickAgainToConfirm
  }

  public isShowingEditForm(): boolean {
    return this._isShowingEditForm
  }

  public cancelOperation(): void {
    this._isShowingEditForm = false
    this.updateView()
  }
}

export default RowPresenter
