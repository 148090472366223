import Modal from '@material-ui/core/Modal'
import { Button } from 'common'
import React, { Component, Fragment, ReactElement } from 'react'

import IFormPresenter from '../SharedAbstractions/IFormPresenter'
import FormBodyFactory from './FormBodyFactory'

type Props = {
  presenter: IFormPresenter
}

class FormView extends Component<Props> {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  componentWillUnmount(): void {
    this.props.presenter.clearView()
  }

  render(): ReactElement {
    const { presenter } = this.props
    return (
      <Modal open onClose={() => presenter.cancelOperation()}>
        <div className='home-modalBody'>
          {this.renderErrorMessage()}
          <h3>
            {presenter.getOperation()} {presenter.getTermType()}
          </h3>
          <FormBodyFactory presenter={presenter} />
          <div className='home-formControls'>
            <Button
              busy={presenter.isSaving()}
              className='primaryButton home-modalButton'
              disabled={!presenter.isSaveEnabled() || presenter.isSaving()}
              label={
                presenter.isSaving()
                  ? 'Saving...'
                  : `${presenter.getOperation()} ${presenter.getTermType()}`
              }
              onClick={() => presenter.clickSave()}
            />
            <a
              className={`${presenter.isSaving() ? 'disabled' : ''}`}
              onClick={() => presenter.cancelOperation()}
            >
              Cancel
            </a>
          </div>
        </div>
      </Modal>
    )
  }

  private renderErrorMessage = (): ReactElement => {
    const errorMessage = this.props.presenter.getErrorMessage()
    return <Fragment>{errorMessage && <p className='home-error error'>{errorMessage}</p>}</Fragment>
  }

  public update(): void {
    this.setState({})
  }
}

export default FormView
