import IExpressionChangeValidator from './IExpressionChangeValidator'
import MetricDTO from './MetricDTO'
import TargetTypeEligibilityDeterminer from './TargetTypeEligibilityDeterminer'
import ExpressionDTO from './TermDTOs/ExpressionDTO'
import TermDTO from './TermDTOs/TermDTO'
import TermTargetTypeEligibilityDeterminerFactory from './TermTargetTypeEligibilityDeterminerFactory'

class ExpressionUpdateValidator implements IExpressionChangeValidator {
  private termIdToTerm: Map<number, TermDTO>
  private terms: TermDTO[]
  private metrics: MetricDTO[]

  constructor() {
    this.terms = []
    this.metrics = []
    this.termIdToTerm = new Map()
  }

  public initialize(terms: TermDTO[], metrics: MetricDTO[]): void {
    this.terms = terms
    this.metrics = metrics
    this.terms.forEach((term: TermDTO) => this.termIdToTerm.set(term.termId, term))
  }

  public isInitialized(): boolean {
    return this.termIdToTerm.size > 0
  }

  public getNamesOfMetricsThatWouldBreakIfExpressionBecame(newExpression: ExpressionDTO): string[] {
    return ExpressionUpdateValidator.removeDuplicates([
      ...this.getNamesOfNumPeopleManagedTargetMetricsThatWillBreak(newExpression),
      ...this.getNamesOfDynamicTargetMetricsThatWillBreak(newExpression)
    ])
  }

  private static removeDuplicates(array: string[]): string[] {
    return Array.from(new Set(array))
  }

  private getNamesOfNumPeopleManagedTargetMetricsThatWillBreak(
    newExpression: ExpressionDTO
  ): string[] {
    return this.getProfileNameAndMetricName(
      this.getMetricsWithPeopleManagedTargetsThatWouldBreakWithNewExpression(newExpression)
    )
  }

  private getMetricsWithPeopleManagedTargetsThatWouldBreakWithNewExpression(
    newExpression: ExpressionDTO
  ): MetricDTO[] {
    return this.getMetricsCurrentlyUsingPeopleManagedTargets().filter(
      (metric: MetricDTO) =>
        !new TargetTypeEligibilityDeterminer(
          new TermTargetTypeEligibilityDeterminerFactory(
            this.getTermsWithNewExpressionSubbedIn(newExpression),
            this.getMetricTermsExcludingMetric(metric)
          )
        ).isEligibleForPeopleManagedTarget(metric.termId)
    )
  }

  private getTermsWithNewExpressionSubbedIn(newExpression: ExpressionDTO): TermDTO[] {
    return [...this.terms.filter(term => term.termId !== newExpression.termId), newExpression]
  }

  private getMetricTermsExcludingMetric(metricToExclude: MetricDTO): MetricDTO[] {
    return this.metrics.filter((m: MetricDTO) => m.id !== metricToExclude.id)
  }

  private getMetricsCurrentlyUsingPeopleManagedTargets(): MetricDTO[] {
    return this.metrics.filter(
      (metric: MetricDTO) =>
        metric.benchmarkType === 'fixedTimesNumPeopleManaged' &&
        new TargetTypeEligibilityDeterminer(
          new TermTargetTypeEligibilityDeterminerFactory(
            this.terms,
            this.getMetricTermsExcludingMetric(metric)
          )
        ).isEligibleForPeopleManagedTarget(metric.termId)
    )
  }

  private getProfileNameAndMetricName(metrics: MetricDTO[]): string[] {
    return metrics.map(
      (metric: MetricDTO) =>
        `${metric.metricProfileName}: ${this.termIdToTerm.get(metric.termId).name}`
    )
  }

  private getNamesOfDynamicTargetMetricsThatWillBreak(newExpression: ExpressionDTO): string[] {
    return this.getProfileNameAndMetricName(
      this.getMetricsWithDynamicTargetsThatWouldBreakWithNewExpression(newExpression)
    )
  }

  private getMetricsWithDynamicTargetsThatWouldBreakWithNewExpression(
    newExpression: ExpressionDTO
  ): MetricDTO[] {
    return this.getMetricsCurrentlyUsingDynamicTargets().filter(
      (metric: MetricDTO) =>
        !new TargetTypeEligibilityDeterminer(
          new TermTargetTypeEligibilityDeterminerFactory(
            this.getTermsWithNewExpressionSubbedIn(newExpression),
            this.getMetricTermsExcludingMetric(metric)
          )
        ).isEligibleForDynamicTarget(metric.termId, metric.metricProfileId)
    )
  }

  private getMetricsCurrentlyUsingDynamicTargets(): MetricDTO[] {
    return this.metrics.filter(
      (metric: MetricDTO) =>
        metric.benchmarkType === 'percentOfAgg' &&
        new TargetTypeEligibilityDeterminer(
          new TermTargetTypeEligibilityDeterminerFactory(
            this.terms,
            this.getMetricTermsExcludingMetric(metric)
          )
        ).isEligibleForDynamicTarget(metric.termId, metric.metricProfileId)
    )
  }
}

export default ExpressionUpdateValidator
