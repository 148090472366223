import { DbDataHelper, StringHelper } from 'common'
import React, { Component, ReactElement } from 'react'

import { IRequestComponent } from '../../../shared'
import { okHttpCode } from '../../../Utilities/HttpCodes'
import { IResponse } from '../../../Utilities/Interfaces'
import { backToPreviousPage } from '../../../Utilities/LocalStorageHelper'
import GenericFormView from './GenericFormView'

type Props = {
  changed: boolean
  children: React.ReactNode
  metricProfileId?: number
  mode: 'add' | 'edit'
  objectName: string
  params: object
  reasonForNotDeletable?: string
  reasonForNotSavable?: string
  submitUrl: string
}

type State = {
  apiError: string
  busy: boolean
}

class GenericForm extends Component<Props, State> implements IRequestComponent {
  static defaultProps = {
    metricProfileId: null,
    reasonForNotDeletable: undefined,
    reasonForNotSavable: undefined
  }

  state = {
    busy: false,
    apiError: ''
  }

  render(): ReactElement {
    const {
      changed,
      children,
      metricProfileId,
      mode,
      objectName,
      reasonForNotDeletable,
      reasonForNotSavable
    } = this.props
    const { busy, apiError } = this.state

    return (
      <GenericFormView
        apiError={apiError}
        busy={busy}
        changed={changed}
        deleteObject={this.deleteObject}
        metricProfileId={metricProfileId}
        mode={mode}
        onSubmit={this.onSubmit}
        objectName={objectName}
        reasonForNotDeletable={reasonForNotDeletable}
        reasonForNotSavable={reasonForNotSavable}
      >
        {children}
      </GenericFormView>
    )
  }

  deleteObject = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault()
    this.operateOnObject('delete')
  }

  operateOnObject = async (method: string): Promise<void> => {
    const { params, submitUrl } = this.props

    DbDataHelper.request({
      component: this,
      method,
      params: method === 'delete' ? {} : params,
      requiresAuth: true,
      successCallback: response => this.handleRequestResponse(response, method),
      url: submitUrl
    })
  }

  handleRequestResponse = (response: IResponse, method: string): void => {
    const methodToOperationType = {
      post: 'add',
      put: 'edit',
      delete: 'delete'
    }

    if (!this.isRequestSuccess(response)) {
      this.setState({ apiError: `${response.code} Error: ${response.message}` })
    } else {
      this.notifyUserOfSuccess(methodToOperationType[method.toLowerCase()])
    }
  }

  isRequestSuccess = (response: any): boolean => {
    return response?.code === okHttpCode || response.id
  }

  private notifyUserOfSuccess = (operationType: 'add' | 'delete' | 'edit'): void => {
    const { metricProfileId, objectName } = this.props
    const pastTenseOperation = operationType === 'delete' ? 'deleted' : `${operationType}ed`

    localStorage.setItem(
      'formSuccessMessage',
      `${StringHelper.capitalize(objectName)} ${pastTenseOperation} successfully!`
    )

    backToPreviousPage(metricProfileId)
  }

  onSubmit = async (): Promise<void> => {
    const { mode } = this.props
    const modeToMethod = {
      add: 'post',
      edit: 'put'
    }

    this.operateOnObject(modeToMethod[mode])
  }
}

export default GenericForm
