/* eslint-disable camelcase */
import './style.css'

import { AddLink, JsonHelper } from 'common'
import React, { Component, Fragment, ReactElement } from 'react'

import Benchmark from '../../../../Entities/Benchmark'
import MetricDTO from '../../../../UseCases/MetricDTO'
import TermDTO from '../../../../UseCases/TermDTOs/TermDTO'
import IBenchmarkSchedule from '../../IBenchmarkSchedule'
import IExpandedTerm from '../../IExpandedTerm'
import IMetric from '../../IMetric'

type Props = {
  companyMetricDtos: MetricDTO[]
  companyTermDtos: TermDTO[]
  loading: boolean
  metricProfileId: number
  metrics: IMetric[]
  terms: IExpandedTerm[]
}

class MetricSection extends Component<Props> {
  static defaultProp = {
    loading: false
  }

  render(): ReactElement {
    const { loading } = this.props

    return (
      <div className='metricProfile-subSection'>
        <h4>Metrics</h4>
        <AddLink
          label='Add a metric'
          className={`${loading && 'home-disabledLink'}`}
          onClick={loading ? '' : this.openMetricForm}
          disabled={loading}
        />
        {this.renderTableView()}
        {this.renderDetailsView()}
      </div>
    )
  }

  openMetricForm = (): void => {
    const { metricProfileId, terms, companyMetricDtos, companyTermDtos } = this.props

    const props = {
      metricProfileId,
      mode: 'add',
      terms,
      termDtos: companyTermDtos,
      metricDtos: companyMetricDtos,
      eligibleMetricDtos: companyMetricDtos
    }

    window.localStorage.setItem('formProps', JSON.stringify(props))
    window.location.pathname = '/metric-form'
  }

  renderTableView = (): ReactElement => {
    const { loading, metrics } = this.props

    if (!loading && !metrics.length) {
      return null
    }

    return (
      <table className='metricSection-tableView table-hover'>
        <thead>
          <tr>
            <th>Metric</th>
            <th>Time period</th>
            <th>Days into period</th>
            <th>Benchmarks</th>
          </tr>
        </thead>
        {loading ? this.renderTableLoadingState() : this.renderTableBody()}
      </table>
    )
  }

  renderTableLoadingState = (): ReactElement => {
    return (
      <tbody className='metricSection-loadingContent'>
        <tr>
          <td>
            <p className='metricProfile-loadingSpace metricSection-loadingTableMetric'></p>
          </td>
          <td>
            <p className='metricProfile-loadingSpace metricSection-loadingTableTimePeriod'></p>
          </td>
          <td>
            <p className='metricProfile-loadingSpace metricSection-loadingTableDaysIntoPeriod'></p>
          </td>
          <td>
            <p className='metricProfile-loadingSpace metricSection-loadingTableBenchmarks'></p>
          </td>
        </tr>
        <tr>
          <td>
            <p className='metricProfile-loadingSpace metricSection-loadingTableMetric'></p>
          </td>
          <td>
            <p className='metricProfile-loadingSpace metricSection-loadingTableTimePeriod'></p>
          </td>
          <td>
            <p className='metricProfile-loadingSpace metricSection-loadingTableDaysIntoPeriod'></p>
          </td>
          <td>
            <p className='metricProfile-loadingSpace metricSection-loadingTableBenchmarks'></p>
          </td>
        </tr>
        <tr>
          <td>
            <p className='metricProfile-loadingSpace metricSection-loadingTableMetric'></p>
          </td>
          <td>
            <p className='metricProfile-loadingSpace metricSection-loadingTableTimePeriod'></p>
          </td>
          <td>
            <p className='metricProfile-loadingSpace metricSection-loadingTableDaysIntoPeriod'></p>
          </td>
          <td>
            <p className='metricProfile-loadingSpace metricSection-loadingTableBenchmarks'></p>
          </td>
        </tr>
      </tbody>
    )
  }

  renderTableBody = (): ReactElement => {
    const { metrics } = this.props

    return (
      <tbody>
        {metrics.length ? (
          metrics.map(metric => {
            const metricTerm = this.getMetricTermString(metric.termId)

            return (
              <tr data-id={metric.id} key={metric.id} onClick={this.editMetric}>
                <td>
                  <div className='metricSection-metricName'>{metric.name}</div>
                  <div className='metricSection-metricDetail'>{metricTerm}</div>
                </td>
                <td>{this.getTimePeriodString(metric.benchmarkSchedule.timePeriod)}</td>
                <td>{metric.benchmarkSchedule.daysIntoPeriodThreshold}</td>
                <td className='metricSection-benchmarks'>
                  {this.renderBenchmarkData(metric.benchmarkSchedule)}
                </td>
              </tr>
            )
          })
        ) : (
          <tr>
            <td>This metric profile does not have any metrics.</td>
          </tr>
        )}
      </tbody>
    )
  }

  editMetric = (event: React.MouseEvent): void => {
    event.preventDefault()
    const { companyMetricDtos, companyTermDtos, metricProfileId, metrics, terms } = this.props
    const target = (event.target as HTMLElement).closest('[data-id]')
    const id = parseInt(target.getAttribute('data-id'), 10)
    const metricToEdit = metrics.find(metricObject => metricObject.id === id)

    const formProps = {
      id,
      metricProfileId,
      metric: metricToEdit,
      mode: 'edit',
      terms,
      termDtos: companyTermDtos,
      eligibleMetricDtos: companyMetricDtos.filter(
        (metricDTO: MetricDTO) => metricToEdit.termId !== metricDTO.termId
      ),
      metricDtos: companyMetricDtos
    }

    window.localStorage.setItem('formProps', JSON.stringify(formProps))
    window.location.pathname = '/metric-form'
  }

  getMetricTermString = (termId: number): string => {
    const { terms } = this.props
    const metricTerm = terms.find(term => term.id === termId)

    if (metricTerm?.expression_id) {
      return `${metricTerm.lh_term.name} ${metricTerm.operator} ${metricTerm.rh_term.name}`
    }

    return null
  }

  getTimePeriodString = (abbreviation: string): string => {
    const abbreviationToTimePeriod = {
      m: 'Monthly',
      q: 'Quarterly',
      d: 'Per Workday'
    }

    if (JsonHelper.hasProperty(abbreviationToTimePeriod, abbreviation)) {
      return abbreviationToTimePeriod[abbreviation]
    }
    return abbreviation
  }

  renderBenchmarkData = (benchmarkSchedule: IBenchmarkSchedule): ReactElement => {
    const { rampStages } = benchmarkSchedule
    const benchmark = new Benchmark(benchmarkSchedule)

    return rampStages.reduce((previous, rampStage, i) => {
      if (i === 0) {
        return this.hasChildTarget({ index: i, benchmark, rampStagesLength: rampStages.length }) ? (
          <Fragment>{benchmark.getBenchmarkString(rampStage)} of child target</Fragment>
        ) : (
          <Fragment>{benchmark.getBenchmarkString(rampStage)}</Fragment>
        )
      }

      return (
        <Fragment>
          {previous}, <span className='metricSection-benchmarkSeparator' />
          {this.hasChildTarget({ index: i, benchmark, rampStagesLength: rampStages.length }) ? (
            <Fragment>{benchmark.getBenchmarkString(rampStage)} of child target</Fragment>
          ) : (
            benchmark.getBenchmarkString(rampStage)
          )}
        </Fragment>
      )
    }, <Fragment />)
  }

  hasChildTarget = (params: { benchmark; index: number; rampStagesLength: number }): boolean => {
    const { benchmark, index, rampStagesLength } = params
    return index === rampStagesLength - 1 && benchmark.isPercentOfAggregateBenchmark()
  }

  renderDetailsView = (): ReactElement => {
    const { loading } = this.props
    return loading ? this.renderDetailsLoadingState() : this.renderDetailsContent()
  }

  renderDetailsLoadingState = (): ReactElement => {
    return (
      <div className='metricSection-detailsView'>
        <details onClick={this.cancelEvent}>
          <summary className='metricSection-summary metricSection-loadingSummary'>
            <p className='metricProfile-loadingSpace metricSection-loadingDetailsMetric'></p>
          </summary>
        </details>
        <details onClick={this.cancelEvent}>
          <summary className='metricSection-summary metricSection-loadingSummary'>
            <p className='metricProfile-loadingSpace metricSection-loadingDetailsMetric'></p>
          </summary>
        </details>
        <details onClick={this.cancelEvent}>
          <summary className='metricSection-summary metricSection-loadingSummary'>
            <p className='metricProfile-loadingSpace metricSection-loadingDetailsMetric'></p>
          </summary>
        </details>
      </div>
    )
  }

  cancelEvent = (event: React.MouseEvent): void => {
    event.preventDefault()
  }

  renderDetailsContent = (): ReactElement => {
    const { metrics } = this.props

    return (
      <div className='metricSection-detailsView'>
        {metrics.map(metric => (
          <details key={metric.id}>
            <summary className='metricSection-summary'>
              {metric.name}
              <a
                className='metricSection-editMetricButton'
                data-id={metric.id}
                href='#'
                onClick={this.editMetric}
              >
                Edit
              </a>
            </summary>
            <div className='metricSection-detailsContent'>
              <p>
                Measurement: <span>{metric.name}</span>
              </p>
              <p>
                Time period:
                <span>{this.getTimePeriodString(metric.benchmarkSchedule.timePeriod)}</span>
              </p>
              <p>
                Days into time period:
                <span>{metric.benchmarkSchedule.daysIntoPeriodThreshold}</span>
              </p>
              <p>
                Benchmarks:
                <span>{this.renderBenchmarkData(metric.benchmarkSchedule)}</span>
              </p>
            </div>
          </details>
        ))}
      </div>
    )
  }
}

export default MetricSection
