import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import IModalResponse from '../Interfaces/IModalResponse'
import IErrorModal from './IErrorModal'

const Swal = withReactContent(swal)
class ErrorModal implements IErrorModal {
  public async error(message: string): Promise<IModalResponse> {
    const result = await Swal.fire({
      title: 'Oops...',
      html: message,
      icon: 'error'
    })

    return {
      isConfirmed: result.isConfirmed,
      value: result.value
    }
  }
}

export default ErrorModal
