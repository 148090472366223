import './style.css'

import { Button } from 'common'
import React, { Component, ReactElement } from 'react'

import ISubscribingView from '../../../SharedAbstractions/ISubscribingView'
import { submissionPage, verificationPage } from '../Classes/ReportUrlToQueryTabPresenter'
import FieldVerificationView from '../FieldVerificationView/FieldVerificationView'
import IReportUrlToQueryTabPresenter from '../Interfaces/IReportUrlToQueryTabPresenter'

const requestInputFieldId = 'reportUrlView-requestUrlField'

type Props = {
  presenter: IReportUrlToQueryTabPresenter
}

class ReportUrlSubmissionView extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  componentWillUnmount(): void {
    this.props.presenter.clearView()
  }

  render(): ReactElement {
    return <div>{this.renderPageBody()}</div>
  }

  renderPageBody = (): ReactElement => {
    const { presenter } = this.props

    if (presenter.getCurrentViewName() === submissionPage) {
      if (presenter.isLoading()) {
        return this.renderLoadingState()
      }
      return this.getSubmissionPage()
    }

    if (presenter.getCurrentViewName() === verificationPage) {
      return this.getVerificationPage()
    }

    return <div></div>
  }

  private renderLoadingState = (): ReactElement => {
    return (
      <div className='reportUrlView-loadingState'>
        <div className='reportUrlView-loadingText'>Loading...</div>
      </div>
    )
  }

  getSubmissionPage = (): ReactElement => {
    const { presenter } = this.props

    return (
      <div>
        {presenter.hasErrorMessage() ? (
          <div className='reportUrlView-errorMessage'>{presenter.getErrorMessage()}</div>
        ) : (
          <div></div>
        )}
        <strong>Note:</strong> you may attempt to auto generate your SOQL information by entering
        your report URL for reports of type <strong>Opportunities</strong>, <strong>Leads</strong>,
        and <strong>Accounts</strong>:
        <textarea id={requestInputFieldId} placeholder='example.url/com'></textarea>
        <div className='reportUrlView-submitButtonWrapper'>
          <Button
            className='primaryButton reportUrlView-submitButton'
            label={'Submit URL'}
            type='Submit'
            onClick={() => {
              // @ts-ignore
              const url = document.getElementById(requestInputFieldId).value
              presenter.getFieldsForReport(url)
            }}
          />
        </div>
      </div>
    )
  }

  getVerificationPage = (): ReactElement => {
    const { presenter } = this.props

    return <FieldVerificationView presenter={presenter.getFieldViewPresenter()} />
  }

  public update(): void {
    this.setState({})
  }
}

export default ReportUrlSubmissionView
