import IObservedPresenter from './IObservedPresenter'
import ISubscribingView from './ISubscribingView'

abstract class ObservedPresenter implements IObservedPresenter {
  private view: ISubscribingView | undefined

  public clearView(): void {
    this.view = undefined
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  protected updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }
}

export default ObservedPresenter
