import { ExistingTermDTO, IdNameDTO } from '../DTOs'
import FormPresenter from '../SharedAbstractions/FormPresenter'
import IOperationCanceler from '../SharedAbstractions/IOperationCanceler'
import ITermListUpdateObserver from '../SharedAbstractions/ITermListUpdateObserver'
import IAggregateTermFormPresenter from './IAggregateTermFormPresenter'

abstract class AggregateTermFormPresenter extends FormPresenter
  implements IAggregateTermFormPresenter {
  private _groupName: string
  private _selectedMetricProfileIds: number[]
  private _selectedTermIdToAggregate: number | undefined
  private knownMetricProfileIds: Set<number>

  constructor(
    operationCanceler: IOperationCanceler,
    termListUpdateObserver: ITermListUpdateObserver,
    protected readonly existingTermDTOs: ExistingTermDTO[],
    private readonly metricProfileDTOs: IdNameDTO[],
    operation: string
  ) {
    super('', operation, operationCanceler, termListUpdateObserver, 'Aggregate Term')
    this._selectedMetricProfileIds = []
    this._groupName = ''
    metricProfileDTOs.sort((a, b) => (a.name < b.name ? -1 : 1))
    this.knownMetricProfileIds = new Set(
      metricProfileDTOs.map(metricProfileDTO => metricProfileDTO.id)
    )
  }

  get groupName(): string {
    return this._groupName
  }

  set groupName(groupName: string) {
    this._groupName = groupName
    this.updateView()
  }

  get selectedTermIdToAggregate(): number {
    return this._selectedTermIdToAggregate
  }

  set selectedTermIdToAggregate(termId: number) {
    if (this.existingTermDTOs.find(term => term.id === termId)) {
      this._selectedTermIdToAggregate = termId
    } else {
      this._selectedTermIdToAggregate = undefined
    }
    this.updateView()
  }

  get selectedMetricProfileIds(): number[] {
    return this._selectedMetricProfileIds.slice()
  }

  set selectedMetricProfileIds(selectedMetricProfileIds: number[]) {
    this._selectedMetricProfileIds = selectedMetricProfileIds.filter(metricProfileId =>
      this.knownMetricProfileIds.has(metricProfileId)
    )
    this.updateView()
  }

  public getAggregateTermNamePreview(): string {
    if (this._groupName && this._selectedTermIdToAggregate) {
      const term = this.existingTermDTOs.find(
        existingTerm => existingTerm.id === this._selectedTermIdToAggregate
      )
      return `Managed ${this.groupName}: ${term.name}`
    }
    return ''
  }

  public getTermOptions(): IdNameDTO[] {
    return this.existingTermDTOs.slice()
  }

  public getMetricProfileOptions(): IdNameDTO[] {
    return this.metricProfileDTOs.slice()
  }

  public isSaveEnabled(): boolean {
    return this.requiredFieldsAreDefined()
  }

  protected requiredFieldsAreDefined(): boolean {
    return (
      !!this._groupName &&
      !!this._selectedTermIdToAggregate &&
      this._selectedMetricProfileIds.length > 0
    )
  }
}

export default AggregateTermFormPresenter
