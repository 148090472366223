import { okHttpCode } from '../../../../Utilities/HttpCodes'
import { IResponse } from '../../../../Utilities/Interfaces'
import ErrorMessageDisplayingPresenter from './ErrorMessageDisplayingPresenter'
import IFormPresenter from './IFormPresenter'
import IOperationCanceler from './IOperationCanceler'
import ITermListUpdateObserver from './ITermListUpdateObserver'

abstract class FormPresenter extends ErrorMessageDisplayingPresenter implements IFormPresenter {
  protected _name: string
  private _isSaving: boolean
  protected currentActiveStatus: boolean

  constructor(
    name: string,
    private readonly operation: string,
    private readonly operationCanceler: IOperationCanceler,
    private readonly termListUpdateObserver: ITermListUpdateObserver,
    private readonly termType: string,
    displayDuration?: number
  ) {
    super(displayDuration)
    this._name = name
    this._isSaving = false
  }

  public updateCurrentActiveStatus(newCurrentActiveStatus: boolean): void {
    this.currentActiveStatus = newCurrentActiveStatus
    this.updateView()
  }

  public getTermType(): string {
    return this.termType.toLowerCase()
  }

  get name(): string {
    return this._name
  }

  set name(newName: string) {
    this._name = newName
    this.updateView()
  }

  public getOperation(): string {
    return this.operation
  }

  public cancelOperation(): void {
    this.operationCanceler.cancelOperation()
  }

  public async clickSave(): Promise<void> {
    if (this.isSaveEnabled()) {
      this.setIsSaving(true)
      const response: IResponse = await this.getResponse()
      this.setIsSaving(false)
      if (response.code !== okHttpCode) {
        this.setErrorMessage(`Failed to ${this.getOperation()} ${this.termType}.`)
      } else {
        this.termListUpdateObserver.notifyOfSuccessfulTermListChange(
          `Successfully ${this.getOperation()}d ${this.termType}`
        )
      }
    }
  }

  protected abstract getResponse(): Promise<IResponse>

  abstract getCheckedStatus(): boolean

  private setIsSaving(isSaving: boolean) {
    this._isSaving = isSaving
    this.updateView()
  }

  abstract isSaveEnabled(): boolean

  public isSaving(): boolean {
    return this._isSaving
  }
}

export default FormPresenter
