import IEmailJobRequestPresenter from './IEmailJobRequestPresenter'
import IFormCloser from './IFormCloser'
import ObservedPresenter from './ObservedPresenter'

abstract class EmailJobRequestPresenter extends ObservedPresenter
  implements IEmailJobRequestPresenter {
  protected _email: string
  private _isLoading: boolean
  private _isShowingRequestSentScreen: boolean

  constructor(private readonly formCloser: IFormCloser) {
    super()
    this._email = ''
    this._isLoading = false
    this._isShowingRequestSentScreen = false
  }

  public get email(): string {
    return this._email
  }

  public set email(email: string) {
    this._email = email
    this.updateView()
  }

  public clickCloseForm(): void {
    this._isShowingRequestSentScreen = false
    this.formCloser.closeForm()
  }

  public async clickBeginJob(): Promise<void> {
    this._isLoading = true
    this.updateView()
    await this.sendRequest()
    this._isLoading = false
    this._isShowingRequestSentScreen = true
    this.updateView()
  }

  protected abstract sendRequest(): Promise<void>

  public isLoading(): boolean {
    return this._isLoading
  }

  public isShowingCompleteMessage(): boolean {
    return this._isShowingRequestSentScreen
  }
}

export default EmailJobRequestPresenter
