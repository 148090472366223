import IFetcher from '../../../Drivers/Interfaces/IFetcher'
import { baseMetricsUrl } from '../../../shared'
import { IdNameDTO } from './DTOs'
import IMetricProfilesReader from './IMetricProfilesReader'

class MetricProfilesReader implements IMetricProfilesReader {
  constructor(private readonly fetcher: IFetcher) {}

  public read(): Promise<IdNameDTO[]> {
    return this.fetcher.fetch({
      method: 'GET',
      url: `${baseMetricsUrl}metricProfiles`,
      body: undefined
    })
  }
}

export default MetricProfilesReader
