const getBaseApiUrl = (): string => {
  const prodServer = 'https://api.databased.com/'
  const sandboxServer = 'https://api.sandbox.databased.com/'
  const devServer = 'http://localhost:8080/'

  if (process.env.NODE_ENV === 'sandbox') {
    /* eslint-disable no-console */
    console.log(`Running api server from ${sandboxServer}`)
    return sandboxServer
  }

  if (process.env.NODE_ENV === 'development') {
    console.log(`Running api server from ${devServer}`)
    return devServer
  }

  return prodServer
}

const getBaseMetricsUrl = (): string => {
  const prodServer = 'https://metrics.databased.com/'
  const sandboxServer = 'https://metrics.sandbox.databased.com/'
  const devServer = 'http://localhost:8081/'

  if (process.env.NODE_ENV === 'sandbox') {
    /* eslint-disable no-console */
    console.log(`Running metrics rest api from ${sandboxServer}`)
    return sandboxServer
  }

  if (process.env.NODE_ENV === 'development') {
    console.log(`Running metrics rest api from ${devServer}`)
    return devServer
  }

  return prodServer
}

const getTermsUrl = (): string => {
  const prodServer = 'https://sync.databased.com/'
  const sandboxServer = 'https://sync.databased.com/'
  const devServer = 'http://localhost:8082/'

  if (process.env.NODE_ENV === 'sandbox') {
    console.log(`Running sync microservice from ${sandboxServer}`)
    return sandboxServer
  }

  if (process.env.NODE_ENV === 'development') {
    console.log(`Running sync microservice from ${devServer}`)
    /* eslint-enable no-console */
    return devServer
  }

  return prodServer
}

export const baseApiUrl = getBaseApiUrl()
export const baseMetricsUrl = getBaseMetricsUrl()
export const syncMicroserviceUrl = getTermsUrl()
export const successTransitionTime = 300
export const messageTime = 4000
