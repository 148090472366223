import IFetcher from '../../../Drivers/Interfaces/IFetcher'
import { baseMetricsUrl } from '../../../shared'
import IResponse from '../../../Utilities/Interfaces/IResponse'
import { ExistingConstantDTO, NewConstantDTO, UpdateConstantDTO } from './DTOs'
import ITermCreator from './SharedAbstractions/ITermCreator'
import ITermDeleter from './SharedAbstractions/ITermDeleter'
import ITermReader from './SharedAbstractions/ITermReader'
import ITermUpdater from './SharedAbstractions/ITermUpdater'

class GlobalTermCRUD implements ITermCreator, ITermReader, ITermUpdater, ITermDeleter {
  constructor(private readonly fetcher: IFetcher) {}

  public create(newTermDTO: NewConstantDTO): Promise<IResponse> {
    return this.fetcher.fetch({
      method: 'POST',
      url: `${baseMetricsUrl}terms`,
      body: newTermDTO
    })
  }

  public getTerms(): Promise<ExistingConstantDTO[]> {
    return this.fetcher.fetch({
      method: 'GET',
      url: `${baseMetricsUrl}terms`,
      body: undefined
    })
  }

  public update(termId: number, existingTermDTO: UpdateConstantDTO): Promise<IResponse> {
    return this.fetcher.fetch({
      method: 'PUT',
      url: `${baseMetricsUrl}terms/${termId}`,
      body: existingTermDTO
    })
  }

  public delete(termId: number): Promise<IResponse> {
    return this.fetcher.fetch({
      method: 'DELETE',
      url: `${baseMetricsUrl}terms/${termId}`,
      body: undefined
    })
  }
}

export default GlobalTermCRUD
