import { ExistingAggregateTermDTO, ExistingTermDTO, IdNameDTO } from '../DTOs'
import ITermCreator from '../SharedAbstractions/ITermCreator'
import ITermDeleter from '../SharedAbstractions/ITermDeleter'
import ITermListUpdateObserver from '../SharedAbstractions/ITermListUpdateObserver'
import ITermUpdater from '../SharedAbstractions/ITermUpdater'
import SectionPresenter from '../SharedAbstractions/SectionPresenter'
import AggregateTermAddFormPresenter from './AggregateTermAddFormPresenter'
import AggregateTermRowPresenter from './AggregateTermRowPresenter'
import IAggregateTermFormPresenter from './IAggregateTermFormPresenter'
import IAggregateTermRowPresenter from './IAggregateTermRowPresenter'
import IAggregateTermSectionPresenter from './IAggregateTermSectionPresenter'

class AggregateTermSectionPresenter extends SectionPresenter
  implements IAggregateTermSectionPresenter {
  private readonly addFormPresenter: AggregateTermAddFormPresenter
  private readonly termIdToRowPresenter: Map<number, AggregateTermRowPresenter>

  constructor(
    existingDTOs: ExistingTermDTO[],
    termListUpdateObserver: ITermListUpdateObserver,
    metricProfileDTOs: IdNameDTO[],
    termManager: ITermCreator & ITermDeleter & ITermUpdater,
    companyId: number
  ) {
    const aggregateDTOs = existingDTOs.reduce(
      (existingAggregateTerms: ExistingAggregateTermDTO[], existingTerm: ExistingTermDTO) => {
        if (existingTerm.type === 'aggregate') {
          existingAggregateTerms.push(existingTerm)
        }
        return existingAggregateTerms
      },
      []
    )
    super(aggregateDTOs)
    this.termIdToRowPresenter = new Map()
    aggregateDTOs.forEach(aggregateDTO => {
      this.termIdToRowPresenter.set(
        aggregateDTO.id,
        new AggregateTermRowPresenter(
          termManager,
          termListUpdateObserver,
          this,
          aggregateDTO,
          existingDTOs,
          metricProfileDTOs
        )
      )
    })
    this.addFormPresenter = new AggregateTermAddFormPresenter(
      this,
      termListUpdateObserver,
      existingDTOs,
      metricProfileDTOs,
      termManager,
      companyId
    )
  }

  public getAddFormPresenter(): IAggregateTermFormPresenter {
    return this.addFormPresenter
  }

  public getRowPresenter(termId: number): IAggregateTermRowPresenter {
    return this.termIdToRowPresenter.get(termId)
  }

  public getTermType(): string {
    return 'aggregate term'
  }

  public getSectionTitle(): string {
    return 'Aggregate Terms'
  }
}

export default AggregateTermSectionPresenter
