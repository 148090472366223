import IFetcher from '../../../../../../Drivers/Interfaces/IFetcher'
import { baseMetricsUrl } from '../../../../../../shared/constants'
import ISubscribingView from '../../../SharedAbstractions/ISubscribingView'
import ApiResponse from '../Interfaces/ApiResponse'
import IFieldViewPresenter from '../Interfaces/IFieldViewPresenter'
import IReportUrlToQueryTabPresenter from '../Interfaces/IReportUrlToQueryTabPresenter'
import ReportFieldsDto from '../Interfaces/ReportFieldsDto'
import FieldViewPresenter from './FieldViewPresenter'

export const submissionPage = 'submissionPage'
export const verificationPage = 'verificationPage'
export const reportIdPrefix = 'Report'
export const fieldsEndpoint = 'reportUrl/fields'
export const nullOrUndefinedError = 'Your must provide a report URL '
export const noReportFieldError = 'Your URL must contain .../Report/...'
export const noReportIdError = 'Your URL must contain a report ID'
export const badResponseError = 'Could not get the data for your report'

class ReportUrlToQueryTabPresenter implements IReportUrlToQueryTabPresenter {
  private reportFields: ReportFieldsDto = null
  private onVerificationPage = false
  private _isLoading = false
  private errorMessage = null
  private _view = null
  constructor(private readonly fetcher: IFetcher) {}

  getFieldViewPresenter(): IFieldViewPresenter {
    return new FieldViewPresenter(this.reportFields)
  }

  public clearView(): void {
    this._view = null
  }

  public setView(view: ISubscribingView): void {
    this._view = view
  }

  public getCurrentViewName(): string {
    if (this.onVerificationPage) {
      return verificationPage
    }

    return submissionPage
  }

  public async getFieldsForReport(reportUrl: string): Promise<void> {
    this.errorMessage = null

    if (this.verifyUrl(reportUrl)) {
      this._isLoading = true
      this._view.update()

      const params = {
        body: { reportUrl },
        method: 'POST',
        url: `${baseMetricsUrl}${fieldsEndpoint}`
      }
      const response = await this.fetcher.fetch(params)
      this.handleResponse(response)
    }
    this.updateView()
  }

  private handleResponse(reportFields: ReportFieldsDto | ApiResponse) {
    if (this.isApiResponse(reportFields)) {
      this.errorMessage = reportFields.message
    } else if (this.isReportFieldsDto(reportFields)) {
      this.onVerificationPage = true
      this.reportFields = reportFields
    } else {
      this.errorMessage = badResponseError
    }
    this._isLoading = false
    this.updateView()
  }

  private isApiResponse(toCheck: any): toCheck is ApiResponse {
    return toCheck && toCheck?.code && toCheck?.message
  }

  private isReportFieldsDto(toCheck: any): toCheck is ReportFieldsDto {
    return !!(
      toCheck &&
      'creditAppliedAtFieldNames' in toCheck &&
      'creditFieldNames' in toCheck &&
      'summationFieldNames' in toCheck &&
      'defaultSummationField' in toCheck &&
      'defaultSfdcObject' in toCheck &&
      'defaultWhereClauses' in toCheck
    )
  }

  private verifyUrl(reportUrl: string): boolean {
    if (reportUrl === null || reportUrl === undefined) {
      this.errorMessage = nullOrUndefinedError
      return false
    }

    if (reportUrl.includes(reportIdPrefix)) {
      const splitUrl = reportUrl.split('/')
      const reportIdIndex = splitUrl.indexOf(reportIdPrefix) + 1
      if (splitUrl.length > reportIdIndex) {
        return true
      }
      this.errorMessage = noReportIdError
    } else {
      this.errorMessage = noReportFieldError
    }
    return false
  }

  public hasErrorMessage(): boolean {
    return !!this.errorMessage
  }

  public getErrorMessage(): string {
    return this.errorMessage ?? ''
  }

  public isLoading(): boolean {
    return this._isLoading
  }

  private updateView(): void {
    if (this._view) {
      this._view.update()
    }
  }
}

export default ReportUrlToQueryTabPresenter
