import IExpressionChangeValidator from '../../../../UseCases/IExpressionChangeValidator'
import { ExistingExpressionDTO, ExistingTermDTO } from '../DTOs'
import ITermCreator from '../SharedAbstractions/ITermCreator'
import ITermDeleter from '../SharedAbstractions/ITermDeleter'
import ITermListUpdateObserver from '../SharedAbstractions/ITermListUpdateObserver'
import ITermUpdater from '../SharedAbstractions/ITermUpdater'
import SectionPresenter from '../SharedAbstractions/SectionPresenter'
import ExpressionAddFormPresenter from './ExpressionAddFormPresenter'
import ExpressionRowPresenter from './ExpressionRowPresenter'
import IExpressionSectionPresenter from './IExpressionSectionPresenter'

class ExpressionSectionPresenter extends SectionPresenter implements IExpressionSectionPresenter {
  private readonly expressionAddFormPresenter: ExpressionAddFormPresenter
  private readonly termIdToRowPresenter: Map<number, ExpressionRowPresenter>

  constructor(
    existingDTOs: ExistingTermDTO[],
    termManager: ITermCreator & ITermDeleter & ITermUpdater,
    termListUpdateObserver: ITermListUpdateObserver,
    companyId: number,
    expressionUpdateValidator: IExpressionChangeValidator
  ) {
    const expressionDTOs = existingDTOs.reduce(
      (existingExpressions: ExistingExpressionDTO[], existingTerm: ExistingTermDTO) => {
        if (existingTerm.type === 'expression') {
          existingExpressions.push(existingTerm)
        }
        return existingExpressions
      },
      []
    )
    super(expressionDTOs)

    this.expressionAddFormPresenter = new ExpressionAddFormPresenter(
      this,
      termManager,
      companyId,
      termListUpdateObserver,
      existingDTOs
    )

    this.termIdToRowPresenter = new Map()
    expressionDTOs.forEach(expressionDTO => {
      this.termIdToRowPresenter.set(
        expressionDTO.id,
        new ExpressionRowPresenter(
          expressionDTO,
          termManager,
          termListUpdateObserver,
          this,
          existingDTOs,
          expressionUpdateValidator
        )
      )
    })
  }

  public getSectionTitle(): string {
    return 'Expressions'
  }

  public getTermType(): string {
    return 'expression'
  }

  public getAddFormPresenter(): ExpressionAddFormPresenter {
    return this.expressionAddFormPresenter
  }

  public getRowPresenter(termId: number): ExpressionRowPresenter {
    return this.termIdToRowPresenter.get(termId)
  }
}

export default ExpressionSectionPresenter
