import IResponse from '../../../../Utilities/Interfaces/IResponse'
import ExistingConstantDTO from '../DTOs/ExistingConstantDTO'
import IOperationCanceler from '../SharedAbstractions/IOperationCanceler'
import ITermListUpdateObserver from '../SharedAbstractions/ITermListUpdateObserver'
import ITermUpdater from '../SharedAbstractions/ITermUpdater'
import ConstantFormPresenter from './ConstantFormPresenter'

class ConstantEditFormPresenter extends ConstantFormPresenter {
  constructor(
    private readonly originalDTO: ExistingConstantDTO,
    private readonly updater: ITermUpdater,
    canceler: IOperationCanceler,
    termListUpdateObserver: ITermListUpdateObserver,
    displayDuration?: number
  ) {
    super(canceler, 'Update', termListUpdateObserver, displayDuration)
    this._name = originalDTO.name
    this._value = String(originalDTO.value)
    this.currentActiveStatus = this.originalDTO.isActive
  }

  protected getResponse(): Promise<IResponse> {
    return this.updater.update(this.originalDTO.id, {
      name: this.name,
      value: parseFloat(this.value),
      isActiveVariable: this.currentActiveStatus ?? true,
      isActiveTerm: this.currentActiveStatus ?? true
    })
  }

  public isSaveEnabled(): boolean {
    const value = Number.parseFloat(this.value)
    return (
      !!this.name &&
      !Number.isNaN(value) &&
      (this.originalDTO.name !== this.name ||
        String(this.originalDTO.value) !== this.value ||
        this.currentActiveStatus !== this.originalDTO.isActive)
    )
  }

  public getCheckedStatus(): boolean {
    return this.currentActiveStatus ?? true
  }
}

export default ConstantEditFormPresenter
