import ISubscribingView from '../../../SharedAbstractions/ISubscribingView'
import { pleaseSelectFieldPlaceholder } from '../FieldVerificationView/FieldVerificationView'
import IFieldViewPresenter from '../Interfaces/IFieldViewPresenter'
import ReportFieldsDto from '../Interfaces/ReportFieldsDto'

export const dateTimeTypeWrapperStart = 'convertTimeZone('
export const dateTimeTypeWrapperEnd = ')'
export const dateTimeType = 'datetime'
export const dateType = 'date'

class FieldViewPresenter implements IFieldViewPresenter {
  private currentSumField: string = null
  private currentAppliedAtField: string = null
  private currentCreditToField: string = null
  private shouldShowQuery = false
  private _view: ISubscribingView
  constructor(private readonly reportFields: ReportFieldsDto) {
    this.currentSumField =
      reportFields.defaultSummationField === '' ? null : reportFields.defaultSummationField
    this._view = null
  }

  public clearView(): void {
    this._view = null
  }

  public setView(view: ISubscribingView): void {
    this._view = view
  }

  public getQueryDto(): string {
    const queryDto = {
      userIdField: this.currentCreditToField,
      dateField: this.currentAppliedAtField,
      summationField: this.currentSumField,
      tableName: this.reportFields.defaultSfdcObject,
      whereClauses: this.reportFields.defaultWhereClauses[0],
      dateType: this.getDateType(),
      groupingColumn: null,
      minGroupSizeToCount: null,
      filterRule: null
    }

    return JSON.stringify(queryDto)
  }

  private getDateType(): string {
    if (this.currentAppliedAtField) {
      return this.currentAppliedAtField?.includes(dateTimeTypeWrapperStart)
        ? dateTimeType
        : dateType
    }

    return null
  }

  setShouldDisplayQueryDto(shouldShow: boolean): void {
    this.shouldShowQuery = shouldShow
    this.updateView()
  }

  shouldDisplayQueryDto(): boolean {
    return this.shouldShowQuery
  }

  public getAppliedAtFields(): string[] {
    return this.reportFields?.creditAppliedAtFieldNames
      ? this.reportFields.creditAppliedAtFieldNames
      : []
  }

  public getCreditFields(): string[] {
    return this.reportFields?.creditFieldNames ? this.reportFields.creditFieldNames : []
  }

  public getDefaultSfdcObject(): string {
    return this.reportFields?.defaultSfdcObject ? this.reportFields.defaultSfdcObject : ''
  }

  public getCurrentSumField(): string {
    return this.currentSumField ?? pleaseSelectFieldPlaceholder
  }

  public setCurrentSumField(newSumField: string): void {
    this.currentSumField = newSumField
    this.updateView()
  }

  public getCurrentAppliedAtField(): string {
    return this.currentAppliedAtField ?? pleaseSelectFieldPlaceholder
  }

  public setCurrentAppliedAtField(newAppliedAtField: string): void {
    this.currentAppliedAtField = newAppliedAtField
    this.updateView()
  }

  public getCurrentCreditToField(): string {
    return this.currentCreditToField ?? pleaseSelectFieldPlaceholder
  }

  public setCurrentCreditToField(newCreditToField: string): void {
    this.currentCreditToField = newCreditToField
    this.updateView()
  }

  public getDefaultWhereClauses(): string[] {
    return this.reportFields?.defaultWhereClauses ? this.reportFields.defaultWhereClauses : []
  }

  public getSummationFields(): string[] {
    return this.reportFields?.summationFieldNames ? this.reportFields.summationFieldNames : []
  }

  private updateView(): void {
    if (this._view) {
      this._view.update()
    }
  }

  public getDateFieldDisplayValue(dateField: string): string {
    if (dateField.includes(dateTimeTypeWrapperStart)) {
      return dateField.replace(dateTimeTypeWrapperStart, '').replace(dateTimeTypeWrapperEnd, '')
    }
    return dateField
  }
}

export default FieldViewPresenter
