const METRIC_PROFILE_ID = 'metricProfileId'
const FORM_PROPS = 'formProps'

export const hasMetricProfileId = (): boolean => {
  return !!window.localStorage.getItem(METRIC_PROFILE_ID)
}

export const setMetricProfileId = (metricProfileId: number): void => {
  if (hasMetricProfileId()) {
    window.localStorage.removeItem(METRIC_PROFILE_ID)
  }

  window.localStorage.setItem(METRIC_PROFILE_ID, `${metricProfileId}`)
}

export const hasFormProps = (): boolean => {
  const formProps = window.localStorage.getItem(FORM_PROPS)
  return !!formProps
}

export const getFormProps = (): any => {
  return window.localStorage.getItem(FORM_PROPS)
}

export const getMetricProfileIdFromFormProps = (): string => {
  return JSON.parse(getFormProps()).metricProfileId
}

export const backToPreviousPage = (metricProfileId?: number): void => {
  const formStorage = getFormProps()

  if (hasFormProps()) {
    const formData = JSON.parse(formStorage)

    setMetricProfileId(formData)
    window.location.pathname = formData ? `/${formData?.id}` : '/'
  } else if (metricProfileId) {
    setMetricProfileId(metricProfileId)
    window.location.pathname = metricProfileId ? `/${metricProfileId}` : '/'
  } else {
    window.location.pathname = '/'
  }
}
