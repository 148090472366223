import Fetcher from '../../../Drivers/Fetcher'
import IExpressionChangeValidator from '../../../UseCases/IExpressionChangeValidator'
import AggregateTermSectionPresenter from './AggregateTerms/AggregateTermSectionPresenter'
import IAggregateTermSectionPresenter from './AggregateTerms/IAggregateTermSectionPresenter'
import ConstantSectionPresenter from './Constants/ConstantSectionPresenter'
import IConstantSectionPresenter from './Constants/IConstantSectionPresenter'
import { ExistingConstantDTO, ExistingTermDTO, ExistingVariableDTO } from './DTOs'
import ExpressionSectionPresenter from './Expressions/ExpressionSectionPresenter'
import IExpressionSectionPresenter from './Expressions/IExpressionSectionPresenter'
import IMetricProfilesReader from './IMetricProfilesReader'
import ISyncPresenter from './SalesforceSync/ISyncPresenter'
import SalesforceSyncPresenter from './SalesforceSync/SalesforceSyncPresenter'
import ErrorMessageDisplayingPresenter from './SharedAbstractions/ErrorMessageDisplayingPresenter'
import ICompanyTermsListPresenter from './SharedAbstractions/ICompanyTermsListPresenter'
import ITermCreator from './SharedAbstractions/ITermCreator'
import ITermDeleter from './SharedAbstractions/ITermDeleter'
import ITermListUpdateObserver from './SharedAbstractions/ITermListUpdateObserver'
import ITermReader from './SharedAbstractions/ITermReader'
import ITermUpdater from './SharedAbstractions/ITermUpdater'
import IVariableSectionPresenter from './Variables/IVariableSectionPresenter'
import SalesforceQueryManager from './Variables/SalesforceQueryManager/SalesforceQueryManager'
import VariableSectionPresenter from './Variables/VariableSectionPresenter'

class CompanyTermsListPresenter extends ErrorMessageDisplayingPresenter
  implements ITermListUpdateObserver, ICompanyTermsListPresenter {
  private _isLoading: boolean
  private terms: ExistingTermDTO[]
  private _successMessage: string
  private constantSectionPresenter: ConstantSectionPresenter | undefined
  private expressionSectionPresenter: ExpressionSectionPresenter | undefined
  private aggregateTermSectionPresenter: AggregateTermSectionPresenter | undefined
  private variableSectionPresenter: VariableSectionPresenter | undefined
  private readonly syncPresenter: SalesforceSyncPresenter

  constructor(
    private readonly termManager: ITermReader & ITermDeleter & ITermCreator & ITermUpdater,
    private readonly companyId: number,
    private readonly successDisplayDuration: number,
    private readonly metricProfilesReader: IMetricProfilesReader,
    private readonly isSalesforceCustomer: boolean,
    private readonly userId: number,
    private readonly expressionUpdateValidator: IExpressionChangeValidator
  ) {
    super(successDisplayDuration)
    this.refreshTermsList()
    this.terms = []
    this._successMessage = ''
    this.syncPresenter = new SalesforceSyncPresenter(new Fetcher())
  }

  private async refreshTermsList(): Promise<void> {
    this._isLoading = true
    this.updateView()
    this.terms = await this.termManager.getTerms()
    this.terms.sort((a, b) => (a.name > b.name ? 1 : -1))
    const { existingConstants, existingVariables } = this.terms.reduce(
      (
        object: {
          existingConstants: ExistingConstantDTO[]
          existingVariables: ExistingVariableDTO[]
        },
        existingTerm: ExistingTermDTO
      ) => {
        if (existingTerm.type === 'constant') {
          object.existingConstants.push(existingTerm)
        }
        if (existingTerm.type === 'variable') {
          object.existingVariables.push(existingTerm)
        }
        return object
      },
      { existingConstants: [], existingVariables: [] }
    )
    this.constantSectionPresenter = new ConstantSectionPresenter(
      existingConstants,
      this.termManager,
      this,
      this.companyId
    )
    this.expressionSectionPresenter = new ExpressionSectionPresenter(
      this.terms,
      this.termManager,
      this,
      this.companyId,
      this.expressionUpdateValidator
    )
    this.aggregateTermSectionPresenter = new AggregateTermSectionPresenter(
      this.terms,
      this,
      await this.metricProfilesReader.read(),
      this.termManager,
      this.companyId
    )
    this.variableSectionPresenter = new VariableSectionPresenter(
      this.termManager,
      this.companyId,
      this,
      existingVariables,
      this.isSalesforceCustomer
        ? new SalesforceQueryManager(new Fetcher(), existingVariables, this.userId)
        : undefined
    )
    this._isLoading = false
    this.updateView()
  }

  public getConstantSectionPresenter(): IConstantSectionPresenter {
    if (!this.isLoading()) {
      return this.constantSectionPresenter
    }
    throw Error('Cannot build constant presenter while loading')
  }

  public getExpressionSectionPresenter(): IExpressionSectionPresenter {
    return this.expressionSectionPresenter
  }

  public getAggregateTermSectionPresenter(): IAggregateTermSectionPresenter {
    return this.aggregateTermSectionPresenter
  }

  public getVariableSectionPresenter(): IVariableSectionPresenter {
    return this.variableSectionPresenter
  }

  public isLoading(): boolean {
    return this._isLoading
  }

  public getSuccessMessage(): string {
    return this._successMessage
  }

  public notifyOfSuccessfulTermListChange(successMessage: string): void {
    this._successMessage = successMessage
    this.refreshTermsList()
    setTimeout(() => {
      this._successMessage = ''
      this.updateView()
    }, this.successDisplayDuration)
  }

  public getSyncPresenter(): ISyncPresenter {
    return this.syncPresenter
  }

  public isShowingSyncButton(): boolean {
    return this.isSalesforceCustomer
  }
}

export default CompanyTermsListPresenter
