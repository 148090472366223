import { Cookies, DbDataHelper, JsonHelper } from 'common'
import React, { Component, ReactElement } from 'react'

import Fetcher from '../../Drivers/Fetcher'
import IExpandedTerm from '../../Routes/Home/IExpandedTerm'
import { baseMetricsUrl, IRequestComponent, messageTime, successTransitionTime } from '../../shared'
import ExpressionUpdateValidator from '../../UseCases/ExpressionUpdateValidator'
import HomeView from './HomeView'
import IMetric from './IMetric'
import IMetricProfile from './IMetricProfile'
import CompanyTermsListPresenter from './MetricDefinitions/CompanyTermsListPresenter'
import GlobalTermCRUD from './MetricDefinitions/GlobalTermCRUD'
import MetricProfilesReader from './MetricDefinitions/MetricProfilesReader'
import ReportUrlToQueryTabPresenter from './MetricDefinitions/Views/ReportUrlToQueryTab/Classes/ReportUrlToQueryTabPresenter'
import IReportUrlToQueryTabPresenter from './MetricDefinitions/Views/ReportUrlToQueryTab/Interfaces/IReportUrlToQueryTabPresenter'
import CompanyDataHelper from './MetricProfile/CompanyDataHelper/CompanyDataHelper'
import IMetricProfilePresenter from './MetricProfile/MetricProfilePresenter/IMetricProfilePresenter'
import MetricProfilePresenter from './MetricProfile/MetricProfilePresenter/MetricProfilePresenter'

type State = {
  apiError: string
  busy: boolean
  metricProfileIdToMetric: {
    [n: number]: IMetric[]
  }
  metricProfileIdToPresenter: {
    [n: number]: IMetricProfilePresenter
  }
  metricProfileIdToTerm: {
    [n: number]: object[]
  }
  metricProfiles: IMetricProfile[]
  metricProfilesLoading: boolean
  successMessage: string
}

class Home extends Component<{}, State> implements IRequestComponent {
  private termsListPresenter: CompanyTermsListPresenter
  private urlSubmissionPresenter: IReportUrlToQueryTabPresenter
  state = {
    apiError: '',
    busy: true,
    metricProfiles: [],
    metricProfileIdToMetric: {},
    metricProfileIdToPresenter: {},
    metricProfileIdToTerm: {},
    metricProfilesLoading: true,
    successMessage: localStorage.getItem('formSuccessMessage') || ''
  }

  async componentDidMount(): Promise<void> {
    DbDataHelper.redirectIfUnauthorized()

    const expressionValidator = new ExpressionUpdateValidator()
    if (DbDataHelper.isAdmin()) {
      this.termsListPresenter = new CompanyTermsListPresenter(
        new GlobalTermCRUD(new Fetcher()),
        Cookies.get('companyId'),
        messageTime,
        new MetricProfilesReader(new Fetcher()),
        Cookies.get('crm') === 'salesforce',
        DbDataHelper.getUserId(),
        expressionValidator
      )
      this.urlSubmissionPresenter = new ReportUrlToQueryTabPresenter(new Fetcher())
      this.getMetricProfiles()
        .then(this.getMetricProfileData)
        .then(this.getAllGlobalTerms)
        .then(() => {
          const { metricProfileIdToMetric, metricProfileIdToTerm, metricProfiles } = this.state
          expressionValidator.initialize(
            CompanyDataHelper.getTerms(metricProfileIdToTerm, metricProfiles),
            CompanyDataHelper.getMetricDTOs(metricProfiles, metricProfileIdToMetric)
          )
          this.finishLoadingMetricsAndTerms()
        })
        .then()
    }

    this.startSuccessMessageTimer()
  }

  componentDidUpdate() {
    this.startSuccessMessageTimer()
  }

  render(): ReactElement {
    const {
      apiError,
      busy,
      metricProfiles,
      metricProfileIdToMetric,
      metricProfileIdToPresenter,
      metricProfileIdToTerm,
      metricProfilesLoading,
      successMessage
    } = this.state

    return (
      <HomeView
        busy={busy}
        error={apiError}
        metricProfiles={metricProfiles}
        metricProfileIdToPresenter={metricProfileIdToPresenter}
        metricProfileIdToMetric={metricProfileIdToMetric}
        metricProfileIdToTerm={metricProfileIdToTerm}
        metricProfilesLoading={metricProfilesLoading}
        openMetricProfileForm={this.openMetricProfileForm}
        removeMetricProfile={this.removeMetricProfile}
        setSuccessMessage={this.setSuccessMessage}
        successMessage={successMessage}
        termsListPresenter={this.termsListPresenter}
      />
    )
  }

  private getMetricProfiles = async (): Promise<void> => {
    this.setMetricProfiles(await new MetricProfilesReader(new Fetcher()).read())
  }

  private setMetricProfiles = (response: any): void => {
    const metricProfiles = response.sort((a, b) => JsonHelper.sortObjectBy(a, b, 'name'))
    const metricProfileIdToTerm = {}
    const metricProfileIdToPresenter = {}
    const fetcher = new Fetcher()
    metricProfiles.forEach(metricProfile => {
      metricProfileIdToTerm[metricProfile.id] = []
      metricProfileIdToPresenter[metricProfile.id] = new MetricProfilePresenter(
        fetcher,
        metricProfile.id
      )
    })

    this.setState({
      metricProfiles,
      metricProfileIdToPresenter,
      metricProfileIdToMetric: {},
      metricProfileIdToTerm,
      busy: false
    })
  }

  private getMetricProfileData = (): Promise<any> => {
    return this.getAllMetrics()
  }

  private getAllGlobalTerms = async (): Promise<any> => {
    return DbDataHelper.request({
      requiresAuth: true,
      successCallback: (response: any) => this.addGlobalsToEachProfile(response),
      url: `${baseMetricsUrl}terms`
    })
  }

  private addGlobalsToEachProfile = (response: any[]): void => {
    const { metricProfileIdToTerm } = this.state
    const termList: IExpandedTerm[] = this.sortTermsByName(response)
    const metricProfileIdToTerms = {}

    Object.keys(metricProfileIdToTerm).forEach(metricProfileId => {
      metricProfileIdToTerms[metricProfileId] = []
      metricProfileIdToTerm[metricProfileId].forEach(term => {
        metricProfileIdToTerms[metricProfileId].push(term)
      })
      termList.forEach(term => {
        metricProfileIdToTerms[metricProfileId].push(term)
      })
      metricProfileIdToTerms[metricProfileId] = this.expandedExpressionTerms(
        metricProfileIdToTerms[metricProfileId]
      )
    })

    this.setState({ metricProfileIdToTerm: metricProfileIdToTerms })
  }

  private getAllMetrics = (): Promise<any> => {
    return DbDataHelper.request({
      requiresAuth: true,
      successCallback: (response: any) => this.createMetricProfileIdToMetrics(response),
      url: `${baseMetricsUrl}metrics`
    })
  }

  private createMetricProfileIdToMetrics = (response: any[]): void => {
    const sortedMetrics = this.sortMetricsByName(response)
    sortedMetrics.forEach(metric => {
      metric.benchmarkSchedule.rampStages.sort((a, b) => a.stageIndex - b.stageIndex)
    })
    const metricProfileIdToMetrics: { [n: number]: IMetric[] } = {}
    sortedMetrics.forEach((metric: IMetric) => {
      if (metricProfileIdToMetrics[metric.metricProfileId]) {
        const metricsToAppendTo = metricProfileIdToMetrics[metric.metricProfileId]
        metricProfileIdToMetrics[metric.metricProfileId] = [...metricsToAppendTo, metric]
      } else {
        metricProfileIdToMetrics[metric.metricProfileId] = [metric]
      }
    })
    this.setState({ metricProfileIdToMetric: metricProfileIdToMetrics })
  }

  private expandedExpressionTerms = (terms: any[]): any[] => {
    return terms.map(term => {
      if (term.type === 'expression') {
        return {
          ...term,
          lh_term: terms.find(searchTerm => searchTerm.id === term.lh_term),
          rh_term: terms.find(searchTerm => searchTerm.id === term.rh_term)
        }
      }

      return term
    })
  }

  private sortTermsByName = (terms: IExpandedTerm[]): IExpandedTerm[] => {
    return terms.sort((a, b) => JsonHelper.sortObjectBy(a, b, 'name'))
  }

  private sortMetricsByName = (metrics: IMetric[]): IMetric[] => {
    return metrics.sort((a, b) => JsonHelper.sortObjectBy(a, b, 'name'))
  }

  private finishLoadingMetricsAndTerms = (): void => {
    this.setState({ metricProfilesLoading: false })
  }

  private startSuccessMessageTimer = () => {
    const { successMessage } = this.state

    if (successMessage) {
      setTimeout(this.fadeOutSuccessMessage, messageTime)
    }
  }

  private fadeOutSuccessMessage = (): void => {
    const successMessageElement = document.querySelector('.home-success') as HTMLElement

    if (successMessageElement) {
      successMessageElement.style.opacity = '0'
      setTimeout(this.clearSuccessMessage, successTransitionTime)
    }
  }

  private clearSuccessMessage = (): void => {
    this.setSuccessMessage('')
    window.localStorage.removeItem('formSuccessMessage')
  }

  private openMetricProfileForm = (): void => {
    const { metricProfiles, metricProfileIdToMetric, metricProfileIdToTerm } = this.state

    const terms = metricProfiles.reduce((newObject, metricProfile) => {
      // eslint-disable-next-line no-param-reassign
      newObject[metricProfile.id] = metricProfileIdToTerm[metricProfile.id].filter(
        term => term.metric_profile_id
      )
      return newObject
    }, {})

    window.localStorage.setItem(
      'formProps',
      JSON.stringify({
        metrics: metricProfileIdToMetric,
        mode: 'add',
        profiles: metricProfiles,
        terms
      })
    )
    window.location.pathname = '/metric-profile-form'
  }

  private setSuccessMessage = (message: string): void => {
    this.setState({ successMessage: message })
  }

  private removeMetricProfile = (id: number): void => {
    this.setState(previousState => ({
      metricProfiles: previousState.metricProfiles.filter(metricProfile => metricProfile.id !== id),
      metricProfileIdToMetric: this.getObjectWithoutKey({
        key: id,
        object: previousState.metricProfileIdToMetric
      }),
      metricProfileIdToTerm: this.getObjectWithoutKey({
        key: id,
        object: previousState.metricProfileIdToTerm
      })
    }))
  }

  private getObjectWithoutKey = ({ object, key }): any => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [key]: _, ...objectWithoutKey } = object
    return objectWithoutKey
  }
}

export default Home
