import './style.css'

import React, { Component, Fragment, ReactElement } from 'react'

type Props = {
  getTabContent: (tabId: string) => ReactElement
  tabNames: string[]
}

type State = {
  selectedTabIndex: number
}

class TabContainer extends Component<Props, State> {
  state = {
    selectedTabIndex: 0
  }

  render(): ReactElement {
    const { tabNames } = this.props

    return (
      <Fragment>
        <ul className='tab-container-tabs'>
          {tabNames.map((name: string, index: number) => {
            return (
              <li
                className={`tab-container-tab ${
                  index === this.state.selectedTabIndex ? 'selected' : ''
                }`}
                key={name}
              >
                <h4
                  className='tab-container-tabName'
                  id={this.getTabId(name)}
                  onClick={() => this.setState({ selectedTabIndex: index })}
                >
                  {name}
                </h4>
              </li>
            )
          })}
        </ul>
        <div className='tab-container-tabContent'>
          {this.props.getTabContent(this.props.tabNames[this.state.selectedTabIndex])}
        </div>
      </Fragment>
    )
  }

  private getTabId = (tabName: string): string => {
    return tabName.toLowerCase().replace(/\s/g, '-')
  }
}

export default TabContainer
