import { DbDataHelper } from 'common'

import IFetcher from './Interfaces/IFetcher'

class Fetcher implements IFetcher {
  public async fetch(
    params: {
      body: any
      method: string
      url: string
    },
    optionalHeaders: object = {}
  ): Promise<any> {
    const { body, method, url } = params

    const result = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${DbDataHelper.getAuthToken()}`,
        ...optionalHeaders
      },
      body: method === 'GET' ? undefined : JSON.stringify(body)
    })

    if (result) {
      const asText = await result.text()
      try {
        return JSON.parse(asText)
      } catch (err) {
        return null
      }
    }
    return null
  }
}

export default Fetcher
