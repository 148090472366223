import IResponse from '../../../../Utilities/Interfaces/IResponse'
import { ExistingTermDTO } from '../DTOs'
import IOperationCanceler from '../SharedAbstractions/IOperationCanceler'
import ITermCreator from '../SharedAbstractions/ITermCreator'
import ITermListUpdateObserver from '../SharedAbstractions/ITermListUpdateObserver'
import ExpressionFormPresenter from './ExpressionFormPresenter'

class ExpressionAddFormPresenter extends ExpressionFormPresenter {
  constructor(
    operationCanceler: IOperationCanceler,
    private readonly creator: ITermCreator,
    private readonly companyId: number,
    termListUpdateObserver: ITermListUpdateObserver,
    private readonly existingTermDTOs: ExistingTermDTO[],
    displayDuration?: number
  ) {
    super(operationCanceler, termListUpdateObserver, 'Create', displayDuration)
  }

  protected getAvailableTerms(): ExistingTermDTO[] {
    return this.existingTermDTOs
  }

  public getCheckedStatus(): boolean {
    return true
  }

  protected getResponse(): Promise<IResponse> {
    return this.creator.create({
      company_id: this.companyId,
      lh_term: this._leftHandTermId,
      rh_term: this._rightHandTermId,
      operator: this._operator,
      type: 'expression',
      name: this._name,
      isActiveVariable: true
    })
  }

  public isSaveEnabled(): boolean {
    return this.requiredFieldsAreNonEmpty()
  }
}

export default ExpressionAddFormPresenter
